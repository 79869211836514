import axios from '@/lib/axiosController';

/**
 * 대회 수상 관리 API
 */

/**
 * 대회 수상명 내용 수정 API
 *
 * @param {number} prizeId
 * @param {string} title
 * @param {number} tier
 * @param {number} maxAllowed
 * @returns
 */
export const updatePrizeByPrizeId = (prizeId, title, tier, maxAllowed) =>
  axios.put(`/prize/${prizeId}`, {
    title,
    tier,
    maxAllowed,
  });

/**
 * 대회 수상명 생성 API
 *
 * @param {number} contestItemId
 * @param {string} title
 * @param {number} tier
 * @param {number} maxAllowed
 * @returns
 */
export const createPrize = (contestItemId, title, tier, maxAllowed) => axios.post('/prize/upload', { contestItemId, title, tier, maxAllowed });

/**
 * 대회 수상 선수 리스트 조회 API
 * @param {number} contestItemId
 * @returns
 */
export const getWinnerListByContestItemId = (contestItemId) => axios.get(`/prize/winner/list/${contestItemId}`);

/**
 * 대회 수상 리스트 조회 API
 * @param {number} contestItemId
 * @returns
 */
export const getPrizeListByContestItemId = (contestItemId) => axios.get(`/prize/list/${contestItemId}`);

/**
 * 대회 수상 집계 API
 * @param {number} contestItemId
 * @returns
 */
export const aggregateWinnerByContestItemId = (contestItemId) => axios.post(`/prize/aggregate/${contestItemId}`);
