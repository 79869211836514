import axios from '@/lib/axiosController';

export const getUser = (userId) => axios.get(`/user/${userId}`);

export const getUserSearch = (query) => axios.get(`/user/search?${query}`);

export const updateUsers = (data) =>
  axios.put(`/user/bulk`, {
    requests: data,
  });

export const deleteUser = (userId) => axios.delete(`/user/delete/${userId}`);

export const deleteUsers = (userIds) =>
  axios.delete(`/user/delete-users`, {
    data: {
      userIds,
    },
  });

export const approveUsers = (userIds) =>
  axios.put(`/user/approve/bulk`, {
    userIds,
  });

export const modifyBranchUsers = (data) =>
  axios.put(`/user/branch/bulk`, {
    requests: data,
  });

export const getBranchOfficeCommunity = (userId) => {
  return axios.get('/branch-office-community/authority', {
    params: {
      userId,
    },
  });
};

export const updateBranchOfficeCommunity = (body) => {
  return axios.post('/branch-office-community/authority', body);
};
