<template>
  <v-dialog :model-value="dialog" @update:modelValue="updateDialog">
    <template v-slot:activator="{ props }">
      <v-btn class="mr-4" :color="color" v-bind="props"> {{ activeLabel }} </v-btn>
    </template>
    <v-card class="dialog">
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <template v-for="item in items" :key="item.id">
          <div class="mb-4" v-if="item.id === 'contestRegisterType'">
            <basic-button-group label="대분류" :items="contestRegistGroup" :active-value="item.title" @update:activeValue="item.title = $event" required />
          </div>
          <basic-input
            v-else-if="!['edit', 'delete'].includes(item.id)"
            class="row"
            :id="item.id"
            :label="item.label"
            :placeholder="item.placeholder"
            :text-value="item.title"
            :readonly="!item.editable"
            :hint="!item.editable ? '수정할 수 없는 값 입니다.' : ''"
            persistent-hint
            required
            @update:textValue="item.title = $event"
          />
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" text @click="closeDialog"> 닫기 </v-btn>
        <v-btn color="blue-darken-1" text @click="contestItemHandler"> {{ activeLabel }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue';
import BasicInput from '@/components/BasicInput.vue';
import BasicButtonGroup from '@/components/form/BasicButtonGroup.vue';

import { CONTEST_REGISTER_TYPE_PASCAL, PLAYER_INFO_TITLE } from '@/constants/contest';

const contestRegistGroup = Object.values(CONTEST_REGISTER_TYPE_PASCAL).map((value) => ({
  id: value,
  label: PLAYER_INFO_TITLE[value],
}));

export default {
  name: 'ContestItemEditableDialog',
  components: { BasicInput, BasicButtonGroup },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    activeHandler: {
      type: Function,
      default: () => {},
    },
    activeLabel: {
      type: String,
      default: '추가',
    },
    color: {
      type: String,
      default: 'primary',
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const dialog = ref(false);

    const closeDialog = () => {
      dialog.value = false;
    };

    const contestItemHandler = () => {
      props.activeHandler(props.items, props.id);
      closeDialog();
    };

    const updateDialog = (value) => {
      dialog.value = value;

      if (value) {
        emit('update:openDialog', props.items);
      }
    };

    return { dialog, closeDialog, updateDialog, contestItemHandler, contestRegistGroup };
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  width: 40rem;
  padding: 1rem;
}

.row {
  margin-bottom: 1rem;
}
</style>
