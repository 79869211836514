import axios from '@/lib/axiosController';

/**
 * 대회 종목 관리 API
 */

/**
 * 대회 종목 수정 API
 *
 * @param {{
 *  contestItemId: number,
 *  name: string,
 *  code: string
 * }} param0
 * @returns
 */
export const updateContestItemById = ({ contestItemId, name, code }) =>
  axios.put(`/contest-item/${contestItemId}`, {
    name,
    code,
  });

/**
 * 대회 종목 생성 API
 * parentId가 null이면 최상위 종목으로 생성
 *
 * @param {{
 *  name: string,
 *  code: string,
 *  contestId: number,
 *  parentId: number,
 *  contestRegisterType: string,
 * }} param0
 * @returns
 */
export const createContestItem = ({ name, code, contestId, parentId = null, contestRegisterType }) =>
  axios.post('/contest-item/upload', {
    name,
    code,
    contestId,
    parentId,
    contestRegisterType,
  });

/**
 * 자식 종목 조회 API
 *
 * @param {number} parentId
 * @returns
 */
export const getContestItemByParentId = (parentId) =>
  axios.get(`/contest-item/child-list`, {
    params: {
      parentId,
    },
  });

/**
 * 대회의 전체 종목 리스트 조회 API
 *
 * @param {number} contestId
 * @returns
 */
export const getAllContestItemByContestId = async (contestId) => {
  const ret = await axios.get(`/contest-item/all/${contestId}`);

  return ret;
};

/**
 * 대회 종목 분류별 전체 리스트 조회 API
 *
 * @param {number} contestId
 * @param {string} contestRegisterType
 * */
export const getAllContestItemByContestIdAndRegisterType = (contestId, contestRegisterType) =>
  axios.get(`/contest-item/all/${contestId}/contest-register-type/${contestRegisterType}`);

/**
 * 대회 수정/등록 모달에서 종목으로 부모 ContestItems 조회하는 API
 * @param {number} leafContestItemId
 * @returns
 */
export const getContestItemParent = (leafContestItemId) => axios.get(`/contest-item/parent/${leafContestItemId}`);
