export const MIRROR_MANAGEMENT_DATA = (() => {
  const data = [];

  for (let i = 0; i < 30; i++) {
    data.push({
      id: i,
      name: `이름-${i}`,
      mirrorNumber: i + 10,
    });
  }

  return data;
})();

export const MIRROR_MANAGEMENT_HEADER = [
  {
    header: '참가번호',
    name: 'id',
    editor: 'text',
  },
  {
    header: '이름',
    name: 'name',
  },
  {
    header: '거울번호',
    name: 'mirrorNumber',
    editor: 'text',
  },
];

export const PARTICIPATION_STATUS = {
  PRE_START: '시작전',
  PARTICIPATING: '참여',
  NOT_PARTICIPATING: '결석',
  시작전: 'PRE_START',
  참여: 'PARTICIPATING',
  결석: 'NOT_PARTICIPATING',
};
