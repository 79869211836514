import { defineStore } from 'pinia';

import { CONTEST, CLASSIFICATION, SECTOR, EVENT } from '@/constants/contest/gridHeader';
import { getAllContest } from '@/apis/contest';
import { getContestItemByParentId, getAllContestItemByContestId, updateContestItemById, createContestItem } from '@/apis/contestItem';
import { PLAYER_INFO_TITLE } from '@/constants/contest';

export const useContestStore = defineStore('contest', {
  /**
   * contests : 대회
   * classifications : 분류
   * sectors : 부문
   * events : 종목
   */
  state: () => ({
    [CONTEST]: [],
    [CLASSIFICATION]: [],
    [SECTOR]: [],
    [EVENT]: [],
    contestItemAndPlayerSequences: [],
    allContestItems: [],
  }),
  actions: {
    async setDefaultContestInfos() {
      const contests = await getAllContest();

      this[CONTEST] = contests;
    },
    async fetchAllContestItems(contestId) {
      const classifications = await getAllContestItemByContestId(contestId);
      const ret = classifications.map((classification) => classification.subContestItems.map((sector) => sector.subContestItems).flat()).flat();

      this.allContestItems = ret;

      return ret;
    },
    async fetchContestItemByParentId(parentId) {
      const contestItem = await getContestItemByParentId(parentId);
      const parsed = contestItem.map((v) => ({
        title: v.name,
        code: v.code,
        displayStatus: v.displayStatus,
        id: v.id,
      }));

      return parsed;
    },
    async fetchClassificationByContestId(contestId) {
      const classifications = await getAllContestItemByContestId(contestId);
      const contestInfo = this[CONTEST].find((v) => v.id === contestId);
      const classificationInfos = classifications.map((v) => ({
        id: v.id,
        title: v.name,
        code: v.code,
        displayStatus: v.displayStatus,
        contestTitle: contestInfo.name,
        contestId: contestInfo.id,
        classificationTitle: v.name,
        classificationCode: v.code,
        contestRegisterType: PLAYER_INFO_TITLE[v.contestRegisterType],
      }));

      this[CLASSIFICATION] = classificationInfos;

      return classificationInfos;
    },
    async fetchSectorByParentIds(contestId, classificationId) {
      const sectorInfos = await this.fetchContestItemByParentId(classificationId);

      const classificationInfo = this[CLASSIFICATION].find((v) => v.id === classificationId);
      const contestInfo = this[CONTEST].find((v) => v.id === contestId);

      const parsed = sectorInfos.map((v) => ({
        id: v.id,
        title: v.title,
        code: v.code,
        displayStatus: v.displayStatus,
        contestId: contestInfo.id,
        contestTitle: contestInfo.title,
        classificationTitle: classificationInfo.title,
        classificationCode: classificationInfo.code,
        sectorCode: v.code,
        sectorTitle: v.title,
      }));
      this[SECTOR] = parsed;

      return parsed;
    },
    async fetchEventByParentIds(contestId, classificationId, sectorId) {
      const eventInfos = await this.fetchContestItemByParentId(sectorId);

      const contestInfo = this[CONTEST].find((v) => v.id === contestId);
      const classificationInfo = this[CLASSIFICATION].find((v) => v.id === classificationId);
      const sectorInfo = this[SECTOR].find((v) => v.id === sectorId);

      const parsed = eventInfos.map((v) => ({
        id: v.id,
        title: v.title,
        code: v.code,
        displayStatus: v.displayStatus,
        contestId: contestInfo.id,
        contestTitle: contestInfo.title,
        classificationTitle: classificationInfo.title,
        classificationCode: classificationInfo.code,
        sectorTitle: sectorInfo.title,
        sectorCode: sectorInfo.code,
        eventTitle: v.title,
        eventCode: v.code,
      }));

      this[EVENT] = parsed;

      return parsed;
    },
    async updateContestItem(contestItemId, name, code, type) {
      await updateContestItemById({ contestItemId, name, code });
      this[type] = this[type].map((v) => {
        if (contestItemId === v.id) {
          return {
            ...v,
            name,
            code,
          };
        }

        return v;
      });
    },
    async registContestItem({ contestId, parentId, name, code, type, contestRegisterType }) {
      await createContestItem({ name, contestId, parentId, code, contestRegisterType });
      await this.dataRefreshHandler(contestId, parentId, type);
    },
    async dataRefreshHandler(contestId, parentId, type) {
      const refreshApiHandler = {
        [CLASSIFICATION]: this.fetchClassificationByContestId,
        [SECTOR]: this.fetchSectorByParentIds,
        [EVENT]: (cId, pId) => {
          const sectorInfo = this[SECTOR].find((v) => v.id === pId);
          const classificationInfo = this[CLASSIFICATION].find((v) => v.code === sectorInfo.classificationCode);

          if (classificationInfo) {
            return this.fetchEventByParentIds(cId, classificationInfo.id, parentId);
          }

          return Promise.resolve();
        },
      };

      await refreshApiHandler[type](contestId, parentId);
    },
  },
});
