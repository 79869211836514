import axios from '@/lib/axiosController';

export const showPost = (postId) => axios.put(`/post/show/${postId}`);

export const showPosts = (postIds) => axios.put(`/post/show-posts`, { postIds });

export const getPost = (postId) => axios.get(`/post/${postId}`);

export const searchPosts = (condition) => axios.get(`/post/search`, { params: condition });

export const deletePost = (postId) => axios.delete(`/post/delete/${postId}`);

export const deletePosts = (postIds) => axios.delete(`/post/delete-posts`, { data: { postIds } });
