import axios from '@/lib/axiosController';

export const showReply = (replyId) => axios.put(`/reply/show/${replyId}`);

export const showReplies = (replyIds) => axios.put(`/reply/show-replys`, { replyIds });

export const getReplies = (postId, condition) => axios.get(`/reply/post/${postId}`, { params: condition });

export const deleteReply = (replyId) => axios.delete(`/reply/delete/${replyId}`);

export const deleteReplies = (replyIds) => axios.delete(`/reply/delete-replies`, { data: { replyIds } });
