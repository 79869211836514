export const SCHEDULE_MANAGEMENT_HEADER = [
  {
    header: '일정명',
    name: 'title',
    editor: 'text',
  },
  {
    header: '일정 요약',
    name: 'description',
    editor: 'text',
  },
  {
    header: '일정 시작일',
    name: 'startDate',
    editor: {
      type: 'datePicker',
      options: {
        format: 'yyyy-MM-dd',
        timepicker: true,
        language: 'ko',
      },
    },
  },
  {
    header: '일정 종료일',
    name: 'endDate',
    editor: {
      type: 'datePicker',
      options: {
        format: 'yyyy-MM-dd',
        timepicker: true,
        language: 'ko',
      },
    },
  },
];
