<template>
  <main-content-box>
    <template #main>
      <div>
        <h5>이미지 뷰어</h5>
        <div class="text-grey mb-10" style="font-size: 15px">임시 이미지 뷰어입니다.</div>
      </div>
      <div class="d-flex justify-center align-center mb-10">
        <img alt="이미지" :src="url" width="100%" height="100%" style="max-width: 800px; min-height: 800px; width: 100%; height: auto" />
      </div>
    </template>
  </main-content-box>
</template>

<script>
import { useRoute } from 'vue-router';
import MainContentBox from '@/components/MainContentBox.vue';

export default {
  name: 'ImageViewer',
  components: { MainContentBox },
  setup() {
    const route = useRoute();
    const url = (route.params.url || '').replaceAll('._.', '/');
    return {
      url,
    };
  },
};
</script>

<style scoped></style>
