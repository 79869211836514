export const LIST_CONTESTANT_HEADER = [
  {
    header: '이름',
    name: 'name',
    sortable: true,
  },
  {
    header: '참가번호',
    name: 'playerSequence',
  },
  {
    header: '참가종목 수',
    name: 'playerContestItemCount',
  },
  {
    header: '부문명',
    name: 'classcificationName',
    filter: { type: 'select', showApplyBtn: true, showClearBtn: true },
    width: 180,
  },
  {
    header: '대표참가종목',
    name: 'contestItemName',
    filter: { type: 'select', showApplyBtn: true, showClearBtn: true },
    width: 180,
  },
  {
    header: '지회(지부)',
    name: 'branchOfficeName',
    filter: { type: 'select', showApplyBtn: true, showClearBtn: true },
  },
  {
    header: '소속(업소/학교)',
    name: 'associationName',
  },
  {
    header: '추천인',
    name: 'recommenderName',
  },
  {
    header: '연락처',
    name: 'phone',
  },
  {
    header: '생년월일',
    name: 'birthDay',
  },
  {
    header: '등록일',
    name: 'createdDate',
  },
  {
    header: '사진 보기',
    name: 'image',
    formatter: function ({ row }) {
      const idImage = (row?.playerFiles || []).find((e) => e?.category === 'ID_PHOTO');
      if (idImage?.url) {
        return '<a href="/static/image/' + idImage.url.replaceAll('/', '._.') + '" target="_blank">이미지</a>';
      }
      return '';
    },
  },
  {
    header: '자격증',
    name: 'certificate',
    formatter: function ({ row }) {
      let result = '';
      const certificate = (row?.playerFiles || []).find((e) => e?.category === 'CERTIFICATE');
      const businessRegistration = (row?.playerFiles || []).find((e) => e?.category === 'BUSINESS_REGISTRATION');
      const enrollVerifiationLetter = (row?.playerFiles || []).find((e) => e?.category === 'ENROLLMENT_VERIFICATION_LETTER');
      const smallBusinessConfirmation = (row?.playerFiles || []).find((e) => e?.category === 'SMALL_BUSINESS_CONFIRMATION');
      if (certificate?.url) {
        if (isImage(certificate)) {
          result += '<a href="/static/image/' + certificate.url.replaceAll('/', '._.') + '" target="_blank">자격증/면허증 사본</a><br/>';
        } else {
          result += `<a href="${certificate.url}" download="${row.name}_자격증/면허증 사본">자격증/면허증 사본</a><br/>`;
        }
      }
      if (businessRegistration?.url) {
        if (isImage(businessRegistration)) {
          result += '<a href="/static/image/' + businessRegistration.url.replaceAll('/', '._.') + '" target="_blank">사업자등록증</a><br/>';
        } else {
          result += `<a href="${businessRegistration.url}" download="${row.name}_사업자등록증">사업자등록증</a><br/>`;
        }
      }
      if (enrollVerifiationLetter?.url) {
        if (isImage(enrollVerifiationLetter)) {
          result += '<a href="/static/image/' + enrollVerifiationLetter.url.replaceAll('/', '._.') + '" target="_blank">재학증명서</a><br/>';
        } else {
          result += `<a href="${enrollVerifiationLetter.url}" download="${row.name}_재학증명서">재학증명서</a><br/>`;
        }
      }
      if (smallBusinessConfirmation?.url) {
        if (isImage(smallBusinessConfirmation)) {
          result += '<a href="/static/image/' + smallBusinessConfirmation.url.replaceAll('/', '._.') + '" target="_blank">미용사 면허증</a><br/>';
        } else {
          result += `<a href="${smallBusinessConfirmation.url}" download="${row.name}_미용사면허증">미용사 면허증</a><br/>`;
        }
      }
      return result;
    },
  },
  {
    header: '비고',
    name: 'note',
  },
];

const isImage = ({ url }) => {
  let reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
  return url.match(reg);
};

export const DEFAULT_OPTIONS = {
  align: 'center',
  sortable: true,
};
