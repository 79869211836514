<template>
  <form v-if="showDropdown" @submit="submitForm" style="display: flex" class="w-100 justify-content-end">
    <div class="w-15 mt-lg-0 select-wrapper mr-1">
      <select :id="'choices-news-' + selectId" @change="changeNewsId" class="form-control select" :name="'choices-news-' + selectId">
        <option key="-1" value="">전체</option>
        <option v-for="(news, idx) in newsList" :key="idx" :value="news.id">
          {{ news.title }}
        </option>
      </select>
    </div>
    <div class="w-15 mt-lg-0 select-wrapper">
      <select :id="'choices-' + selectId" @change="changeOption" class="form-control select" :name="'choices-' + selectId">
        <option v-for="(option, idx) in options" :key="idx" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="w-25 input-group input-search">
      <input type="search" :value="keyword" @input="changeKeyword" @keydown="keyDownHandler" @blur="blurInput" class="form-control" placeholder="검색하기" />
      <button type="submit" class="input-group-text text-body">
        <i class="fas fa-search" aria-hidden="true"></i>
      </button>
    </div>
  </form>
</template>

<style scoped lang="scss">
.input-search {
  margin-left: 4px;
  margin-right: 5px;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-right: 1px solid #d2d6da !important;
}

.select-wrapper:deep .choices__item--choice {
  padding: 0.3rem 0.5rem;
}
</style>

<script>
import { isEmpty } from 'lodash-es';
import { computed, ref } from 'vue';
const useEventHandler = (props, keyword, selectedOption, selectedNewsId) => {
  const changeNewsId = (e) => {
    selectedNewsId.value = e.target.value;
  };
  const changeOption = (e) => {
    selectedOption.value = e.target.value;
  };
  const changeKeyword = (e) => {
    keyword.value = e.target.value;
    props.onChange?.({ e, keyword: keyword.value, option: selectedOption.value });
  };
  const blurInput = (e) => {
    props.onblur?.({ e, keyword: keyword.value, option: selectedOption.value });
  };
  const submitForm = (e) => {
    e.preventDefault();
    props.onSubmit?.({ e, keyword: keyword.value, option: selectedOption.value, newsId: selectedNewsId.value });
  };

  const keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      submitForm(e);
    }
  };

  return { changeNewsId, changeOption, changeKeyword, blurInput, submitForm, keyDownHandler };
};

export default {
  name: 'NewsSearchBox',
  props: {
    newsList: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selectId: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
    },
    onBlur: {
      type: Function,
    },
    onSubmit: {
      type: Function,
    },
  },
  setup(props) {
    const showDropdown = computed(() => !isEmpty(props.options));

    const keyword = ref('');
    const selectedOption = ref(props.options[0].value);
    const selectedNewsId = ref();

    const { changeNewsId, changeOption, changeKeyword, blurInput, submitForm, keyDownHandler } = useEventHandler(
      props,
      keyword,
      selectedOption,
      selectedNewsId,
    );

    return {
      showDropdown,
      keyword,
      selectedOption,
      selectedNewsId,
      changeNewsId,
      changeOption,
      changeKeyword,
      blurInput,
      submitForm,
      keyDownHandler,
    };
  },
};
</script>
