<template>
  <div class="p-3 pt-0 pb-0 card-footer d-flex align-items-center">
    <p class="text-sm">
      {{ desc }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'CardFooter',
  props: {
    desc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style></style>
