<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <!--        카드 헤더-->
        <board-card-header :title="title" :description="description">
          <template #header-utils>
            <div class="header-utils-buttons">
              <button class="mb-0 btn btn-success" v-if="!disableHeaderUtils" @click="showPosts"><i class="fas fa-eye me-2" />선택 보여주기</button>
              <button class="mb-0 btn btn-warning" v-if="!disableHeaderUtils" @click="deletePosts"><i class="fas fa-trash me-2" />선택 삭제하기</button>
            </div>
          </template>
        </board-card-header>
        <!--        카드 바디-->
        <div class="px-0 pb-0 card-body pt-0">
          <!--          드롭다운 & 검색창-->
          <board-search-box select-id="search-types" :options="options" :onSubmit="submitHandler" />
          <!--       테이블-->
          <board-table-container @click="tableHandler" :table-id="tableId">
            <template #table-header>
              <board-table-header :head-cells="headCells" show-checkbox />
            </template>
            <template #table-body>
              <tbody>
                <board-table-row v-for="row of rows" :key="row.id" :post-id="row.id" show-actions show-checkbox>
                  <template #table-data>
                    <td class="text-sm">
                      <router-link :to="`/community/post-detail/${row.id}`">{{ row.title }}</router-link>
                    </td>
                    <td class="text-sm">{{ row.userName }}</td>
                    <td class="text-sm">{{ row.createdDate }}</td>
                    <td class="text-sm">{{ row.modifiedDate }}</td>
                    <td class="text-sm">
                      <span :class="`badge badge-${row.displayStatus === 'SHOW' ? 'success' : 'danger'} badge-sm`">
                        {{ row.displayStatus === 'SHOW' ? '활성화됨' : '삭제됨' }}
                      </span>
                    </td>
                    <td class="text-sm">
                      <button
                        v-if="row.displayStatus === 'DELETE'"
                        class="action_button"
                        name="showPost"
                        :data-element-name="actionsShowPost"
                        :data-post-id="row.id"
                      >
                        보여주기
                      </button>
                      <button v-else class="action_button" name="deletePost" :data-element-name="actionsDeletePost" :data-post-id="row.id">삭제하기</button>
                    </td>
                  </template>
                </board-table-row>
              </tbody>
            </template>
          </board-table-container>
          <div class="text-center" v-if="!rows || rows.length === 0">등록된 게시글이 없습니다.</div>
          <argon-pagination>
            <argon-pagination-item v-if="pageInfo.isFirst" :key="pageInfo.first" :active="false" :first="true" @click="getPagePosts(pageInfo.first)" />
            <argon-pagination-item v-if="pageInfo.isPrev" :key="pageInfo.prev" :active="false" :prev="true" @click="getPagePosts(pageInfo.prev)" />
            <argon-pagination-item v-for="i in pageInfo.pageItems" :key="i" :label="i.toString()" :active="i === pageInfo.current" @click="getPagePosts(i)" />
            <argon-pagination-item v-if="pageInfo.isNext" :key="pageInfo.next" :active="false" :next="true" @click="getPagePosts(pageInfo.next)" />
            <argon-pagination-item v-if="pageInfo.isLast" :key="pageInfo.last" :active="false" :last="true" @click="getPagePosts(pageInfo.last)" />
          </argon-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-utils-buttons {
  & > button + button {
    margin-left: 8px;
  }
}
</style>

<script>
import BoardCardHeader from '@/views/components/community/boardCard/BoardCardHeader.vue';
import BoardSearchBox from '@/views/components/community/boardCard/BoardSearchBox.vue';
import BoardTableContainer from '@/views/components/community/boardCard/BoardTableContainer.vue';
import BoardTableHeader from '@/views/components/community/boardCard/BoardTableHeader.vue';
import BoardTableRow from '@/views/components/community/boardCard/BoardTableRow.vue';
import { TABLE_CHECKBOX, ACTIONS_DELETE_POST, ACTIONS_SHOW_POST } from '@/constants/community/board';
import { OPTIONS, HEAD_CELLS } from '@/constants/community/commonBoard';
import { computed, onMounted, ref, inject } from 'vue';
import { deletePosts, deletePost, showPost, showPosts, searchPosts } from '@/apis/post';
import ArgonPagination from '@/components/ArgonPagination.vue';
import ArgonPaginationItem from '@/components/ArgonPaginationItem.vue';
import moment from 'moment';

const usePost = (selected, rows, pageInfo, condition, pagesSize) => {
  const swal = inject('$swal');

  const removePosts = async () => {
    const selectedList = [...selected.value].map((s) => s.id);
    await swal('삭제하시겠습니까?');
    try {
      await deletePosts(selectedList);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    [...selected.value].forEach((s) => (s.checked = false));
    await searchPagePosts();
  };
  const removePost = async (postId) => {
    await swal.fire('삭제하시겠습니까?');
    try {
      await deletePost(postId);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    await searchPagePosts();
  };
  const openPosts = async () => {
    const selectedList = [...selected.value].map((s) => s.id);
    await swal.fire('복구 하시겠습니까?');
    try {
      await showPosts(selectedList);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    [...selected.value].forEach((s) => (s.checked = false));
    await searchPagePosts();
  };
  const openPost = async (postId) => {
    await swal.fire('복구 하시겠습니까?', `복구할 게시글 id ${postId}`);
    try {
      await showPost(postId);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    await searchPagePosts();
  };

  const searchPagePosts = async () => {
    selected.value = new Set();
    const result = await searchPosts(condition);
    rows.value = result.content.map((p) => {
      return {
        ...p,
        createdDate: moment(p.createdDate).format('YYYY년 MM월 DD일 HH:mm'),
        modifiedDate: p.modifiedDate ? moment(p.modifiedDate).format('YYYY년 MM월 DD일 HH:mm') : '',
      };
    });

    const { number, totalPages } = result;
    let size = pagesSize.value;

    const quotient = parseInt(number / size);

    const start = quotient * size + 1;
    const end = quotient * size + size > totalPages ? totalPages % size : size;

    pageInfo.value = {
      isFirst: quotient !== 0,
      isPrev: quotient !== 0,
      isNext: quotient * size + size < totalPages,
      isLast: quotient * size + size < totalPages,
      first: 1,
      last: totalPages,
      prev: quotient * size,
      next: (quotient + 1) * size + 1,
      current: number + 1,
      pageItems: Array.apply(0, Array(end)).map((element, index) => index + start),
    };
  };

  return { deletePosts: removePosts, deletePost: removePost, showPosts: openPosts, showPost: openPost, searchPagePosts };
};

export default {
  name: 'CommonBoard',
  props: {
    tableId: {
      type: String,
      required: true,
    },
    boardGroup: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  components: {
    ArgonPagination,
    ArgonPaginationItem,
    BoardCardHeader,
    BoardSearchBox,
    BoardTableContainer,
    BoardTableHeader,
    BoardTableRow,
  },
  setup(props) {
    const rows = ref([]);
    const pagesSize = ref(5);
    const pageInfo = ref({
      prev: 0,
      next: 0,
      first: 0,
      last: 0,
      isPrev: 0,
      isNext: 0,
      isFirst: 0,
      isLast: 0,
      current: 0,
      pageItems: [],
    });

    const condition = {
      size: 10,
      page: 0,
      boardGroup: props.boardGroup,
      keyword: null,
      userName: null,
    };

    const selected = ref(new Set());

    onMounted(async () => {
      await searchPagePosts();
    });

    const disableHeaderUtils = computed(() => selected.value.size <= 0);

    const { deletePosts, deletePost, showPosts, showPost, searchPagePosts } = usePost(selected, rows, pageInfo, condition, pagesSize);

    const submitHandler = ({ keyword, option }) => {
      if (option === 'title') {
        condition.keyword = keyword;
        condition.userName = null;
      } else {
        condition.userName = keyword;
        condition.keyword = null;
      }
      condition.page = 0;
      searchPagePosts();
    };

    const checkboxHandler = (target) => {
      if (selected.value.has(target)) {
        selected.value.delete(target);
        return;
      }
      selected.value.add(target);
    };

    const tableHandler = (e) => {
      const { elementName, postId } = e.target.dataset;

      if (!elementName) return;

      if (elementName === TABLE_CHECKBOX) {
        checkboxHandler(e.target);
        return;
      }

      if (elementName === ACTIONS_DELETE_POST) {
        deletePost(postId);
        return;
      }

      if (elementName === ACTIONS_SHOW_POST) {
        showPost(postId);
      }
    };

    const getPagePosts = async (i) => {
      condition.page = i - 1;
      await searchPagePosts();
    };

    return {
      selected,
      disableHeaderUtils,
      options: OPTIONS,
      headCells: HEAD_CELLS,
      rows,
      pagesSize,
      pageInfo,
      submitHandler,
      deletePosts,
      deletePost,
      showPosts,
      showPost,
      checkboxHandler,
      tableHandler,
      getPagePosts,
      actionsShowPost: ACTIONS_SHOW_POST,
      actionsDeletePost: ACTIONS_DELETE_POST,
    };
  },
};
</script>
