<template>
  <main-content-box>
    <template #main>
      <div v-if="!isEditMode">
        <div class="contestItemManagement__row">
          <v-row>
            <v-col cols="8">
              <contest-select-group :defaultSelectedInfo="contestItemSelectedId" :selectHandler="itemSelectHandler" :mode="EVENT"></contest-select-group>
            </v-col>
            <v-col cols="3">
              <v-text-field solo clearable v-model="contestSelectInfo.playerName" placeholder="선수명"></v-text-field>
            </v-col>
            <v-col cols="1" align-self="center">
              <v-btn class="mr-4" color="success" @click="onSearch" :disabled="isSearchLoading">조회하기</v-btn>
            </v-col>
          </v-row>
        </div>
        <grid-view
          id="contestant-grid-view-body"
          ref="gridRef"
          title="선수목록 조회"
          description="선수 목록을 더블클릭하면, 선수 접수세부내용을 조회/수정 할 수 있습니다."
          :columns="columns"
          :data="data"
          :column-options="columnOptions"
          :row-headers="rowHeaders"
          :on-double-click="moveToContestantSpecificPage"
          :is-edit-hide="true"
        >
          <template #addBtn>
            <v-btn text :disabled="data.length === 0" value="print" color="info" @click="print"> 인쇄하기 </v-btn>
            <v-btn class="ml-4" text :disabled="data.length === 0" value="excel" color="success" @click="exportToExcel"> 엑셀 다운로드 </v-btn>
            <v-btn style="color: white" class="ml-4" text :disabled="data.length === 0" value="delete" color="danger" @click="onDeletePlayers">선수삭제</v-btn>
          </template>
        </grid-view>
      </div>
      <contest-form
        v-else
        :defaultFileUrl="fileUrl"
        :defaultPlayerInfo="playerInfo"
        :defaultRecommenderInfo="recommenderInfo"
        :submitHandler="onClickEdit"
        :isLoading="isLoading"
        :cancelHandler="onClickEditCancel"
        :contestSelectGroupDisabledKey="{ [CONTEST]: true }"
        buttonText="수정하기"
        isUpdateForm
      />
    </template>
  </main-content-box>
</template>

<script>
import { ref } from 'vue';
import { isEmpty } from 'lodash-es';
import { getContestPlayerList, modifyPlayer, getPlayerDetail, deletePlayer } from '@/apis/contestant';

import MainContentBox from '@/components/MainContentBox.vue';

import GridView from '@/views/components/common/GridView';
import ContestSelectGroup from '@/views/components/contest/ContestSelectGroup';
import ContestForm from '@/views/components/contest/ContestForm.vue';

import { FILE_CATEGORY } from '@/constants/contest';
import { LIST_CONTESTANT_HEADER } from '@/constants/grid/gridHeader';
import { CONTEST, CLASSIFICATION, EVENT, SECTOR } from '@/constants/contest/gridHeader';

import { validateFormData, convertDataForApi } from '@/utils/contest';
import { warningToast, successToast } from '@/utils/toastUtil';
import { useBranchStore } from '@/store/branch';
import moment from 'moment';

const columns = LIST_CONTESTANT_HEADER;

const columnOptions = {
  resizable: true,
};

/**
 * 선수목록 페이지
 */
export default {
  name: 'ContestantList',
  components: { GridView, MainContentBox, ContestForm, ContestSelectGroup },
  setup() {
    const data = ref([]);
    const branchStore = useBranchStore();
    const contestSelectInfo = ref({
      contestId: undefined,
      contestItemId: undefined,
      playerName: null,
    });

    const isEditMode = ref(false);
    const isLoading = ref(false);
    const isSearchLoading = ref(false);
    const playerInfo = ref({});
    const recommenderInfo = ref({});
    const gridRef = ref({});

    const contestItemSelectedId = ref({
      [CONTEST]: '',
      [CLASSIFICATION]: '',
      [SECTOR]: '',
      [EVENT]: '',
    });

    const fileUrl = ref({
      [FILE_CATEGORY.ID_PHOTO]: '',
      [FILE_CATEGORY.CERTIFICATE]: '',
      [FILE_CATEGORY.BUSINESS_REGISTRATION]: '',
      [FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER]: '',
      [FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION]: '',
    });

    const onDeletePlayers = async () => {
      const checkedPlayers = (gridRef.value.getCheckedRows() || []).map((e) => e.id);

      if (checkedPlayers?.length === 0) {
        warningToast('삭제할 선수를 선택해주세요.');
      }
      if (window.confirm(`${checkedPlayers?.length} 명의 선수정보를 삭제하시겠습니까? 삭제된 선수데이터는 복구가 불가합니다.`)) {
        await deletePlayer({ playerIds: checkedPlayers });
        successToast('선수 삭제에 성공하였습니다.');
        onSearch();
      }
    };

    const exportToExcel = () => {
      gridRef.value.exportExcel({
        columnNames: columns.map((e) => e.name).filter((n) => n !== 'image' && n !== 'certificate'),
        useFormattedValue: true,
        fileName: `선수목록-${moment().format('YYYY-MM-DD HH:mm:ss')}`,
      });
    };

    const itemSelectHandler = (selectedId, type) => {
      if (type === CONTEST) {
        contestSelectInfo.value.contestId = selectedId;
        contestSelectInfo.value.contestItemId = null;
      } else {
        contestSelectInfo.value.contestItemId = selectedId;
      }
    };

    const fetchEditData = async (playerId) => {
      isLoading.value = true;

      if (playerId !== 0) {
        const playerDetailData = await getPlayerDetail(playerId);

        if (!isEmpty(playerDetailData)) {
          const {
            id,
            name,
            birthDay,
            phone,
            address,
            addressDetail,
            zip,
            businessName,
            note,
            contestRegisterType,
            associationType,
            branchOfficeId,
            branchOfficeName,
            associationName,
            contestId,
            recommender,
            playerFiles,
            playerMirrorMappings,
          } = playerDetailData;

          const displayedFilesInfo = playerFiles.reduce((obj, prev) => {
            fileUrl.value[prev.category] = prev.url;
            obj[prev.category] = [new File([], prev.name)];

            return obj;
          }, {});

          playerInfo.value = {
            id,
            name,
            birthday: birthDay,
            phone,
            address,
            addressDetail,
            zip,
            businessName,
            contestRegisterType,
            associationType,
            branchOfficeId,
            regionName: branchStore.branchInfoArray.find((v) => v.id === branchOfficeId)?.region,
            branchName: branchOfficeName,
            branchOfficeName,
            associationName,
            contestId,
            playerFiles,
            attachmentList: [],
            note,
            contestItemAndPlayerSequences: {
              createMirrorMappings: [],
              modifyMirrorMappings: playerMirrorMappings.map((v) => ({ id: v.id, contestItemId: v.contestItemId, playerSequence: v.playerSequence })),
              deleteMirrorMappings: [],
            },
            ...displayedFilesInfo,
          };
          recommenderInfo.value = {
            ...recommender,
            regionName: branchStore.branchInfoArray.find((v) => v.id === recommender.branchOfficeId)?.region,
            branchName: recommender.branchOfficeName,
          };
          isEditMode.value = true;
        }
      }

      isLoading.value = false;
    };

    const onSearch = async () => {
      if (!contestSelectInfo.value?.contestId) {
        warningToast('대회는 필수 선택값입니다.');
        return;
      }

      isSearchLoading.value = true;
      try {
        let result = await getContestPlayerList(contestSelectInfo.value);
        if (result?.length === 0) {
          warningToast('검색결과가 없습니다.');
        } else {
          successToast('선수 검색에 성공했습니다.');
        }

        // fixme 소속 업소명 노출을 위한 temporal change
        if (result?.length !== 0) {
          result = result.map((e) => ({
            ...e,
            associationName: e?.contestRegisterType !== 'STUDENT' ? e?.businessName : e.associationName,
          }));
        }

        data.value = result;
      } catch (e) {
        isSearchLoading.value = false;
      }

      isSearchLoading.value = false;
    };

    const onClickEdit = async () => {
      try {
        const validInfo = validateFormData(playerInfo.value, recommenderInfo.value, playerInfo.value.contestRegisterType);

        if (!validInfo.isValid) {
          return validInfo;
        }

        const { convertedPlayerInfo, convertedRecommender } = convertDataForApi(playerInfo.value, recommenderInfo.value, true);

        const form = { ...convertedPlayerInfo, birthDay: convertedPlayerInfo.birthday, recommender: convertedRecommender };
        const formData = new FormData();

        formData.append('request', new Blob([JSON.stringify(form)], { type: 'application/json' }));

        form.playerFiles.forEach(({ category, url }, index) => {
          /**
           * 수정되지 않은 데이터는 formData에 포함시키지 않음
           */
          if (!url) {
            formData.append(category, form.attachmentList[index]);
          }
        });

        await modifyPlayer(convertedPlayerInfo.id, formData);
        await onSearch();

        isEditMode.value = false;

        return { isValid: true };
      } catch (e) {
        console.error(e);
      }
    };

    const moveToContestantSpecificPage = (e) => {
      const grid = e?.instance;
      const row = grid.getRow(e?.rowKey);

      if (row.id) {
        fetchEditData(row.id);
      }
    };

    const onClickEditCancel = () => {
      isEditMode.value = false;
    };

    const rowHeaders = ['rowNum', 'checkbox'];

    const print = () => {
      const initBody = document.body.innerHTML;
      window.onbeforeprint = function () {
        document.body.style.zoom = 0.5;
        document.body.innerHTML = document.getElementById('contestant-grid-view-body').innerHTML;
      };
      window.onafterprint = function () {
        document.body.innerHTML = initBody;
        document.body.style.zoom = 1.0;
      };
      window.print();
      location.reload();
    };

    return {
      contestItemSelectedId,
      columns,
      data,
      fileUrl,
      columnOptions,
      rowHeaders,
      isEditMode,
      isLoading,
      isSearchLoading,
      playerInfo,
      recommenderInfo,
      itemSelectHandler,
      moveToContestantSpecificPage,
      onSearch,
      onClickEdit,
      onClickEditCancel,
      CONTEST,
      SECTOR,
      EVENT,
      gridRef,
      exportToExcel,
      contestSelectInfo,
      print,
      onDeletePlayers,
    };
  },
};
</script>

<style lang="scss" scoped>
.contestItemManagement {
  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
}
</style>
