<template>
  <div class="mb-4">
    <div class="mb-2" v-if="label !== ''">
      <required-caption :disabled="disabled" :label="label" :required="required" />
    </div>
    <Datepicker
      :modelValue="date"
      :range="range"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :format="formatter"
      locale="ko"
      cancelText="취소"
      selectText="선택"
      :modelType="modelType"
      :autoApply="autoApply"
      @update:modelValue="(value) => $emit('update:date', value)"
      :monthPicker="monthPicker"
      :enableTimePicker="!onlyDate"
    ></Datepicker>
    <div>
      <small v-if="errorText !== ''" class="error-msg">{{ errorText }}</small>
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';

import RequiredCaption from '@/components/RequiredCaption.vue';

export default {
  components: { RequiredCaption, Datepicker },
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: null,
    },
    errorText: {
      type: String,
      default: '',
    },
    date: {
      /**
       * Datepicker Value Type :  https://vue3datepicker.com/api/props/#modelvalue
       */
      type: [Date, String, Array],
      default: new Date(),
    },
    autoApply: {
      type: Boolean,
      default: false,
    },
    onlyDate: {
      type: Boolean,
      default: false,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const format = (date) => {
      let dateObj = date;
      let text = '';

      if (typeof date === 'string') {
        dateObj = new Date(date);
      }

      if (!props.monthPicker) {
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();

        text = `${year}년 ${month}월 ${day}일`;

        if (!props.onlyDate) {
          const hour = dateObj.getHours();
          const minute = dateObj.getMinutes();

          text += ` ${hour}시 ${minute}분`;
        }
      } else {
        text = `${date.year}년 ${date.month + 1}월`;
      }

      return text;
    };

    const formatter = (date) => {
      if (props.range && !date[1]) {
        return null;
      }

      if (Array.isArray(date)) {
        return `${format(date[0])} ~ ${format(date[1])}`;
      }

      return format(date);
    };

    return { formatter };
  },
};
</script>

<style lang="scss" scoped>
.error-msg {
  color: red;
}
</style>
