export const AdminAuthorityType = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  MANAGER: 'MANAGER',
  USER: 'USER',
};

export const UserAuthorityGroupType = {
  AUTHORIZED_MEMBER: 'AUTHORIZED_MEMBER',
  NON_AUTHORIZED_MEMBER: 'NON_AUTHORIZED_MEMBER',
  NOT_MEMBER: 'NOT_MEMBER',
};
