import { toCamelCase } from '@/utils/string';

export const CONTEST_REGISTER_TYPE = {
  STUDENT: 'student',
  INDEPENDENT_BUSINESS: 'independent-business',
  GENERAL: 'general',
};

export const CONTEST_REGISTER_TYPE_PASCAL = {
  STUDENT: 'STUDENT',
  INDEPENDENT_BUSINESS: 'INDEPENDENT_BUSINESS',
  GENERAL: 'GENERAL',
};

export const ASSOCIATION_TYPE = {
  [CONTEST_REGISTER_TYPE_PASCAL.STUDENT]: 'EDUCATIONAL_INSTITUTION',
  [CONTEST_REGISTER_TYPE_PASCAL.INDEPENDENT_BUSINESS]: 'KOBA_BRANCH',
  [CONTEST_REGISTER_TYPE_PASCAL.GENERAL]: 'KOBA_BRANCH',
};

export const PLAYER_INFO_TITLE = {
  [CONTEST_REGISTER_TYPE.STUDENT]: '학생부',
  [CONTEST_REGISTER_TYPE.INDEPENDENT_BUSINESS]: '소상공인부',
  [CONTEST_REGISTER_TYPE.GENERAL]: '일반부',
  [CONTEST_REGISTER_TYPE_PASCAL.STUDENT]: '학생부',
  [CONTEST_REGISTER_TYPE_PASCAL.INDEPENDENT_BUSINESS]: '소상공인부',
  [CONTEST_REGISTER_TYPE_PASCAL.GENERAL]: '일반부',
};

export const ATTACHMENT_LIST = ['idPhoto', 'enrollmentVerificationLetter', 'certificate', 'businessRegistration', 'smallBusinessConfirmation'];

/** 파일 카테고리 명 */
export const FILE_CATEGORY = {
  ID_PHOTO: 'ID_PHOTO',
  CERTIFICATE: 'CERTIFICATE',
  BUSINESS_REGISTRATION: 'BUSINESS_REGISTRATION',
  ENROLLMENT_VERIFICATION_LETTER: 'ENROLLMENT_VERIFICATION_LETTER',
  SMALL_BUSINESS_CONFIRMATION: 'SMALL_BUSINESS_CONFIRMATION',
};

/** 파일 카테고리 명, 카테고리 ID MAP */
export const FILE_CATEGORY_ID_MAPPING = {
  [FILE_CATEGORY.ID_PHOTO]: 'idPhoto',
  [FILE_CATEGORY.CERTIFICATE]: 'certificate',
  [FILE_CATEGORY.BUSINESS_REGISTRATION]: 'businessRegistration',
  [FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER]: 'enrollmentVerificationLetter',
  [FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION]: 'smallBusinessConfirmation',
};

/** 파일 카테고리 PASCAL_CASE to map camelCase */
export const FILE_CATEGORY_CAMEL_CASE = Object.values(FILE_CATEGORY).reduce((obj, prev) => {
  obj[prev] = toCamelCase(prev);

  return obj;
}, {});

export const GENERAL_ATTR = {
  associationName: {
    label: '소속 (지회/지부)',
    placeholder: '소속 (지회/지부)',
  },
  name: {
    label: '지회장명 또는 기술강사명',
    placeholder: '성명',
  },
};

export const STUDENT_ATTR = {
  associationName: {
    label: '소속 (학교)',
    placeholder: '소속 (학교)',
  },
  name: {
    label: '선생님 또는 교수명',
    placeholder: '성명',
  },
};

/** 한글 인풋명 **/
export const PLAYER_INPUT_KR = {
  associationName: '소속',
  branchOfficeId: '지회/지부',
  name: '이름',
  birthday: '생년월일',
  phone: '연락처',
  address: '주소',
  addressDetail: '상세주소',
  password: '비밀번호',
  contestItemAndPlayerSequences: '종목',
  note: '비고',
  ID_PHOTO: '증명사진',
  ENROLLMENT_VERIFICATION_LETTER: '재학증명서',
  CERTIFICATE: '자격증 또는 면허증 사본',
  BUSINESS_REGISTRATION: '사업자 등록증',
  SMALL_BUSINESS_CONFIRMATION: '미용사 면허증',
};

export const RECOMMENDER_INPUT_KR = {
  associationName: '소속',
  name: '이름',
  phone: '연락처',
};

/** contestForm event 명 */
export const SET_INPUT = 'setInput';
export const SET_INPUT_ERROR = 'setInputError';
export const RESET_INPUT_ERROR = 'resetInputError';

export const rawDataColumn = {
  playerId: '선수 일련번호',
  BUSINESS_NAME: '업소명',
  CONTEST_REGISTER_TYPE: '대회 등록 타입',
  ASSOCIATION_TYPE: '선수 소속 타입',
  ASSOCIATION_NAME: '선수 소속명',
  BRANCH_OFFICE_ID: '선수 지회-지부 일련번호',
  BRANCH_NAME: '선수 지회-지부 명',
  REGION: '선수 지회-지부 지역',
  TYPE: '선수 지회-지부 타입',
  RECOMMENDER_NAME: '추천인 명',
  RECOMMENDER_ASSOCIATION_TYPE: '추천인 소속 타입',
  RECOMMENDER_ASSOCIATION_NAME: '추천인 소속명',
  RECOMMENDER_BRANCH_OFFICE_ID: '추천인 지회-지부 일련번호',
  RECOMMENDER_BRANCH_OFFICE_BRANCH_NAME: '추천인 지회-지부 명',
  RECOMMENDER_BRANCH_OFFICE_REGION: '추천인 지회-지부 지역',
  RECOMMENDER_BRANCH_OFFICE_TYPE: '추천인 지회-지부 타입',
  LOW_CONTEST_ITEM_ID: '종목 일련번호',
  LOW_CONTEST_ITEM_NAME: '종목명',
  LOW_CONTEST_ITEM_CODE: '종목 코드',
  MID_CONTEST_ITEM_ID: '부문 일련번호',
  MID_CONTEST_ITEM_NAME: '부문명',
  MID_CONTEST_ITEM_CODE: '부문 코드',
  TOP_CONTEST_ITEM_ID: '분류 일련번호',
  TOP_CONTEST_ITEM_NAME: '분류명',
  TOP_CONTEST_ITEM_CODE: '분류 코드',
};
