<template>
  <div class="py-4 container-fluid gap-3 d-flex flex-column">
    <!--    <div class="row gy-3">-->
    <!--      <div class="col-md-12 col-lg-6">-->
    <!--        <counter-card id="all" :count="63847543" suffix=" 명" title="전체 회원"></counter-card>-->
    <!--      </div>-->
    <!--      <div class="col-md-12 col-lg-6">-->
    <!--        <counter-card id="subscribers" :count="44563" suffix=" 명" title="미용 회보 구독 회원"></counter-card>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="row gy-3">
      <div class="col-md-12 col-lg-6">
        <div class="col-md-6 col-lg-12 mb-3">
          <doughnut-chart id="dau-today" title="금일 페이지별 클릭 수 추이" :chart="todayStatistic" />
        </div>
        <basic-card title="대회 목록">
          <div class="list-group list-group-flush">
            <grey-line class="mb-1" />
            <div v-for="item in contestList" class="my-1" :key="item.id">
              <h6 style="font-size: 18px">{{ item.title }}</h6>
              <div class="d-flex flex-row justify-space-between">
                <div class="d-flex flex-column mt-4 mr-4">
                  <small>대회 접수기간</small>
                  <h6>{{ toYYYYMMDD(item.applicationStartDate) }} ~ {{ toYYYYMMDD(item.applicationEndDate) }}</h6>
                </div>
                <div v-if="isAfterFromNow(item.applicationEndDate)" class="d-flex flex-column mt-4 align-items-end">
                  <small>접수 마감까지</small>
                  <h6>{{ getDDay(item.applicationEndDate) }} 일</h6>
                </div>
                <div v-else class="d-flex flex-column mt-4 align-items-end">
                  <small>접수 마감</small>
                  <h6>접수 마감</h6>
                </div>
              </div>
              <div class="d-flex flex-row justify-space-between mt-0">
                <div class="d-flex flex-column mr-4">
                  <small>대회 기간</small>
                  <h6>{{ toYYYYMMDD(item.startDate) }} ~ {{ toYYYYMMDD(item.endDate) }}</h6>
                </div>
                <div v-if="isAfterFromNow(item.startDate)" class="d-flex flex-column align-items-end">
                  <small>대회 시작까지</small>
                  <h6>{{ getDDay(item.startDate) }} 일</h6>
                </div>
                <div v-else-if="isBetweenFromNow(item.startDate, item.endDate)" class="d-flex flex-column align-items-end">
                  <small>대회중</small>
                  <h6>대회중</h6>
                </div>
                <div v-else class="d-flex flex-column align-items-end">
                  <small>대회 종료</small>
                  <h6>대회 종료</h6>
                </div>
              </div>
              <grey-line />
            </div>
          </div>
        </basic-card>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="col-md-6 col-lg-12 mb-3">
          <bar-chart id="dau-weekly" title="일별 클릭 수" :chart="weeklyStatistic"></bar-chart>
        </div>
        <div class="col-md-6 col-lg-12">
          <bar-chart id="mau-monthly" title="월별 클릭 수" :chart="monthlyStatistic"></bar-chart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';

// import CounterCard from '@/components/Card/CounterCard.vue';
import BasicCard from '@/components/Card/BasicCard.vue';
// import LineChart from '@/components/Chart/Line.vue';
import BarChart from '@/components/Chart/Bar.vue';
import DoughnutChart from '@/components/Chart/Doughnut.vue';
import { getAllContest } from '@/apis/contest';
import GreyLine from '@/components/GreyLine';
import { isAfterFromNow, isBetweenFromNow, toYYYYMMDD } from '@/utils/dateUtil';
import { getMonthlyMenuStatistics, getTodayMenuStatistics, getWeeklyMenuStatistics } from '@/apis/statistics';
import moment from 'moment';

export default {
  components: {
    GreyLine,
    BasicCard,
    DoughnutChart,
    BarChart,
  },
  setup() {
    const data = ref('Dashboard Data Example');
    const contestList = ref([]);
    const weeklyStatistic = ref({});
    const todayStatistic = ref({});
    const monthlyStatistic = ref({});

    const getDDay = (strDate) => {
      const now = new Date();
      const newDay = new Date(strDate);

      const gap = Math.floor(((now.getTime() - newDay.getTime()) / (1000 * 60 * 60 * 24)) * -1);

      return gap;
    };

    const getDateDescription = (endDate) => {
      if (isAfterFromNow(endDate)) {
        return '접수 종료';
      } else {
        return '접수중';
      }
    };

    const makeChartData = (menuStatistics, todayMenuStatistics, monthlyMenuStatistics) => {
      // 일일 방문수 집계
      const keysWeekly = Object.keys(menuStatistics).sort((a, b) => (moment(a).isAfter(moment(b)) ? 0 : -1));
      let dataWeekly = [];
      keysWeekly.forEach((key) => {
        const acc = menuStatistics[key].reduce((acc, current) => acc + current.count, 0);
        dataWeekly.push(acc);
      });
      weeklyStatistic.value = {
        labels: keysWeekly,
        datasets: {
          label: '클릭 수',
          data: dataWeekly,
        },
      };

      // 오늘 방문수 집계
      const keysToday = todayMenuStatistics.map((e) => e.userMenuResponse.name);
      const dataToday = todayMenuStatistics.map((e) => e.count);
      todayStatistic.value = {
        labels: keysToday,
        datasets: {
          label: '클릭 수',
          data: dataToday,
        },
      };

      // 월별 방문수 집계
      const keysMonthly = Object.keys(monthlyMenuStatistics).sort((a, b) => (moment(a).isAfter(moment(b)) ? 0 : -1));
      let dataMonthly = [];
      keysMonthly.forEach((key) => {
        const acc = monthlyMenuStatistics[key].reduce((acc, current) => acc + current.count, 0);
        dataMonthly.push(acc);
      });
      monthlyStatistic.value = {
        labels: keysMonthly,
        datasets: {
          label: '클릭 수',
          data: dataMonthly,
        },
      };
    };

    onMounted(async () => {
      contestList.value = await getAllContest();

      const menuStatistics = await getWeeklyMenuStatistics();
      const menuStatisticsToday = await getTodayMenuStatistics();
      const menuStatisticsMonthly = await getMonthlyMenuStatistics();
      console.log(menuStatisticsMonthly);
      makeChartData(menuStatistics, menuStatisticsToday, menuStatisticsMonthly);
    });

    return {
      data,
      getDDay,
      progressingVotes: [
        { title: '제 34회 서울시 지부장 선거', endDate: '2022-10-20' },
        { title: '제 31회 광양시 지부장 선거', endDate: '2022-09-31' },
      ],
      contestList,
      todayStatistic,
      weeklyStatistic,
      monthlyStatistic,
      toYYYYMMDD,
      getDateDescription,
      isAfterFromNow,
      isBetweenFromNow,
    };
  },
};
</script>
