<template>
  <li class="nav-item">
    <router-link v-if="to" class="nav-link" :class="{ item: isChild }" :to="to">
      <!--      <span class="sidenav-mini-icon"> {{ miniIcon }} </span>-->
      <span class="sidenav-normal"> {{ text }} </span>
    </router-link>
    <div v-else class="nav-link">
      <span class="sidenav-normal">- {{ text }}</span>
    </div>
  </li>
</template>
<script>
export default {
  name: 'SidenavItem',
  props: {
    to: {
      type: [Object, String],
      required: '',
    },
    // miniIcon: {
    //   type: String,
    //   required: true,
    // },
    text: {
      type: String,
      required: '',
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  font-weight: 400 !important;
}
</style>
