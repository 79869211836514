<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <post-card-header :title="post.title" :author="post.userName" :views="post.views" :created-date="post.createdDate" :modified-date="post.modifiedDate">
          <template #header-utils>
            <div class="header-utils-buttons">
              <!--              <button class="mb-0 btn btn-outline-danger btn-sm" @click="removePost(postId)" type="submit">삭제하기</button>-->
              <!--              <button class="mb-0 btn btn-outline-success btn-sm" @click="recoverPost(postId)" type="submit">복원하기</button>-->
            </div>
          </template>
        </post-card-header>
        <div class="card-body">
          <post-viewer ref="viewerRef" :content="post?.body"></post-viewer>
        </div>
        <div>
          <post-file-list :file-list="post.postFileViewList" />
        </div>
      </div>
    </div>
  </div>
  <reply-list :post-id="postId" :table-id="postId" />
</template>

<script>
import { deletePost, getPost, showPost } from '@/apis/post';
import { inject, ref, onMounted } from 'vue';
import PostCardHeader from '@/views/components/community/postCard/PostCardHeader.vue';
import PostFileList from '@/views/components/community/postCard/PostFileList.vue';
import moment from 'moment';
import ReplyList from '@/views/pages/community/ReplyList.vue';
import PostViewer from '@/views/components/community/postCard/PostViewer.vue';
import { useRoute } from 'vue-router';

const usePostDetail = (post, route) => {
  const swal = inject('$swal');
  const getPostDetail = async (postId) => {
    const result = await getPost(postId);
    post.value = {
      ...result,
      createdDate: moment(result.createdDate).format('YYYY년 MM월 DD일 HH:mm'),
      modifiedDate: result.modifiedDate ? moment(result.modifiedDate).format('YYYY년 MM월 DD일 HH:mm') : '',
    };
  };
  const removePost = async (postId) => {
    await swal.fire('삭제하시겠습니까?');
    await deletePost(postId);
    route.reload();
  };
  const recoverPost = async (postId) => {
    await swal.fire('복원하시겠습니까?');
    await showPost(postId);
    route.reload();
  };
  return { getPostDetail, removePost, recoverPost };
};

export default {
  name: 'PostDetail',
  props: {
    postId: {
      type: String,
      required: true,
    },
  },
  components: {
    PostCardHeader,
    PostFileList,
    ReplyList,
    PostViewer,
  },
  setup(props) {
    const post = ref({});
    const viewerRef = ref();
    const route = useRoute();

    const updateBody = (body) => {
      viewerRef.value.updateBody(body);
    };

    const { getPostDetail, removePost, recoverPost } = usePostDetail(post, route);

    onMounted(async () => {
      await getPostDetail(props.postId);
      updateBody(post.value.body);
    });

    return {
      post,
      removePost,
      viewerRef,
      updateBody,
      recoverPost,
    };
  },
};
</script>
