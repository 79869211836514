<template>
  <main-content-box>
    <template #main>
      <grid-view :columns="columns" :data="data" :column-options="columnOptions" :row-headers="rowHeaders" :updateRows="updateContestHandler">
        <template #addBtn>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ props }">
              <v-btn class="mr-4" color="primary" v-bind="props"> 추가하기 </v-btn>
            </template>
            <v-card class="dialog">
              <v-card-title>
                <span class="text-h5">대회 만들기</span>
              </v-card-title>
              <v-card-text>
                <basic-input
                  id="contestName"
                  label="대회명"
                  placeholder="대회명을 입력해주세요"
                  :text-value="newContestInfo.title"
                  required
                  @update:textValue="newContestInfo.title = $event"
                />
                <basic-input
                  id="location"
                  label="대회 장소"
                  placeholder="대회장소를 입력해주세요"
                  :text-value="newContestInfo.location"
                  required
                  @update:textValue="newContestInfo.location = $event"
                />
                <basic-date-picker
                  label="대회 신청 기간"
                  :date="newContestInfo.applicationDateRange"
                  @update:date="newContestInfo.applicationDateRange = $event"
                  placeholder="대회 신청 기간을 선택해주세요"
                  required
                  range
                />
                <basic-date-picker
                  label="대회 개최 기간"
                  :date="newContestInfo.contestDateRange"
                  @update:date="newContestInfo.contestDateRange = $event"
                  placeholder="대회 개최 기간을 선택해주세요"
                  required
                  range
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog"> 닫기 </v-btn>
                <v-progress-circular v-if="isCreateLoading" indeterminate color="primary"></v-progress-circular>
                <v-btn v-else color="blue-darken-1" text @click="createContestHandler"> 생성 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </grid-view>
    </template>
  </main-content-box>
</template>

<script>
import { ref, onMounted } from 'vue';

import { createContest, updateContestById, getAllContest } from '@/apis/contest';

import GridView from '@/views/components/common/GridView';
import { CONTEST_MANAGEMENT_HEADER } from '@/constants/contest/gridHeader';
import MainContentBox from '@/components/MainContentBox.vue';
import BasicInput from '@/components/BasicInput.vue';
import BasicDatePicker from '@/components/BasicDatePicker.vue';

import { successToast } from '@/utils/toastUtil';
import { YYYYMMDDHHMMSSToKorFormat } from '@/utils/dateUtil';

/**
 * 대회관리 페이지
 */
export default {
  name: 'ContestManagement',
  components: { GridView, MainContentBox, BasicDatePicker, BasicInput },
  setup() {
    const dialog = ref(false);
    const isCreateLoading = ref(false);
    const isDataLoading = ref(false);
    const newContestInfo = ref({
      title: '',
      contestDateRange: [],
      applicationDateRange: [],
      location: '',
    });

    const columns = CONTEST_MANAGEMENT_HEADER;
    const columnOptions = {
      resizable: true,
    };
    const data = ref([]);
    const rowHeaders = ['rowNum', 'checkbox'];

    const closeDialog = () => {
      isCreateLoading.value = false;
      dialog.value = false;
    };

    const fetchData = async () => {
      isDataLoading.value = true;
      const allContestData = await getAllContest();

      data.value = allContestData.map((d) => ({
        ...d,
        startDate: d.startDate,
        endDate: d.endDate,
        applicationStartDate: YYYYMMDDHHMMSSToKorFormat(d.applicationStartDate),
        applicationEndDate: YYYYMMDDHHMMSSToKorFormat(d.applicationEndDate),
      }));
      isDataLoading.value = false;
    };

    const createContestHandler = async () => {
      isCreateLoading.value = true;
      try {
        const body = {
          title: newContestInfo.value.title,
          location: newContestInfo.value.location,
          startDate: newContestInfo.value.contestDateRange[0],
          endDate: newContestInfo.value.contestDateRange[1],
          applicationStartDate: newContestInfo.value.applicationDateRange[0],
          applicationEndDate: newContestInfo.value.applicationDateRange[1],
        };

        await createContest(body);
        await fetchData();
      } catch (e) {
        closeDialog();
      }

      successToast('대회 생성에 성공했습니다.');
      closeDialog();
    };

    const updateContestHandler = async (dataArr) => {
      await Promise.all(dataArr.map((d) => updateContestById(d)));
      await fetchData();

      return true;
    };

    onMounted(() => {
      fetchData();
    });

    return { dialog, isCreateLoading, closeDialog, createContestHandler, updateContestHandler, newContestInfo, columns, data, columnOptions, rowHeaders };
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  width: 40rem;
}
</style>
