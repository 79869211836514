<template>
  <tr>
    <slot name="table-data" />
  </tr>
</template>

<style scoped lang="scss">
.check-box {
  margin-left: 20px;
}

.action_button {
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  border-radius: 20px;

  &:hover {
    background: aliceblue;
  }
}
</style>

<script>
export default {
  name: 'UserMenuTableRow',
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
