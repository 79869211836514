import { cloneDeep, isEmpty } from 'lodash-es';
import { CONTEST_REGISTER_TYPE_PASCAL, PLAYER_INPUT_KR, RECOMMENDER_INPUT_KR, FILE_CATEGORY, FILE_CATEGORY_ID_MAPPING } from '@/constants/contest';

const recommenderValidator = (recommender, isStudentForm) => {
  const validInfo = {
    infoType: 'recommender',
    name: '',
    message: '',
    isValid: false,
  };

  /** 추천인 정보 */
  const isErrorForm = Object.entries(recommender).some(([key, val]) => {
    const inputNameKr = RECOMMENDER_INPUT_KR[key];

    if (!inputNameKr || val) return false;
    else if (key === 'associationName') {
      if (isStudentForm && isEmpty(val)) return true;
      else return false;
    } else if (key === 'branchOfficeId') {
      if (!isStudentForm && val === 0) return true;
      else return false;
    }

    validInfo.name = key;
    validInfo.message = `추천인 정보 > 필수 입력값 "${inputNameKr}" 을/를 입력해주세요.`;
    return true;
  });

  validInfo.isValid = !isErrorForm;
  return validInfo;
};

const playerValidator = (playerInfo, isStudentForm, isIndependentBusinessForm) => {
  const validInfo = {
    infoType: 'player',
    name: '',
    message: '',
    isValid: false,
  };

  const isErrorForm = Object.entries(playerInfo).some(([key, val]) => {
    const inputNameKr = PLAYER_INPUT_KR[key];
    validInfo.name = key;

    if (!inputNameKr && key !== 'attachmentList') return false;

    if (key === 'birthday' && isEmpty(val)) {
      validInfo.message = '선수 정보 > "생년월일"을 입력해주세요.';
      return true;
    }
    if (key === 'contestItemAndPlayerSequences' && isEmpty(val)) {
      validInfo.message = `선수 정보 > 최소 하나의 "종목" 을 선택해주세요.`;
      return true;
    }

    /** 비회원 필수 값 입력 */
    validInfo.message = `선수 정보 > 필수 입력값 "${inputNameKr}" 을/를 입력해주세요.`;

    if (key === 'attachmentList') {
      const requiredCategories = [FILE_CATEGORY.ID_PHOTO];

      if (isStudentForm) {
        requiredCategories.push(FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER);
      }

      if (!isStudentForm && !isIndependentBusinessForm) {
        requiredCategories.push(FILE_CATEGORY.CERTIFICATE);
      }

      if (isIndependentBusinessForm) {
        requiredCategories.push(FILE_CATEGORY.BUSINESS_REGISTRATION);
        requiredCategories.push(FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION);
      }

      const requiredKey = requiredCategories.filter((category) => !playerInfo.playerFiles.some((v) => v.category === category))?.[0];
      if (requiredKey) {
        validInfo.name = FILE_CATEGORY_ID_MAPPING[requiredKey];
        validInfo.message = `선수정보 > 필수 첨부파일 "${PLAYER_INPUT_KR[requiredKey]}" 을/를 첨부해주세요.`;
        return true;
      }

      return false;
    } else if (key === 'associationName') {
      if (isStudentForm && isEmpty(val)) return true;
      else return false;
    } else if (key === 'branchOfficeId') {
      if (!isStudentForm && val === 0) return true;
      else return false;
    } else if (isEmpty(val)) {
      return true;
    }

    return false;
  });

  validInfo.isValid = !isErrorForm;
  return validInfo;
};

export const validateFormData = (playerInfo, recommender, contestRegisterType) => {
  const isStudentForm = contestRegisterType === CONTEST_REGISTER_TYPE_PASCAL.STUDENT;
  const isIndependentBusinessForm = contestRegisterType === CONTEST_REGISTER_TYPE_PASCAL.INDEPENDENT_BUSINESS;

  const checker = (info) => {
    const { infoType, isValid, name, message } = info;
    return { infoType, isValid, name, message };
  };

  /** 참가자 Validation */
  const playerValidInfo = playerValidator(playerInfo, isStudentForm, isIndependentBusinessForm);

  if (!playerValidInfo.isValid) {
    return checker(playerValidInfo);
  }

  /** 추천인 Validation */
  const recommenderInfo = recommenderValidator(recommender, isStudentForm);

  if (!recommenderInfo.isValid) {
    return checker(recommenderInfo);
  }

  return { isValid: true };
};

export const convertDataForApi = (playerInfo, recommender, isModify) => {
  const clonedPlayerInfo = cloneDeep(playerInfo);
  const clonedRecommender = cloneDeep(recommender);

  const isStudentForm = clonedPlayerInfo.contestRegisterType === CONTEST_REGISTER_TYPE_PASCAL.STUDENT;
  console.log(playerInfo);
  console.log(clonedPlayerInfo.contestItemAndPlayerSequences);

  if (isStudentForm) {
    delete clonedPlayerInfo.branchOfficeId;
  } else {
    clonedPlayerInfo.associationName = clonedPlayerInfo.branchName;
    clonedRecommender.associationName = clonedRecommender.branchName;
  }

  if (!isModify) {
    clonedPlayerInfo.createMirrorMappings = clonedPlayerInfo.contestItemAndPlayerSequences.createMirrorMappings;
    delete clonedPlayerInfo.contestItemAndPlayerSequences;
  } else {
    clonedPlayerInfo.createMirrorMappings = clonedPlayerInfo.contestItemAndPlayerSequences.modifyMirrorMappings.filter((v) => v?.id === undefined);
    clonedPlayerInfo.modifyMirrorMappings = clonedPlayerInfo.contestItemAndPlayerSequences.modifyMirrorMappings.filter((v) => v?.id !== undefined);
    clonedPlayerInfo.deleteMirrorMappings = clonedPlayerInfo.contestItemAndPlayerSequences.deleteMirrorMappings;
    delete clonedPlayerInfo.contestItemAndPlayerSequences;
  }

  /** 실제 업로드가 된 파일만 업로드 */
  clonedPlayerInfo.playerFiles = clonedPlayerInfo.playerFiles.filter((v) => !v.url);

  /** 불필요한 데이터 제거 */
  delete clonedPlayerInfo.regionName;
  delete clonedPlayerInfo.branchName;
  delete clonedPlayerInfo[FILE_CATEGORY.ID_PHOTO];
  delete clonedPlayerInfo[FILE_CATEGORY.CERTIFICATE];
  delete clonedPlayerInfo[FILE_CATEGORY.BUSINESS_REGISTRATION];
  delete clonedPlayerInfo[FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER];
  delete clonedPlayerInfo[FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION];

  delete clonedRecommender.regionName;
  delete clonedRecommender.branchName;
  return {
    convertedPlayerInfo: clonedPlayerInfo,
    convertedRecommender: clonedRecommender,
  };
};
