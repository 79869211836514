<template>
  <basic-card :title="title">
    <div class="chart">
      <canvas :id="id" class="chart-canvas" :height="height"></canvas></div
  ></basic-card>
</template>

<script>
import { onMounted, watch } from 'vue';
import Chart from 'chart.js/auto';

import BasicCard from '@/components/Card/BasicCard.vue';

export default {
  name: 'BarChart',
  props: {
    id: {
      type: String,
      default: 'bar-chart',
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '300px',
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        required: true,
        label: String,
        data: Array,
      },
    },
  },
  components: {
    BasicCard,
  },
  setup(props) {
    const createChart = () => {
      const ctx = document.getElementById(props.id).getContext('2d');

      const chartStatus = Chart.getChart(props.id);
      if (chartStatus !== undefined) {
        chartStatus.destroy();
      }

      if (!props?.chart?.datasets) {
        return;
      }

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: props.chart.labels,
          datasets: [
            {
              label: props.chart.datasets.label,
              weight: 5,
              borderWidth: 0,
              borderRadius: 4,
              backgroundColor: '#3A416F',
              data: props.chart.datasets.data,
              fill: false,
              maxBarThickness: 35,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#9ca2b7',
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                color: '#9ca2b7',
                padding: 10,
              },
            },
          },
        },
      });
    };

    watch(
      () => ({ ...props.chart }),
      (curr, prev) => {
        console.log(curr, prev);
        createChart();
      },
    );

    onMounted(() => {
      createChart();
    });
  },
};
</script>
