import { createStore } from 'vuex';
import bootstrap from 'bootstrap/dist/js/bootstrap.min';

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isRTL: false,
    color: '',
    sidebarType: 'bg-white',
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: 'default',
    bootstrap,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenavShow = document.querySelector('#app');

      if (sidenavShow.classList.contains('g-sidenav-show') && sidenavShow.classList.contains('g-sidenav-hidden')) {
        sidenavShow.classList.add('g-sidenav-pinned');
        sidenavShow.classList.remove('g-sidenav-hidden');
        state.isPinned = true;
      } else if (sidenavShow.classList.contains('g-sidenav-show') && sidenavShow.classList.contains('g-sidenav-pinned')) {
        sidenavShow.classList.add('g-sidenav-hidden');
        sidenavShow.classList.remove('g-sidenav-pinned');
        state.isPinned = false;
      } else if (sidenavShow.classList.contains('g-sidenav-show')) {
        sidenavShow.classList.add('g-sidenav-pinned');
        state.isPinned = true;
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit('setSidebarType', payload);
    },
  },
  getters: {},
});
