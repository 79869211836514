<template>
  <li class="nav-item" v-if="menu.displayStatus === 'SHOW'">
    <sidenav-item v-if="menu.type === 'MENU'" :text="menu.name" :to="menu.path" />
    <sidenav-collapse
      style="margin-left: -20px !important"
      class="ms-0"
      v-else
      :nav-text="menu.name"
      :collapse="menu.type === 'FOLDER'"
      :aria-controls="''"
      :collapse-ref="false"
      :expand="currentParentPath === menu.childMenus[0]?.path.split('/')[1]"
    >
      <!--      :collapse-ref="menu.path ?? ''"-->
      <template #list>
        <ul class="nav">
          <template v-for="childMenu of menu.childMenus" :key="childMenu.id">
            <sidenav-item :text="childMenu.name" :to="childMenu.path" isChild />
          </template>
        </ul>
        <div class="divider-wrapper">
          <v-divider color="#d3d6db" length="90%"></v-divider>
        </div>
      </template>
    </sidenav-collapse>
  </li>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import SidenavCollapse from '@/examples/Sidenav/SidenavCollapse';
import SidenavItem from '@/examples/Sidenav/SidenavItem';

export default {
  name: 'side-nav-menu',
  components: {
    SidenavCollapse,
    SidenavItem,
  },
  props: {
    menu: Object,
  },
  setup() {
    const route = useRoute();
    const currentParentPath = ref(route.path.split('/')[1]);

    return { currentParentPath };
  },
};
</script>

<style scoped>
.divider-wrapper {
  display: flex;
  justify-content: center;
}
</style>
