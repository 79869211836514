export const HEAD_CELLS = [
  { id: 'content', label: '내용' },
  { id: 'userName', label: '작성자' },
  { id: 'createdDate', label: '작성일' },
  { id: 'modifiedDate', label: '최근 수정일' },
  { id: 'displayStatus', label: '상태' },
];
export const TABLE_CHECKBOX = 'table-checkbox';
export const ACTIONS_SHOW_REPLY = 'actions-showReply';
export const ACTIONS_DELETE_REPLY = 'actions-deleteReply';
