export const BRANCH_OFFICE_COMMUNITY_AUTHORITY_HEADER = [
  {
    header: '조회 권한',
    name: 'readAuthority',
    formatter: 'listItemText',
    editor: {
      type: 'select',
      options: {
        listItems: [
          { text: 'O', value: true },
          { text: 'X', value: false },
        ],
      },
    },
  },
  {
    header: '쓰기 권한',
    name: 'writeAuthority',
    formatter: 'listItemText',
    editor: {
      type: 'select',
      options: {
        listItems: [
          { text: 'O', value: true },
          { text: 'X', value: false },
        ],
      },
    },
  },
  {
    header: '고정글 작성 권한',
    name: 'writeFixedPostAuthority',
    formatter: 'listItemText',
    editor: {
      type: 'select',
      options: {
        listItems: [
          { text: 'O', value: true },
          { text: 'X', value: false },
        ],
      },
    },
  },
];

export const USER_MANAGEMENT_HEADER = [
  {
    header: '아이디',
    name: 'loginId',
  },
  {
    header: '이름',
    name: 'name',
    editor: 'text',
  },
  {
    header: '핸드폰번호',
    name: 'phone',
    editor: 'text',
  },
  {
    header: '주소',
    name: 'address',
    editor: 'text',
  },
  {
    header: '상세 주소',
    name: 'addressDetail',
    editor: 'text',
  },
  {
    header: '우편번호',
    name: 'zip',
    editor: 'text',
  },
  {
    header: '지회/지부명',
    name: 'branchOfficeName',
  },
  {
    header: '대기중/승인됨',
    name: 'userStatusText',
  },
  {
    header: '활성/비활성',
    name: 'displayStatusText',
  },
  {
    header: '사용자 권한',
    name: 'userAuthorityGroupTypeText',
    formatter: 'listItemText',
    editor: {
      type: 'select',
      options: {
        listItems: [
          { text: '관리자', value: '관리자' },
          { text: '단체장', value: '단체장' },
          { text: '정회원', value: '정회원' },
          { text: '준회원', value: '준회원' },
          { text: '비회원', value: '비회원' },
        ],
      },
    },
  },
  {
    header: '생성일자',
    name: 'createdDate',
  },
  {
    header: '수정일자',
    name: 'modifiedDate',
  },
];

export const USER_AUTHORITY = {
  MANAGER_MEMBER: '관리자',
  BRANCH_MANAGER_MEMBER: '단체장',
  AUTHORIZED_MEMBER: '정회원',
  NON_AUTHORIZED_MEMBER: '준회원',
  NOT_MEMBER: '비회원',
  관리자: 'MANAGER_MEMBER',
  단체장: 'BRANCH_MANAGER_MEMBER',
  정회원: 'AUTHORIZED_MEMBER',
  준회원: 'NON_AUTHORIZED_MEMBER',
  비회원: 'NOT_MEMBER',
};
