<template>
  <v-btn class="mr-4" :style="noMargin && 'margin: 0 !important;'" text v-bind="attrs" value="excel" color="success" @click="exportToExcel">
    엑셀 다운로드
  </v-btn>
</template>

<script>
import { computed } from 'vue';
import moment from 'moment';
import { useContestStore } from '@/store/contest';
import { EVENT } from '@/constants/contest/gridHeader';

export default {
  name: 'ExcelExportBtn',
  props: {
    noMargin: {
      type: Boolean,
      default: false,
    },
    columnNames: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      required: true,
    },
    gridRef: {
      type: Object,
      required: true,
    },
    selectedEventId: {
      type: String,
      required: true,
    },
  },
  setup(props, { attrs }) {
    const contestStore = useContestStore();
    const contestTitles = computed(() => contestStore[EVENT].find((item) => item.id === props.selectedEventId) || {});

    const exportToExcel = () => {
      const { contestTitle, classificationTitle, sectorTitle, eventTitle } = contestTitles.value;

      const fileName = `${props.fileName}-${moment().format('YYYY-MM-DD HH:mm:ss')}`;
      const fullFileName = `${contestTitle}-${classificationTitle}-${sectorTitle}-${eventTitle}-${fileName}`;

      const hasAllTitle = contestTitle && classificationTitle && sectorTitle && eventTitle;

      props.gridRef.exportExcel({
        ...(props.columnNames && { columnNames: props.columnNames }),
        useFormattedValue: true,
        fileName: hasAllTitle ? fullFileName : fileName,
      });
    };
    return { attrs, exportToExcel };
  },
};
</script>
