<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success" :dark-mode="true" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">대한미용사협회 Admin</h4>
                  <p class="mb-0">발급받은 관리자 계정을 입력해주세요</p>
                </div>
                <div class="card-body">
                  <form role="form" v-on:submit="handleLogin">
                    <div class="mb-3">
                      <argon-input id="loginId" placeholder="관리자 아이디" name="username" size="lg" type="username" />
                    </div>
                    <div class="mb-3">
                      <argon-input id="password" type="password" placeholder="비밀번호" name="password" size="lg" />
                    </div>
                    <!--                    <argon-switch id="rememberMe" name="rememberMe">-->
                    <!--                      로그인 유지하기-->
                    <!--                    </argon-switch>-->
                    <div class="text-center">
                      <argon-button v-on:click="login" class="mt-4" variant="gradient" color="success" full-width size="lg"> 로그인 </argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    계정을 발급받지 않으셨다면, 관리자에게 연락 바랍니다.
                    <!--                    <router-link-->
                    <!--                      :to="{ name: 'Signup Illustration' }"-->
                    <!--                      class="text-success text-gradient font-weight-bold"-->
                    <!--                      >Sign up</router-link-->
                    <!--                    >-->
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div
                class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{
                  backgroundImage:
                    'url(' + 'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg' + ')',
                  backgroundSize: 'cover',
                }"
              >
                <span class="mask bg-gradient-success opacity-6"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from '@/examples/PageLayout/Navbar.vue';
import ArgonInput from '@/components/ArgonInput.vue';
// import ArgonSwitch from '@/components/ArgonSwitch.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import { mapMutations } from 'vuex';

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';

const body = document.getElementsByTagName('body')[0];
export default {
  name: 'SigninIllustration',
  components: {
    Navbar,
    ArgonInput,
    // ArgonSwitch,
    ArgonButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove('bg-gray-100');
  },
  mounted() {
    // document.getElementById('loginId').value = 'hyhp';
    // document.getElementById('password').value = 'qwe123!@#';
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add('bg-gray-100');
  },
  setup() {
    const loginId = ref('');
    const password = ref('');
    const router = useRouter();
    const authStore = useAuthStore();

    const handleLogin = async (e) => {
      e.preventDefault();
      // todo composition api 사용해서 login, pw binding
      const loginId = document.getElementById('loginId').value;
      const password = document.getElementById('password').value;
      await authStore.requestSignin(loginId, password);

      router.push({ path: '/dashboard' });
    };
    return {
      loginId,
      password,
      handleLogin,
    };
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout']),
  },
};
</script>
