<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card p-4">
        <slot name="main"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
