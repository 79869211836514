import axiosInstance from '@/lib/axiosController';

export const getSchedules = (year, month) => axiosInstance.get(`/schedule/list?year=${year}&month=${month}`);

export const createSchedule = (body) => axiosInstance.post('/schedule/upload', body);

export const updateSchedule = (id, body) => axiosInstance.put(`/schedule/${id}`, body);

export const updateSchedulesBulk = (body) =>
  axiosInstance.put('/schedule/bulk', {
    modifyRequests: body,
  });

export const deleteSchedule = (id) => axiosInstance.delete(`/schedule/delete/${id}`);

export const deleteSchedules = (ids) =>
  axiosInstance.delete(`/schedule/delete-schedules`, {
    data: {
      ids,
    },
  });
