import axios from '@/lib/axiosController';

export const getContestPlayerList = async (params) =>
  axios.get('/player/list', {
    params: { ...params },
  });

/**
 * 대회 선수 등록
 */
export const uploadPlayer = (body) =>
  axios.post('/player/upload', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

/**
 * 대회 선수 정보 수정
 */
export const modifyPlayer = (playerId, body) =>
  axios.put(`/player/${playerId}`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

/**
 * 대회 선수 정보 수정
 */
export const deletePlayer = (body) => axios.delete(`/player/delete-players`, { data: body });

/**
 * 대회 선수 상세 정보 조회 API
 *
 * @param {number} playerId
 * @returns
 */
export const getPlayerDetail = (playerId) => axios.get(`/player/${playerId}`);
