<template>
  <div class="pb-0 card-header mb-3 mb-lg-5">
    <div class="justify-content-between">
      <div class="border-top pt-3">
        <h6>첨부 파일</h6>
        <div v-for="file of fileList" :key="file.id" class="row py-2 ps-1 mb-2 border rounded-3">
          <div class="col-auto fs-6 fw-normal">
            {{ file.fileName }}
          </div>
          <div class="col-auto">
            <a :href="file.url" :download="file.fileName">
              <i class="fa fa-download" />
            </a>
          </div>
        </div>
        <div class="text-center" v-if="!fileList || fileList.length === 0">등록된 첨부파일이 없습니다.</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

<script>
export default {
  name: 'PostFileList',
  props: {
    fileList: {
      type: Array,
    },
  },
};
</script>
