export const OPTIONS = [
  {
    label: '제목',
    value: 'title',
  },
  {
    label: '작성자',
    value: 'userName',
  },
];

export const HEAD_CELLS = [
  { id: 'title', label: '제목' },
  { id: 'userName', label: '작성자' },
  { id: 'createdDate', label: '작성일' },
  { id: 'modifiedDate', label: '최근 수정일' },
  { id: 'displayStatus', label: '상태' },
];
