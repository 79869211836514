<template>
  <thead class="thead-light">
    <tr>
      <!--      <th v-if="showCheckbox">선택</th>-->
      <th v-for="{ id, label } of headCells" :key="id">
        <div class="my-auto text-start">{{ label }}</div>
      </th>
      <!--      <th v-if="showActions">-</th>-->
    </tr>
  </thead>
</template>

<script>
export default {
  // [{id: String, label: String}]
  name: 'AuthorityTableHeader',
  props: {
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    headCells: {
      type: Array,
      default: () => [],
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
