const preventClose = (e) => {
  e.preventDefault();
  e.returnValue = '';
};

const preventGoBack = () => {
  if (!window.confirm('뒤로가시겠습니까? 변경사항이 저장되지 않을 수 있습니다.')) {
    history.pushState(null, '', location.href);
  }
};

export const registPreventCloseEvent = () => {
  window.addEventListener('beforeunload', preventClose);
  window.addEventListener('popstate', preventGoBack);
};

export const destroyPreventCloseEvent = () => {
  window.removeEventListener('beforeunload', preventClose);
  window.removeEventListener('popstate', preventGoBack);
};
