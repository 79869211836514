<template>
  <main-content-box>
    <template #main>
      <div class="mirror__header">
        <contest-select-group :selectHandler="itemSelectHandler"></contest-select-group>
      </div>
      <v-row class="mb-6 ml-1">
        <v-col>
          <v-btn-toggle v-model="pivotForm" tile color="deep-purple accent-3" group @click="changePivotForm">
            <v-btn value="contestItem">종목별통계</v-btn>
            <v-btn value="branchOffice">지회별접수현황</v-btn>
            <v-btn value="education">학교별통계</v-btn>
            <v-btn value="recommenderEdu">추천인별통계(학교)</v-btn>
            <v-btn value="recommenderEduRegion">추천인별통계(학교지역)</v-btn>
            <v-btn value="recommenderCommon">추천인별통계(일반)</v-btn>
            <v-btn value="player">신청인원통계</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row class="mb-1 ml-4 p-0">
        <v-col cols="2">
          <v-switch v-model="subTotalToggle" color="success" label="부분합 포함 여부" @change="dataHandler" />
        </v-col>
        <v-col cols="2" />
        <v-col cols="8">
          <v-select
            v-if="pivotForm === 'branchOffice' || pivotForm === 'recommenderEdu' || pivotForm === 'recommenderCommon'"
            v-model="selectedItems"
            :items="items"
            label="필터 조건을 선택하세요."
            multiple
            chips
            persistent-hint
            @update:modelValue="changeFilterItems"
          ></v-select>
        </v-col>
      </v-row>
      <pivot v-if="data.length > 0" ref="pivotRef" :data="data" :rows="rows" :cols="cols" :is-sub-total="subTotalToggle" :data-handler="dataHandler"></pivot>
      <v-row>
        <v-spacer />
        <v-btn v-if="data.length > 0" large class="mt-6 mb-3 mr-3" value="excel" color="green-lighten-1" @click="exportToExcel"> Excel </v-btn>
      </v-row>
    </template>
  </main-content-box>
</template>

<script>
/**
 * 통계 페이지
 */
import MainContentBox from '@/components/MainContentBox';
import Pivot from '@/views/components/contest/Pivot';
import ContestSelectGroup from '@/views/components/contest/ContestSelectGroup';
import { ref, watch } from 'vue';
import { CONTEST, CLASSIFICATION, SECTOR, EVENT } from '@/constants/contest/gridHeader';
import { getContestStatisticsRawDataById } from '@/apis/contest';
import { successToast } from '@/utils/toastUtil';

export default {
  name: 'Statistics',
  components: { Pivot, MainContentBox, ContestSelectGroup },
  setup() {
    const data = ref([]);
    const rawData = ref([]);
    const rows = ref([]);
    const cols = ref([]);
    const pivotRef = ref();
    const pivotForm = ref('contestItem');
    const subTotalToggle = ref(true);
    const contestItemSelectedId = ref({
      [CONTEST]: '',
      [CLASSIFICATION]: '',
      [SECTOR]: '',
      [EVENT]: '',
    });
    const items = ref([]);
    const selectedItems = ref([]);

    watch(
      () => data.value,
      () => {
        // DOM 에 그려지는 시간 때문에 setTimeOut 추가.=
        setTimeout(() => {
          const tmp = document.getElementsByClassName('pvtRowLabelFiller');
          if (tmp && tmp?.length) {
            for (let i = 0; i < tmp.length; i++) {
              const elem = tmp[i];
              const colspan = Number(elem.getAttribute('colspan'));
              if (colspan === 2) {
                elem.innerHTML = '<p style="font-size: 12px; font-weight: bold">부문 합계</p>';
              } else if (colspan === 1) {
                elem.innerHTML = '<p style="font-size: 12px; font-weight: bold">종목 합계</p>';
              } else {
                elem.innerHTML = '<p style="font-size: 12px; font-weight: bold">합계</p>';
              }
            }
          }
        }, 100);
      },
    );

    const pivotTemplete = {
      contestItem: {
        rows: ['분류명', '부문명', '종목명'],
        cols: [],
      },
      branchOffice: {
        rows: ['선수 지회-지부 명'],
        cols: ['분류명', '부문명', '종목명'],
      },
      education: {
        rows: ['선수 소속명'],
        cols: ['분류명', '부문명', '종목명'],
      },
      recommenderEdu: {
        rows: ['추천인 명', '추천인 소속명'],
        cols: ['분류명', '부문명', '종목명'],
      },
      recommenderEduRegion: {
        rows: ['추천인 명', '추천인 지회-지부 명'],
        cols: ['분류명', '부문명', '종목명'],
      },
      recommenderCommon: {
        rows: ['추천인 명', '추천인 지회-지부 명'],
        cols: ['분류명', '부문명', '종목명'],
      },
      player: {
        rows: ['분류명', '부문명'],
        cols: [],
      },
    };

    const changePivotForm = () => {
      rows.value = pivotTemplete[pivotForm.value].rows;
      cols.value = pivotTemplete[pivotForm.value].cols;
      dataHandler(data.value, rows.value, cols.value);
    };

    const itemSelectHandler = async (selectedId, type) => {
      if (type === CONTEST) {
        await getData(selectedId);
        dataHandler();
      } else {
        data.value = [];
      }
    };

    const getData = async (contestId) => {
      const raws = await getContestStatisticsRawDataById(contestId);
      successToast('데이터 조회가 완료되었습니다.');
      rawData.value = raws.map((v) => ({
        선수명: v.player.playerId,
        업소명: v.player.businessName,
        '대회 등록 타입': v.player.contestRegisterType,
        '선수 소속 타입': v.player.associationType,
        '선수 소속명': v.player.associationName,
        '선수 지회-지부 일련번호': v.player.branchOffice.branchOfficeId,
        '선수 지회-지부 명': v.player.branchOffice.branchName ? v.player.branchOffice.branchName : '기타',
        '선수 지회-지부 지역': v.player.branchOffice.region,
        '선수 지회-지부 타입': v.player.branchOffice.type,
        '추천인 명': v.recommender.recommenderName,
        '추천인 소속 타입': v.recommender.associationType,
        '추천인 소속명': v.recommender.associationName,
        '추천인 지회-지부 일련번호': v.recommender.branchOffice.branchOfficeId,
        '추천인 지회-지부 명': v.recommender.branchOffice.branchName ? v.recommender.branchOffice.branchName : '기타',
        '추천인 지회-지부 지역': v.recommender.branchOffice.region,
        '추천인 지회-지부 타입': v.recommender.branchOffice.type,
        '종목 일련번호': v.contestItem.id,
        종목명: v.contestItem.name,
        '종목 코드': v.contestItem.code,
        '부문 일련번호': v.contestItem.parent.id,
        부문명: v.contestItem.parent.name,
        '부문 코드': v.contestItem.parent.code,
        '분류 일련번호': v.contestItem.parent.parent.id,
        분류명: v.contestItem.parent.parent.name,
        '분류 코드': v.contestItem.parent.parent.code,
      }));
      rows.value = pivotTemplete[pivotForm.value].rows;
      cols.value = pivotTemplete[pivotForm.value].cols;
    };

    const dataHandler = () => {
      data.value = filterByPivotForm();
      pivotRef.value.updateData(data.value, rows.value, cols.value, subTotalToggle.value);
    };

    const changeFilterItems = () => {
      const filterData = filterByItems();
      pivotRef.value.updateData(filterData, rows.value, cols.value, subTotalToggle.value);
    };

    const filterByItems = () => {
      if (selectedItems.value.length === 0) {
        return [...data.value];
      }
      const keys = new Set(selectedItems.value);
      if (pivotForm.value === 'branchOffice') {
        return data.value.filter((v) => keys.has(v['선수 지회-지부 명']));
      } else if (pivotForm.value === 'recommenderEdu') {
        return data.value.filter((v) => keys.has(v['추천인 명']));
      } else if (pivotForm.value === 'recommenderCommon') {
        return data.value.filter((v) => keys.has(v['추천인 명']));
      }
    };

    const filterByPivotForm = () => {
      if (pivotForm.value === 'branchOffice') {
        const values = rawData.value.map((v) => v['선수 지회-지부 명']);
        items.value = Array.from(new Set(values));
        selectedItems.value = [];
        return [...rawData.value];
      } else if (pivotForm.value === 'education') {
        return rawData.value.filter((v) => v['대회 등록 타입'] === 'STUDENT');
      } else if (pivotForm.value === 'recommenderEdu') {
        const values = rawData.value.filter((v) => v['대회 등록 타입'] === 'STUDENT').map((v) => v['추천인 명']);
        items.value = Array.from(new Set(values));
        selectedItems.value = [];
        return rawData.value.filter((v) => v['대회 등록 타입'] === 'STUDENT');
      } else if (pivotForm.value === 'recommenderEduRegion') {
        return rawData.value.filter((v) => v['대회 등록 타입'] === 'STUDENT');
      } else if (pivotForm.value === 'recommenderCommon') {
        const values = rawData.value.filter((v) => v['대회 등록 타입'] !== 'STUDENT').map((v) => v['추천인 명']);
        items.value = Array.from(new Set(values));
        selectedItems.value = [];
        return rawData.value.filter((v) => v['대회 등록 타입'] !== 'STUDENT');
      }
      return [...rawData.value];
    };

    const exportToExcel = () => {
      const table = document.querySelector('table.pvtTable');
      let myBlob = new Blob([table.outerHTML], { type: 'application/vnd.ms-excel' });
      let url = window.URL.createObjectURL(myBlob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'export.xls';
      a.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 0);
    };

    return {
      data,
      itemSelectHandler,
      contestItemSelectedId,
      rows,
      cols,
      dataHandler,
      pivotRef,
      pivotForm,
      changePivotForm,
      exportToExcel,
      subTotalToggle,
      items,
      selectedItems,
      changeFilterItems,
      filterByItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.mirror {
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
  }

  &__header {
    display: flex;
    justify-content: end;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
