<template>
  <div class="pb-0 card-header mb-3 mb-lg-5">
    <div class="d-lg-flex justify-content-between w-100">
      <div>
        <h5 v-if="!!title" class="mb-0">{{ title }}</h5>
        <p v-if="!!description" class="mb-0 text-sm">{{ description }}</p>
      </div>
      <div style="display: flex">
        <slot name="header-utils" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsCardHeader',
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
};
</script>
