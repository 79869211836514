<template>
  <div class="name gray-600" :class="{ 'disabled-title': disabled }">{{ label }}<span v-if="required" class="required">*</span></div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  letter-spacing: 0.005em;
}

.required {
  color: red;
}

.disabled-title {
  color: grey !important;
}
</style>
