<template>
  <main-content-box>
    <template #main>
      <div class="winner__header">
        <contest-select-group :defaultSelectedInfo="contestItemSelectedId" :selectHandler="itemSelectHandler" :mode="EVENT"></contest-select-group>
      </div>
      <div>
        <div class="text-right text-red-lighten-2 mr-4" :hidden="!contestItemIdRef">*집계 버튼을 눌렀는지 확인해 주세요!</div>
        <grid-view
          class="pt-0 mt-1"
          ref="gridRef"
          :columns="WINNER_MANAGEMENT_HEADER"
          :data="data"
          :header="HEADER_OPTION"
          :columnOptions="COLUMN_OPTIONS"
          :is-edit-hide="true"
        >
          <template #addBtn>
            <excel-export-btn :selected-event-id="contestItemSelectedId[EVENT]" :grid-ref="gridRef" file-name="수상자 관리" :disabled="data.length === 0" />
            <v-dialog v-model="dialog" persistent width="500">
              <template v-slot:activator="{ props }">
                <v-btn color="primary" v-bind="props" :disabled="data.length <= 0">상장 PDF로 내보내기</v-btn>
                <v-btn class="ml-2" color="deep-orange" :disabled="!contestItemIdRef" @click="aggregateWinner">집계 및 재집계</v-btn>
              </template>
              <v-card>
                <v-card-title>수상자 관리</v-card-title>
                <v-card-text>{{ data.length }}개의 상장을 PDF로 내보내시겠습니까?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialog = false"> 취소 </v-btn>
                  <v-btn color="green darken-1" text @click="exportPrizeToPDF"> 내보내기 </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </grid-view>
      </div>
    </template>
  </main-content-box>
</template>

<script>
import { ref } from 'vue';

import { useContestStore } from '@/store/contest';
import { aggregateWinnerByContestItemId, getWinnerListByContestItemId } from '@/apis/prize';

import MainContentBox from '@/components/MainContentBox.vue';

import GridView from '@/views/components/common/GridView.vue';
import ContestSelectGroup from '@/views/components/contest/ContestSelectGroup.vue';

import { WINNER_MANAGEMENT_HEADER, SCORE_JUDGE_LIST, JUDGE_ID_PREFIX, CONTEST, CLASSIFICATION, SECTOR, EVENT } from '@/constants/contest/gridHeader';
import { prizesSaveToPdf } from '@/utils/pdf';
import { successToast } from '@/utils/toastUtil';
import ExcelExportBtn from '@/components/ExcelExportBtn';

const HEADER_OPTION = {
  height: 120,
  complexColumns: [
    {
      header: '점수',
      name: 'mergeColumn',
      childNames: ['takeOffPoint', 'mergeColumn2'],
    },
    {
      header: '심사위원점수',
      name: 'mergeColumn2',
      childNames: [...SCORE_JUDGE_LIST.map((v) => v.name)],
    },
  ],
};

const COLUMN_OPTIONS = {
  frozenCount: 5,
  frozenBorderWidth: 2,
  resizable: true,
};

const makeScoreJudgeData = (contestScoreItems) =>
  contestScoreItems.reduce((obj, prev) => {
    obj[`${JUDGE_ID_PREFIX}${prev.judgeId}`] = prev.score;

    return obj;
  }, {});

export default {
  name: 'WinnerManagement',
  components: { MainContentBox, ContestSelectGroup, GridView, ExcelExportBtn },
  setup() {
    const contestStore = useContestStore();
    const data = ref([]);
    const dialog = ref(false);
    const contestItemIdRef = ref();
    const contestItemSelectedId = ref({
      [CONTEST]: '',
      [CLASSIFICATION]: '',
      [SECTOR]: '',
      [EVENT]: '',
    });
    const gridRef = ref({});

    const itemSelectHandler = async (contestItemId, type) => {
      if (type === EVENT) {
        await getWinnerList(contestItemId);
        contestItemIdRef.value = contestItemId;
      } else {
        data.value = [];
      }
    };

    const aggregateWinner = async () => {
      await aggregateWinnerByContestItemId(contestItemIdRef.value);
      successToast('데이터 집계가 완료되었습니다.');
      await getWinnerList(contestItemIdRef.value);
    };

    const getWinnerList = async (contestItemId) => {
      const scores = await getWinnerListByContestItemId(contestItemId);
      successToast('데이터 조회가 완료되었습니다.');

      data.value = scores.map((v) => ({
        id: v.id,
        title: v.title,
        rank: v.rank,
        mirrorSequence: v.mirrorSequence,
        playerName: v.playerName,
        totalScore: v.totalScore,
        takeOffPoint: v.takeOffPoint,
        ...makeScoreJudgeData(v.contestScoreItems),
      }));
    };

    const exportPrizeToPDF = () => {
      const pdfData = data.value.map((v) => ({
        title: v.title,
        sectionName: contestStore[EVENT].find((v) => v.id === contestItemIdRef.value).title,
        playerName: v.playerName,
      }));

      dialog.value = false;
      prizesSaveToPdf(pdfData);
    };

    return {
      WINNER_MANAGEMENT_HEADER,
      EVENT,
      contestItemIdRef,
      dialog,
      data,
      contestItemSelectedId,
      gridRef,
      itemSelectHandler,
      exportPrizeToPDF,
      aggregateWinner,
      HEADER_OPTION,
      COLUMN_OPTIONS,
    };
  },
};
</script>

<style lang="scss" scoped>
.winner {
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
  }

  &__header {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
