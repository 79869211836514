<template>
  <div class="table-responsive mt-3">
    <table :id="tableId" class="table table-flush">
      <slot name="table-header" />
      <slot name="table-body" />
      <slot name="table-footer" />
    </table>
  </div>
</template>

<script>
export default {
  name: 'BoardTableContainer',
  props: {
    tableId: {
      type: String,
      required: true,
    },
  },
};
</script>
