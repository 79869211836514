<template>
  <div class="contestRegist">
    <h3>선수 정보 ({{ PLAYER_INFO_TITLE[playerInfo.contestRegisterType] }})</h3>
    <v-divider class="mb-4" inset></v-divider>

    <basic-button-group
      v-if="!isUpdateForm"
      label="지원 유형"
      :items="contestRegistGroup"
      :active-value="playerInfo.contestRegisterType"
      @update:activeValue="onUpdateRegistType($event)"
      required
    ></basic-button-group>

    <basic-input
      v-if="isStudentForm"
      id="associationName"
      required
      name="associationName"
      :text-value="playerInfo.associationName"
      label="소속(학교)"
      placeholder="소속(학교)를 입력하세요"
      :errorMessages="errors[PLAYER_PREFIX].associationName"
      @update:textValue="playerInfo.associationName = $event"
      @blur="resetPlayerInputError"
    />
    <form-row v-else label="소속 지회, 지부 선택" required>
      <template #content>
        <div class="mt-4 mb-4">
          <branch-select-group
            :branchOfficeId="playerInfo.branchOfficeId"
            :regionName="playerInfo.regionName"
            :branchName="playerInfo.branchName"
            @update:regionName="playerInfo.regionName = $event"
            @update:branchOfficeId="(value) => onUpdateBranch(value, PLAYER_PREFIX)"
          />
          <small v-if="errors[PLAYER_PREFIX].branchOfficeId" class="red-color p-2">{{ errors[PLAYER_PREFIX].branchOfficeId }}</small>
        </div>
      </template>
    </form-row>

    <basic-input
      id="name"
      label="이름"
      placeholder="선수 이름을 입력하세요"
      :rules="validator.name"
      :text-value="playerInfo.name"
      :errorMessages="errors[PLAYER_PREFIX].name"
      @update:textValue="playerInfo.name = $event"
      @blur="resetPlayerInputError"
      required
    />

    <form-row label="생년월일" required>
      <template #content>
        <basic-date-picker
          id="birthday"
          placeholder="생년월일을 선택해주세요"
          :date="playerInfo.birthday"
          :errorText="errors[PLAYER_PREFIX].birthday"
          @update:date="onUpdateBirthday($event)"
          modelType="yyyy-MM-dd"
          autoApply
          required
          onlyDate
        />
      </template>
    </form-row>

    <basic-input
      id="phone"
      label="연락처"
      placeholder="예) 01012341234"
      :rules="validator.phone"
      :text-value="playerInfo.phone"
      :errorMessages="errors[PLAYER_PREFIX].phone"
      @update:textValue="playerInfo.phone = $event"
      @blur="resetPlayerInputError"
      required
    />

    <!--    <basic-input-->
    <!--      v-if="!isUpdateForm"-->
    <!--      id="password"-->
    <!--      required-->
    <!--      type="password"-->
    <!--      name="password"-->
    <!--      label="비밀번호"-->
    <!--      placeholder="대회 등록 전용 비밀번호"-->
    <!--      helper-text="최소 4자, 최대 12자"-->
    <!--      :minlength="4"-->
    <!--      :maxlength="12"-->
    <!--      :text-value="playerInfo.password"-->
    <!--      :errorMessages="errors[PLAYER_PREFIX].password"-->
    <!--      @update:textValue="playerInfo.password = $event"-->
    <!--      @blur="resetPlayerInputError"-->
    <!--    />-->

    <form-row label="증명사진" required>
      <template #content>
        <div class="d-flex">
          <v-file-input
            :id="FILE_CATEGORY.ID_PHOTO"
            accept="image/*"
            v-model="playerInfo[FILE_CATEGORY.ID_PHOTO]"
            label="증명사진 업로드"
            variant="underlined"
            :class="{ 'red-color': errors[PLAYER_PREFIX].idPhoto }"
            :errorMessages="errors[PLAYER_PREFIX].idPhoto"
            @update:modelValue="(e) => fileInputHandler(FILE_CATEGORY.ID_PHOTO, e)"
            @blur="resetPlayerInputError"
          ></v-file-input>
          <v-btn v-if="!isEmpty(fileUrl[FILE_CATEGORY.ID_PHOTO])" @click="() => openPreviewDialog(fileUrl[FILE_CATEGORY.ID_PHOTO])" flat>미리보기</v-btn>
        </div>
      </template>
    </form-row>

    <form-row v-if="isIndependentBusinessForm" label="사업자 등록증" required>
      <template #content>
        <div class="d-flex">
          <v-file-input
            :id="FILE_CATEGORY.BUSINESS_REGISTRATION"
            accept="image/*"
            v-model="playerInfo[FILE_CATEGORY.BUSINESS_REGISTRATION]"
            label="사업자 등록증 업로드"
            variant="underlined"
            :class="{ 'red-color': errors[PLAYER_PREFIX].businessRegistration }"
            :errorMessages="errors[PLAYER_PREFIX].businessRegistration"
            @update:modelValue="(e) => fileInputHandler(FILE_CATEGORY.BUSINESS_REGISTRATION, e)"
            @blur="resetPlayerInputError"
          ></v-file-input>
          <v-btn
            v-if="!isEmpty(fileUrl[FILE_CATEGORY.BUSINESS_REGISTRATION])"
            @click="() => openPreviewDialog(fileUrl[FILE_CATEGORY.BUSINESS_REGISTRATION])"
            flat
            >미리보기</v-btn
          >
        </div>
      </template>
    </form-row>

    <form-row v-if="isIndependentBusinessForm" label="미용사 면허증" required>
      <template #content>
        <div class="d-flex">
          <v-file-input
            :id="FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION"
            accept="image/*"
            v-model="playerInfo[FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION]"
            label="미용사 면허증 업로드"
            variant="underlined"
            :class="{ 'red-color': errors[PLAYER_PREFIX].smallBusinessConfirmation }"
            :errorMessages="errors[PLAYER_PREFIX].smallBusinessConfirmation"
            @update:modelValue="(e) => fileInputHandler(FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION, e)"
            @blur="resetPlayerInputError"
          ></v-file-input>
          <v-btn
            v-if="!isEmpty(fileUrl[FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION])"
            @click="() => openPreviewDialog(fileUrl[FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION])"
            flat
            >미리보기</v-btn
          >
        </div>
      </template>
    </form-row>

    <form-row v-if="!isStudentForm && !isIndependentBusinessForm" label="자격증 또는 면허증 사본" required>
      <template #content>
        <div class="d-flex">
          <v-file-input
            :id="FILE_CATEGORY.CERTIFICATE"
            accept="image/*"
            v-model="playerInfo[FILE_CATEGORY.CERTIFICATE]"
            label="자격증 또는 면허증 사본 업로드"
            variant="underlined"
            :class="{ 'red-color': errors[PLAYER_PREFIX].certificate }"
            :errorMessages="errors[PLAYER_PREFIX].certificate"
            @update:modelValue="(e) => fileInputHandler(FILE_CATEGORY.CERTIFICATE, e)"
            @blur="resetPlayerInputError"
          ></v-file-input>
          <v-btn v-if="!isEmpty(fileUrl[FILE_CATEGORY.CERTIFICATE])" @click="() => openPreviewDialog(fileUrl[FILE_CATEGORY.CERTIFICATE])" flat>미리보기</v-btn>
        </div>
      </template>
    </form-row>

    <form-row v-if="isStudentForm" label="재학증명서" required>
      <template #content>
        <div class="d-flex">
          <v-file-input
            :id="FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER"
            accept="image/*"
            v-model="playerInfo[FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER]"
            label="재학증명서 업로드"
            variant="underlined"
            :class="{ 'red-color': errors[PLAYER_PREFIX].enrollmentVerificationLetter }"
            :errorMessages="errors[PLAYER_PREFIX].enrollmentVerificationLetter"
            @update:modelValue="(e) => fileInputHandler(FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER, e)"
            @blur="resetPlayerInputError"
          ></v-file-input>
          <v-btn
            v-if="!isEmpty(fileUrl[FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER])"
            @click="() => openPreviewDialog(fileUrl[FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER])"
            flat
            >미리보기</v-btn
          >
        </div>
      </template>
    </form-row>

    <basic-input
      v-if="!isStudentForm"
      id="businessName"
      name="businessName"
      label="업소명"
      placeholder="예) OO 헤어샵"
      :text-value="playerInfo.businessName"
      @update:textValue="playerInfo.businessName = $event"
      @blur="resetPlayerInputError"
      :errorMessages="errors[PLAYER_PREFIX].businessName"
    />

    <daum-post-input
      id="address"
      required
      name="address"
      label="주소"
      placeholder="주소 찾기"
      button-text="찾기"
      :value="playerInfo.address"
      :on-complete-find-address="onCompleteFindAddress"
      :errorText="errors[PLAYER_PREFIX].address"
      @blur="resetPlayerInputError"
    />

    <basic-input
      id="addressDetail"
      name="addressDetail"
      :text-value="playerInfo.addressDetail"
      :disabled="playerInfo.address === ''"
      label="상세주소 입력"
      :placeholder="playerInfo.address === '' ? '주소를 검색해주세요' : '상세 주소를 입력해주세요'"
      :errorMessages="errors[PLAYER_PREFIX].addressDetail"
      @update:textValue="playerInfo.addressDetail = $event"
      @blur="resetPlayerInputError"
      required
    />

    <form-row label="종목선택" required>
      <template #content>
        <v-card variant="tonal" flat>
          <v-list>
            <v-list-subheader>지원 종목 리스트 (종목명 / 참가번호) </v-list-subheader>
            <div v-if="isUpdateForm">
              <div
                class="text-center text__empty"
                v-if="
                  playerInfo.contestItemAndPlayerSequences?.modifyMirrorMappings === undefined ||
                  playerInfo.contestItemAndPlayerSequences?.modifyMirrorMappings?.length === 0
                "
              >
                지원할 종목을 선택해주세요
              </div>
              <v-list-item v-for="(value, idx) in playerInfo.contestItemAndPlayerSequences.modifyMirrorMappings" :key="idx">
                <div class="d-flex justify-space-between">
                  <div>{{ allContestItems.find((v) => v.id === value.contestItemId)?.name }} / {{ value.playerSequence }}번</div>
                  <div>
                    <v-btn class="mr-2" color="success" flat @click="onClickContestItemUpdate(value.contestItemId, value.playerSequence, idx)">수정</v-btn>
                    <v-btn color="danger" flat @click="onCloseSelectedContestItem(idx)">삭제</v-btn>
                  </div>
                </div>
              </v-list-item>
            </div>
            <div v-else>
              <div class="text-center text__empty" v-if="playerInfo.contestItemAndPlayerSequences.createMirrorMappings?.length === 0">
                지원할 종목을 선택해주세요
              </div>
              <v-list-item v-for="(value, idx) in playerInfo.contestItemAndPlayerSequences.createMirrorMappings" :key="idx">
                <div class="d-flex justify-space-between">
                  <div>{{ allContestItems.find((v) => v.id === value.contestItemId)?.name }} / {{ value.playerSequence }}번</div>
                  <div>
                    <v-btn class="mr-2" color="success" flat @click="onClickContestItemUpdate(value.contestItemId, value.playerSequence, idx)">수정</v-btn>
                    <v-btn color="danger" flat @click="onCloseSelectedContestItem(idx)">삭제</v-btn>
                  </div>
                </div>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
        <div class="mb-4 mt-2">
          <v-dialog v-model="contestItemDialog">
            <template v-slot:activator="{ props }">
              <v-btn color="success" v-bind="props" block @click="resetContestItemSelectedId"> 종목 선택하기 </v-btn>
            </template>

            <v-card>
              <v-card-title>종목 선택하기</v-card-title>
              <v-card-subtitle>최종 선택한 종목과 참가번호로 선택됩니다.</v-card-subtitle>
              <v-card-text>
                <div class="mb-6">
                  <div class="mb-4"><required-caption label="종목 선택" required /></div>
                  <contest-select-group
                    :defaultSelectedInfo="contestItemSelectedId"
                    :selectHandler="eventSelectHandler"
                    :mode="EVENT"
                    :disabledKey="contestSelectGroupDisabledKey"
                    :isLoading="isContestItemDialogLoding"
                  ></contest-select-group>
                </div>
                <div>
                  <basic-input
                    id="playerSequence"
                    name="playerSequence"
                    label="참가번호"
                    type="number"
                    placeholder="참가번호를 입력해주세요."
                    :text-value="contestItemDialogInfo.playerSequence"
                    @update:textValue="contestItemDialogInfo.playerSequence = $event"
                    required
                  />
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="contestItemDialog = false">취소</v-btn>
                <v-btn color="blue-darken-1" @click="addContestItem">선택하기</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </form-row>

    <div class="mb-8">
      <basic-input
        id="note"
        name="note"
        label="비고"
        placeholder="입금 내역을 기재 바랍니다. 예) 6/15 홍길동 입금"
        hint="*입금하실 계좌번호: 하나은행 375-910019-28304 사)대한미용사회중앙회"
        :text-value="playerInfo.note"
        :errorMessages="errors[PLAYER_PREFIX].note"
        @update:textValue="playerInfo.note = $event"
        @blur="resetPlayerInputError"
        persistent-hint
        required
      />
      <!-- <p class="bank__description">
            *입금하실 계좌번호: 하나은행 375-910019-28304 사)대한미용사회중앙회
          </p> -->
    </div>

    <h3>추천인 정보</h3>
    <v-divider class="mb-4" inset></v-divider>

    <basic-input
      v-if="isStudentForm"
      id="associationName"
      name="associationName"
      :text-value="recommenderInfo.associationName"
      label="소속(학교)"
      placeholder="소속(학교)를 입력하세요"
      :errorMessages="errors[RECOMMENDER_PREFIX].associationName"
      @update:textValue="recommenderInfo.associationName = $event"
      @blur="resetRecommenderInputError"
      required
    />
    <form-row v-else label="소속" required>
      <template #content>
        <div class="mt-4 mb-4">
          <branch-select-group
            :branchOfficeId="recommenderInfo.branchOfficeId"
            :regionName="recommenderInfo.regionName"
            :branchName="recommenderInfo.branchName"
            @update:regionName="recommenderInfo.regionName = $event"
            @update:branchOfficeId="(value) => onUpdateBranch(value, RECOMMENDER_PREFIX)"
          />
          <small v-if="errors[RECOMMENDER_PREFIX].branchOfficeId" class="red-color p-2">{{ errors[PLAYER_PREFIX].branchOfficeId }}</small>
        </div>
      </template>
    </form-row>

    <basic-input
      v-if="!isStudentForm"
      id="ordinalNumber"
      name="ordinalNumber"
      :text-value="recommenderInfo.ordinalNumber"
      type="number"
      label="강사 기수"
      placeholder="숫자만 입력"
      :errorMessages="errors[RECOMMENDER_PREFIX].ordinalNumber"
      @update:textValue="recommenderInfo.ordinalNumber = $event"
      @blur="resetRecommenderInputError"
    />

    <basic-input
      id="name"
      name="name"
      :text-value="recommenderInfo.name"
      :label="isStudentForm ? '선생님 또는 교수명' : '지회장명 또는 기술강사명'"
      placeholder="성명"
      :errorMessages="errors[RECOMMENDER_PREFIX].name"
      @update:textValue="recommenderInfo.name = $event"
      @blur="resetRecommenderInputError"
      required
    />

    <basic-input
      id="phone"
      name="phone"
      :text-value="recommenderInfo.phone"
      label="연락처"
      type="tel"
      placeholder="예) 01012341234"
      :rules="validator.phone"
      :errorMessages="errors[RECOMMENDER_PREFIX].phone"
      @update:textValue="recommenderInfo.phone = $event"
      @blur="resetRecommenderInputError"
      required
    />

    <progress-loader v-if="isRegistLoading" />
    <v-btn v-else class="regist__btn" size="x-large" @click="formSubmitHandler" block>{{ buttonText }}</v-btn>
    <v-btn v-if="isUpdateForm" size="x-large" @click="cancelHandler" block>이전</v-btn>

    <v-dialog v-model="previewDialog">
      <v-img :src="previewImgSrc" height="100%" max-width="50rem" width="100%" min-width="30rem" cover></v-img>
    </v-dialog>
  </div>
</template>

<script>
import { computed, onMounted, watch, onUnmounted, ref, toRef } from 'vue';
import { isEmpty } from 'lodash-es';

import { useBranchStore } from '@/store/branch';

import { getContestItemParent } from '@/apis/contestItem';

import BasicDatePicker from '@/components/BasicDatePicker.vue';
import FormRow from '@/components/FormRow.vue';
import BasicInput from '@/components/BasicInput.vue';
import DaumPostInput from '@/components/DaumPostInput.vue';
import BasicButtonGroup from '@/components/form/BasicButtonGroup.vue';
import ProgressLoader from '@/components/ProgressLoader.vue';
import RequiredCaption from '@/components/RequiredCaption.vue';

import ContestSelectGroup from '@/views/components/contest/ContestSelectGroup.vue';
import BranchSelectGroup from '@/views/components/contest/BranchSelectGroup.vue';

import { ASSOCIATION_TYPE, CONTEST_REGISTER_TYPE_PASCAL, PLAYER_INFO_TITLE, FILE_CATEGORY, FILE_CATEGORY_ID_MAPPING } from '@/constants/contest';
import { CONTEST, CLASSIFICATION, SECTOR, EVENT } from '@/constants/contest/gridHeader';
import { errorToast, successToast } from '@/utils/toastUtil';
import { registPreventCloseEvent, destroyPreventCloseEvent } from '@/utils/event';
import { useContestStore } from '@/store/contest';

const PLAYER_PREFIX = 'player';
const RECOMMENDER_PREFIX = 'recommender';

const contestRegistGroup = Object.values(CONTEST_REGISTER_TYPE_PASCAL).map((value) => ({
  id: value,
  label: PLAYER_INFO_TITLE[value],
}));

/** onBlur 시 검사하는 validator */
const validator = {
  name: [
    (v) => v.trim().length >= 2 || '이름은 2글자 이상 입력해주세요.',
    (v) => v.trim().length <= 30 || '이름은 30글자 이하로 입력해주세요.',
    (v) => /^[ㄱ-ㅎ|가-힣|a-z|A-Z| |]+$/.test(v) || '이름은 한글, 영어만 가능합니다.',
  ],
  phone: [(v) => /^(01[0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/.test(v.trim()) || '올바른 휴대전화 번호가 아닙니다.'],
  // password: [(v) => v.trim().length < 4 || '4글자 이상 입력해주세요.'],
};

/**
 * 종목 관리 페이지
 */
export default {
  name: 'ContestantRegist',
  components: {
    FormRow,
    BasicInput,
    BasicDatePicker,
    BranchSelectGroup,
    BasicButtonGroup,
    DaumPostInput,
    ContestSelectGroup,
    ProgressLoader,
    RequiredCaption,
  },
  props: {
    defaultPlayerInfo: {
      type: Object,
      default: () => ({}),
    },
    buttonText: {
      type: String,
      default: '',
    },
    defaultRecommenderInfo: {
      type: Object,
      default: () => ({}),
    },
    submitHandler: {
      type: Function,
      default: () => {},
    },
    cancelHandler: {
      type: Function,
      default: () => {},
    },
    defaultFileUrl: {
      type: Object,
      default: () => ({}),
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const branchStore = useBranchStore();
    const contestStore = useContestStore();

    const isRegistLoading = ref(false);
    const previewDialog = ref(false);
    const contestItemDialog = ref(false);
    const previewImgSrc = ref('');
    const allContestItems = ref([]);

    /**
     * ContestItem 추가 모달
     * */
    let prevIdx = '';
    const contestItemSelectedId = ref({
      [CONTEST]: '',
      [CLASSIFICATION]: '',
      [SECTOR]: '',
      [EVENT]: '',
    });
    const contestItemDialogInfo = ref({
      contestItemId: 0,
      playerSequence: 0,
      isEditMode: false,
    });
    const isContestItemDialogLoding = ref(false);
    const playerInfo = toRef(props, 'defaultPlayerInfo');
    const recommenderInfo = toRef(props, 'defaultRecommenderInfo');
    const fileUrl = toRef(props, 'defaultFileUrl');

    const errors = ref({
      [PLAYER_PREFIX]: {},
      [RECOMMENDER_PREFIX]: {},
    });

    const isStudentForm = computed(() => playerInfo.value.contestRegisterType === CONTEST_REGISTER_TYPE_PASCAL.STUDENT);
    const isIndependentBusinessForm = computed(() => playerInfo.value.contestRegisterType === CONTEST_REGISTER_TYPE_PASCAL.INDEPENDENT_BUSINESS);

    const initData = async () => {
      if (playerInfo.value.contestId >= 1) {
        allContestItems.value = await contestStore.fetchAllContestItems(playerInfo.value.contestId);
      }
      await eventSelectHandler(contestStore[CONTEST][0]?.id, CONTEST);
    };

    // for update data page
    watch(
      () => props.isLoading,
      () => {
        initData();
      },
    );

    // for regist data page
    onMounted(() => {
      initData();
      registPreventCloseEvent(window);
    });

    onUnmounted(() => {
      destroyPreventCloseEvent(window);
    });

    const onUpdateBirthday = (value) => {
      playerInfo.value.birthday = value;
      resetPlayerInputError('birthday');
    };

    const onUpdateRegistType = (value) => {
      playerInfo.value.associationName = '';
      playerInfo.value.regionName = '';
      playerInfo.value.branchOfficeId = 0;
      playerInfo.value.branchName = '';

      playerInfo.value.contestRegisterType = value;
      playerInfo.value.associationType = ASSOCIATION_TYPE[value];
      recommenderInfo.value.associationType = ASSOCIATION_TYPE[value];
    };

    const onUpdateBranch = (branchOfficeId, type) => {
      if (type === PLAYER_PREFIX) {
        playerInfo.value.branchOfficeId = branchOfficeId;
        playerInfo.value.branchName = branchStore.branchInfoArray.find((v) => v.id === branchOfficeId)?.branchName;
        resetPlayerInputError('branchOfficeId');
      } else {
        recommenderInfo.value.branchOfficeId = branchOfficeId;
        recommenderInfo.value.branchName = branchStore.branchInfoArray.find((v) => v.id === branchOfficeId)?.branchName;
        resetRecommenderInputError('branchOfficeId');
      }
    };

    const onCompleteFindAddress = (address, zip) => {
      playerInfo.value.address = address;
      playerInfo.value.zip = zip;
    };

    // 같은 종류의 file 을 2번 업로드 했을 때 중복 제거
    const fileInputHandler = (category, files) => {
      for (let i = 0; i < playerInfo.value.playerFiles.length; i++) {
        if (playerInfo.value.playerFiles[i]?.category === category) {
          /**
           *  선수 수정: playerFiles, attachmentList의 index sync가 안맞기 때문에 체크
           */
          if (!playerInfo.value.playerFiles[i]?.url) {
            playerInfo.value.attachmentList.splice(i, 1);
          }

          fileUrl.value[category] = '';
          playerInfo.value.playerFiles.splice(i, 1);
        }
      }

      if (files.length > 0) {
        playerInfo.value.playerFiles.push({ category });
        playerInfo.value.attachmentList.push(files[0]);
      }

      resetPlayerInputError(FILE_CATEGORY_ID_MAPPING[category]);
    };

    const eventSelectHandler = async (id, type) => {
      if (type === CONTEST) {
        playerInfo.value.contestId = id;
        await contestStore.fetchAllContestItems(id);
      } else if (type === EVENT) {
        contestItemDialogInfo.value.contestItemId = id;
      }
    };

    const setInputError = ({ infoType, name, message }) => {
      errors.value[infoType][name] = message;
    };

    const resetInputError = (infoType, name) => {
      if (name in errors.value[infoType]) {
        delete errors.value[infoType][name];
      }
    };

    const resetPlayerInputError = (id) => {
      resetInputError(PLAYER_PREFIX, id);
    };

    const resetRecommenderInputError = (id) => {
      resetInputError(RECOMMENDER_PREFIX, id);
    };

    const resetContestItemSelectedId = async () => {
      contestItemSelectedId.value = {
        [CONTEST]: '',
        [CLASSIFICATION]: '',
        [SECTOR]: '',
        [EVENT]: '',
      };
      contestItemDialogInfo.value = {
        contestItemId: 0,
        playerSequence: 0,
        isEditMode: false,
      };
    };

    const formSubmitHandler = async () => {
      isRegistLoading.value = true;

      try {
        const { infoType, isValid, name, message } = await props.submitHandler(playerInfo.value, recommenderInfo.value);

        /** !isValid 면 인풋을 입력하라는 모달 표시 */
        if (!isValid) {
          setInputError({ infoType, name, message });
          errorToast(message);

          isRegistLoading.value = false;
          return;
        }

        successToast('대회 선수 등록/수정을 완료했습니다.');
      } catch (e) {
        isRegistLoading.value = false;
      }

      isRegistLoading.value = false;
    };

    const openPreviewDialog = (src) => {
      previewImgSrc.value = src;
      previewDialog.value = true;
    };

    const onCloseSelectedContestItem = (index) => {
      if (props.isUpdateForm) {
        const mirrorMapping = playerInfo.value.contestItemAndPlayerSequences.modifyMirrorMappings[index];
        if (mirrorMapping?.id) {
          playerInfo.value.contestItemAndPlayerSequences.deleteMirrorMappings.push({ id: mirrorMapping?.id });
        }
        playerInfo.value.contestItemAndPlayerSequences.modifyMirrorMappings = playerInfo.value.contestItemAndPlayerSequences.modifyMirrorMappings.filter(
          (item, idx) => idx !== index,
        );
      } else {
        playerInfo.value.contestItemAndPlayerSequences.createMirrorMappings = playerInfo.value.contestItemAndPlayerSequences.createMirrorMappings.filter(
          (item, idx) => idx !== index,
        );
      }
    };

    const addContestItem = () => {
      const { contestItemId, playerSequence, isEditMode } = contestItemDialogInfo.value;

      if (contestItemId === 0) {
        errorToast('종목을 선택해주세요.');
      } else if (playerSequence === 0) {
        errorToast('참가 번호를 입력해주세요.');
      } else if (playerInfo.value.contestItemAndPlayerSequences.length === 5) {
        errorToast('최대 5종목 까지 지원할 수 있습니다.');
      } else {
        if (isEditMode) {
          if (props.isUpdateForm) {
            playerInfo.value.contestItemAndPlayerSequences.modifyMirrorMappings[Number(prevIdx)] = {
              ...playerInfo.value.contestItemAndPlayerSequences.modifyMirrorMappings[Number(prevIdx)],
              contestItemId,
              playerSequence,
            };
          } else {
            playerInfo.value.contestItemAndPlayerSequences.createMirrorMappings[Number(prevIdx)] = {
              contestItemId,
              playerSequence,
            };
          }
        } else {
          if (props.isUpdateForm) {
            playerInfo.value.contestItemAndPlayerSequences.modifyMirrorMappings.push({
              contestItemId,
              playerSequence,
            });
          } else {
            playerInfo.value.contestItemAndPlayerSequences.createMirrorMappings.push({
              contestItemId,
              playerSequence,
            });
          }
          successToast('종목을 추가했습니다.');
        }

        resetPlayerInputError('contestItemAndPlayerSequences');
        contestItemDialogInfo.value = {
          contestItemId: 0,
          playerSequence: 0,
          isEditMode: false,
        };
        contestItemDialog.value = false;
      }
    };

    const onClickContestItemUpdate = async (contestItemId, playerSequence, idx) => {
      contestItemDialog.value = true;
      isContestItemDialogLoding.value = true;
      const ret = await getContestItemParent(contestItemId);

      if (!isEmpty(ret)) {
        const { classificationList, classificationSeq, contestItemSeq, contestList, contestSeq, eventList, sectorList, sectorSeq } = ret;

        contestItemSelectedId.value = {
          [CONTEST]: contestSeq,
          [CLASSIFICATION]: classificationSeq,
          [SECTOR]: sectorSeq,
          [EVENT]: contestItemSeq,
        };

        contestStore[CONTEST] = contestList;
        contestStore[CLASSIFICATION] = classificationList;
        contestStore[SECTOR] = sectorList;
        contestStore[EVENT] = eventList;

        prevIdx = idx;

        contestItemDialogInfo.value = {
          contestItemId: contestItemSeq,
          playerSequence: playerSequence,
          isEditMode: true,
        };
      }

      isContestItemDialogLoding.value = false;
    };

    return {
      allContestItems,
      previewDialog,
      contestItemDialog,
      previewImgSrc,
      contestItemSelectedId,
      contestItemDialogInfo,
      isContestItemDialogLoding,
      isRegistLoading,
      isStudentForm,
      isIndependentBusinessForm,
      PLAYER_PREFIX,
      RECOMMENDER_PREFIX,
      FILE_CATEGORY,
      CONTEST,
      EVENT,
      PLAYER_INFO_TITLE,
      playerInfo,
      recommenderInfo,
      fileUrl,
      errors,
      contestRegistGroup,
      resetPlayerInputError,
      resetRecommenderInputError,
      validator,
      eventSelectHandler,
      fileInputHandler,
      onUpdateBranch,
      formSubmitHandler,
      onUpdateBirthday,
      onCompleteFindAddress,
      onUpdateRegistType,
      openPreviewDialog,
      addContestItem,
      onClickContestItemUpdate,
      onCloseSelectedContestItem,
      resetContestItemSelectedId,
      isEmpty,
    };
  },
};
</script>

<style lang="scss" scoped>
.contestRegist {
  padding: 3rem;
  width: calc(100% - 20rem);
  align-self: center;

  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
  }
}

.bank__description {
  color: #0070bc;
  margin-top: 8px;
}

.regist__btn {
  color: white;
  background-color: #0070bc;
  margin-top: 2rem;
}

.red-color {
  color: red;
}

.text {
  &__empty {
    font-size: 14px;
  }
}
</style>
