<template>
  <canvas :id="chartId" class="chart-canvas" height="300"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'TrafficChart',
  data() {
    return {
      chartId: 'chart-line',
    };
  },
  mounted() {
    const chart = document.getElementById(this.chartId).getContext('2d');

    const gradientStroke1 = chart.createLinearGradient(0, 230, 0, 50);

    gradientStroke1.addColorStop(1, 'rgba(203,12,159,0.2)');
    gradientStroke1.addColorStop(0.2, 'rgba(72,72,176,0.0)');
    gradientStroke1.addColorStop(0, 'rgba(203,12,159,0)'); // purple colors

    const gradientStroke2 = chart.createLinearGradient(0, 230, 0, 50);

    gradientStroke2.addColorStop(1, 'rgba(20,23,39,0.2)');
    gradientStroke2.addColorStop(0.2, 'rgba(72,72,176,0.0)');
    gradientStroke2.addColorStop(0, 'rgba(20,23,39,0)'); // purple colors

    // Line chart
    new Chart(chart, {
      type: 'line',
      data: {
        labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Organic Search',
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: '#4BB543 ',
            borderColor: '#4BB543 ',
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke1,
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
            maxBarThickness: 6,
          },
          {
            label: 'Referral',
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: '#3A416F',
            borderColor: '#3A416F',
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
            maxBarThickness: 6,
          },
          {
            label: 'Direct',
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 2,
            pointBackgroundColor: '#17c1e8',
            borderColor: '#17c1e8',
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke2,
            data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: '#9ca2b7',
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: '#9ca2b7',
              padding: 10,
            },
          },
        },
      },
    });
  },
};
</script>
