import axios from '@/lib/axiosController';
import { korFormatToYYYYMMDDHHMMSS, toYYYYMMDD } from '@/utils/dateUtil';

/**
 * 대회 ID로 대회정보 조회
 * @param {string} id
 */
export const getContestById = async (id) => axios.get(`/contest/${id}`);

/**
 * 대회 ID로 대회 내용 수정
 * @param {string} id
 * @returns
 */
export const updateContestById = async ({ id, title, startDate, endDate, applicationStartDate, applicationEndDate, location }) =>
  axios.put(`/contest/${id}`, {
    id,
    title,
    startDate: toYYYYMMDD(startDate),
    endDate: toYYYYMMDD(endDate),
    applicationStartDate: korFormatToYYYYMMDDHHMMSS(applicationStartDate),
    applicationEndDate: korFormatToYYYYMMDDHHMMSS(applicationEndDate),
    location,
  });

/**
 * 대회 생성
 * @param {{
 *  title: string,
 *  startDate: date,
 *  endDate: date,
 *  applicationStartDate: date,
 *  applicationEndDate: date,
 *  location: string
 * }} params
 * @returns
 */
export const createContest = async ({ title, startDate, endDate, applicationStartDate, applicationEndDate, location }) =>
  axios.post('/contest/upload', {
    title,
    startDate,
    endDate,
    applicationStartDate,
    applicationEndDate,
    location,
  });

/**
 * 등록된 모든 대회 조회
 * @returns
 */
export const getAllContest = async () => axios.get('/contest/all');

/**
 * 대회 통계 Raw 데이터 가져오 API
 * @param {string} id
 * @returns
 */
export const getContestStatisticsRawDataById = async (id) => axios.get(`/contest/statistics/${id}`);
