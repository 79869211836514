<template>
  <div class="overflow-auto">
    <div class="mb-2" ref="pivot" />
  </div>
</template>

<script>
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import 'plotly.js/dist/plotly-basic.min';
import 'pivottable/dist/pivot.css';
import 'pivottable/dist/pivot.js';
import 'subtotal/dist/subtotal.min';
import { ref, onMounted } from 'vue';

export default {
  name: 'Pivot',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Array,
      default: () => [],
    },
    isSubTotal: {
      type: Boolean,
      default: true,
    },
    dataHandler: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const pivot = ref();
    const dataClass = $.pivotUtilities.SubtotalPivotData;
    const renderer = $.pivotUtilities.subtotal_renderers['Table With Subtotal'];
    onMounted(() => {
      $(pivot.value).pivot(props.data, {
        rows: props.rows,
        cols: props.cols,
        renderer,
        dataClass,
      });
    });

    const updateData = (data, rows, cols, isSubTotal) => {
      if (isSubTotal) {
        $(pivot.value).pivot(data, {
          rows,
          cols,
          renderer,
          dataClass,
        });
      } else {
        $(pivot.value).pivot(data, {
          rows,
          cols,
        });
      }
    };

    return {
      pivot,
      updateData,
    };
  },
};
</script>
<style scoped lang="scss">
.pvtTable {
  min-width: 100%;
  font-size: 10pt;
}
</style>
