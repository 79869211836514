<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <!--        카드 헤더-->
        <reply-card-header :title="title" :description="description">
          <template #header-utils>
            <div class="header-utils-buttons">
              <button class="mb-0 btn btn-success" v-if="!disableHeaderUtils" @click="showReplies"><i class="fas fa-eye me-2" />선택 보여주기</button>
              <button class="mb-0 btn btn-warning" v-if="!disableHeaderUtils" @click="deleteReplies"><i class="fas fa-trash me-2" />선택 삭제하기</button>
            </div>
          </template>
        </reply-card-header>
        <!--        카드 바디-->
        <div class="px-0 pb-0 card-body pt-0">
          <!--       테이블-->
          <reply-table-container @click="tableHandler" :table-id="tableId">
            <template #table-header>
              <reply-table-header :head-cells="headCells" show-checkbox />
            </template>
            <template #table-body>
              <tbody>
                <reply-table-row v-for="row of rows" :key="row.id" :reply-id="row.id" show-actions show-checkbox>
                  <template #table-data>
                    <td class="text-sm">{{ row.content }}</td>
                    <td class="text-sm">{{ row.userName }}</td>
                    <td class="text-sm">{{ row.createdDate }}</td>
                    <td class="text-sm">{{ row.modifiedDate }}</td>
                    <td class="text-sm">
                      <span :class="`badge badge-${row.displayStatus === 'SHOW' ? 'success' : 'danger'} badge-sm`">
                        {{ row.displayStatus }}
                      </span>
                    </td>
                  </template>
                </reply-table-row>
              </tbody>
            </template>
          </reply-table-container>
          <div class="text-center" v-if="!rows || rows.length === 0">등록된 댓글이 없습니다.</div>
          <argon-pagination>
            <argon-pagination-item v-if="pageInfo.isFirst" :key="pageInfo.first" :active="false" :first="true" @click="getPageReplies(pageInfo.first)" />
            <argon-pagination-item v-if="pageInfo.isPrev" :key="pageInfo.prev" :active="false" :prev="true" @click="getPageReplies(pageInfo.prev)" />
            <argon-pagination-item v-for="i in pageInfo.pageItems" :key="i" :label="i.toString()" :active="i === pageInfo.current" @click="getPageReplies(i)" />
            <argon-pagination-item v-if="pageInfo.isNext" :key="pageInfo.next" :active="false" :next="true" @click="getPageReplies(pageInfo.next)" />
            <argon-pagination-item v-if="pageInfo.isLast" :key="pageInfo.last" :active="false" :last="true" @click="getPageReplies(pageInfo.last)" />
          </argon-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-utils-buttons {
  & > button + button {
    margin-left: 8px;
  }
}
</style>

<script>
import { TABLE_CHECKBOX } from '@/constants/community/board';
import { computed, onMounted, ref, inject } from 'vue';
import { deleteReplies, deleteReply, showReply, showReplies, getReplies } from '@/apis/reply';
import ArgonPagination from '@/components/ArgonPagination';
import ArgonPaginationItem from '@/components/ArgonPaginationItem';
import moment from 'moment';
import ReplyCardHeader from '@/views/components/community/replyCard/ReplyCardHeader';
import ReplyTableContainer from '@/views/components/community/replyCard/ReplyTableContainer';
import ReplyTableHeader from '@/views/components/community/replyCard/ReplyTableHeader';
import ReplyTableRow from '@/views/components/community/replyCard/ReplyTableRow';
import { ACTIONS_DELETE_REPLY, ACTIONS_SHOW_REPLY, HEAD_CELLS } from '@/constants/community/reply';

const useReply = (selected, rows, pageInfo, postId, condition, pagesSize) => {
  const swal = inject('$swal');

  const removeReplies = async () => {
    const selectedList = [...selected.value].map((s) => s.id);
    await swal('삭제하시겠습니까?');
    try {
      await deleteReplies(selectedList);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    [...selected.value].forEach((s) => (s.checked = false));
    await searchPageReplies();
  };
  const removeReply = async (replyId) => {
    await swal.fire('삭제하시겠습니까?');
    try {
      await deleteReply(replyId);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    await searchPageReplies();
  };
  const openReplies = async () => {
    const selectedList = [...selected.value].map((s) => s.id);
    await swal.fire('복구 하시겠습니까?');
    try {
      await showReplies(selectedList);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    [...selected.value].forEach((s) => (s.checked = false));
    await searchPageReplies();
  };
  const openReply = async (replyId) => {
    await swal.fire('복구 하시겠습니까?');
    try {
      await showReply(replyId);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    await searchPageReplies();
  };

  const searchPageReplies = async () => {
    selected.value = new Set();
    const result = await getReplies(postId, condition);
    rows.value = result.content.map((r) => {
      return {
        ...r,
        createdDate: moment(r.createdDate).format('YYYY년 MM월 DD일 HH:mm'),
        modifiedDate: r.modifiedDate ? moment(r.modifiedDate).format('YYYY년 MM월 DD일 HH:mm') : '',
      };
    });

    const { number, totalPages } = result;
    let size = pagesSize.value;

    const quotient = parseInt(number / size);

    const start = quotient * size + 1;
    const end = quotient * size + size > totalPages ? totalPages % size : size;

    pageInfo.value = {
      isFirst: quotient !== 0,
      isPrev: quotient !== 0,
      isNext: quotient * size + size < totalPages,
      isLast: quotient * size + size < totalPages,
      first: 1,
      last: totalPages,
      prev: quotient * size,
      next: (quotient + 1) * size + 1,
      current: number + 1,
      pageItems: Array.apply(0, Array(end)).map((element, index) => index + start),
    };
  };

  return { deleteReplies: removeReplies, deleteReply: removeReply, showReplies: openReplies, showReply: openReply, searchPageReplies };
};

export default {
  name: 'ReplyList',
  props: {
    tableId: {
      type: String,
      required: true,
    },
    postId: {
      type: String,
      required: true,
    },
  },
  components: {
    ArgonPagination,
    ArgonPaginationItem,
    ReplyCardHeader,
    ReplyTableContainer,
    ReplyTableHeader,
    ReplyTableRow,
  },
  setup(props) {
    const rows = ref([]);
    const pagesSize = ref(5);
    const pageInfo = ref({
      prev: 0,
      next: 0,
      first: 0,
      last: 0,
      isPrev: 0,
      isNext: 0,
      isFirst: 0,
      isLast: 0,
      current: 0,
      pageItems: [],
    });

    const condition = {
      size: 10,
      page: 0,
    };

    const selected = ref(new Set());

    onMounted(async () => {
      await searchPageReplies(props.postId, condition);
    });

    const disableHeaderUtils = computed(() => selected.value.size <= 0);

    const { deleteReplies, deleteReply, showReplies, showReply, searchPageReplies } = useReply(selected, rows, pageInfo, props.postId, condition, pagesSize);

    const checkboxHandler = (target) => {
      if (selected.value.has(target)) {
        selected.value.delete(target);
        return;
      }
      selected.value.add(target);
    };

    const tableHandler = (e) => {
      const { elementName, replyId } = e.target.dataset;

      if (!elementName) return;

      if (elementName === TABLE_CHECKBOX) {
        checkboxHandler(e.target);
        return;
      }

      if (elementName === ACTIONS_DELETE_REPLY) {
        deleteReply(replyId);
        return;
      }

      if (elementName === ACTIONS_SHOW_REPLY) {
        showReply(replyId);
      }
    };

    const getPageReplies = async (i) => {
      condition.page = i - 1;
      await searchPageReplies();
    };

    return {
      selected,
      disableHeaderUtils,
      headCells: HEAD_CELLS,
      rows,
      pagesSize,
      pageInfo,
      deleteReplies,
      deleteReply,
      showReplies,
      showReply,
      checkboxHandler,
      tableHandler,
      getPageReplies,
    };
  },
};
</script>
