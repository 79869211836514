<template>
  <div class="mb-4">
    <required-caption class="mb-2" :disabled="disabled" :label="label" :required="required" />
    <slot name="content" :disabled="disabled" :label="label" :required="required" />
  </div>
</template>

<script>
import RequiredCaption from '@/components/RequiredCaption.vue';

export default {
  components: { RequiredCaption },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style></style>
