<template>
  <main-content-box>
    <template #main>
      <div class="mb-4">임의적으로 상장만 출력할때 사용합니다. (DB에 저장되지는 않습니다.)</div>
      <div class="mb-6">
        <grid-view
          ref="gridRef"
          :columns="columns"
          :data="data"
          :column-options="columnOptions"
          :row-headers="rowHeaders"
          :update-rows="updateRows"
          :is-cancel="false"
          :is-save="false"
          :on-click-print="onClickPrint"
          :default-editable="true"
        >
          <template #addBtn> </template>
        </grid-view>
      </div>
      <v-btn color="lime" class="mb-4" size="large" @click="addRow">+</v-btn>
      <v-btn color="success" size="large" @click="onPrintPDF" :disabled="data.length === 0">출력하기</v-btn>
    </template>
  </main-content-box>
</template>

<script>
import { ref } from 'vue';
import MainContentBox from '@/components/MainContentBox.vue';
import { prizesSaveToPdf } from '@/utils/pdf';
import GridView from '@/views/components/common/GridView.vue';
import { PRIZE_PRINT_HEADER } from '@/constants/contest/gridHeader';

const setDefaultPrize = (title = '', playerName = '', sectionName = '') => ({ title, playerName, sectionName });
// const INITIAL_PRIZES = [setDefaultPrize()];

export default {
  components: {
    GridView,
    MainContentBox,
  },
  setup() {
    const data = ref([{ title: '', playerName: '', sectionName: '' }]);
    const columns = PRIZE_PRINT_HEADER;
    const columnOptions = {
      resizable: true,
    };
    const rowHeaders = ['rowNum'];
    const gridRef = ref({});

    const columnNames = columns.map((e) => e.name);

    const updateRows = async (updatedRows) => {
      console.log(updatedRows);
      data.value = updatedRows.map((v) => ({ ...v }));
      return data.value;
    };

    const addRow = async () => {
      const defaultPrize = setDefaultPrize();
      data.value.push(defaultPrize);
      gridRef.value.addRow(defaultPrize);
      console.log(data.value);
    };

    const removeRow = (rowIndex) => {
      data.value.splice(rowIndex, 1);
    };

    const onPrintPDF = async () => {
      gridRef.value.printPDF();
    };

    const onClickPrint = async (rows) => {
      await prizesSaveToPdf(rows);
    };

    return {
      data,
      onClickPrint,
      addRow,
      removeRow,
      columns,
      columnNames,
      columnOptions,
      rowHeaders,
      gridRef,
      updateRows,
      onPrintPDF,
    };
  },
};
</script>

<style lang="scss">
.input-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 16px;

  & > :not(:last-child) {
    margin-right: 1rem;
  }
}
</style>
