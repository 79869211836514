<template>
  <nav aria-label="breadcrumb">
    <ol :class="'px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb'">
      <li :class="'text-sm breadcrumb-item'">
        <a class="text-dark'">
          <i class="ni ni-box-2"></i>
        </a>
      </li>
      <li class="`text-sm breadcrumb-item text-dark`">
        <a class="text-dark" href="#">{{ currentDirectory }}</a>
      </li>
    </ol>
    <h3 class="mb-0 font-weight-bolder text-dark'">
      {{ currentPage }}
    </h3>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Breadcrumbs',
  props: {
    currentPage: {
      type: String,
      default: '',
    },
    currentDirectory: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['isNavFixed']),
  },
};
</script>
