<template>
  <v-dialog :model-value="dialog" @update:modelValue="updateDialog">
    <template v-slot:activator="{ props }">
      <v-btn class="prizeCreate__createBtn" v-bind="props"> 수상명 추가 </v-btn>
    </template>
    <v-card class="dialog">
      <v-card-title>
        <span class="text-h5">수상명 추가</span>
      </v-card-title>
      <v-card-text>
        <div class="prizeCreate__row">
          <span class="prizeCreate__title">대회명</span>
          <span class="prizeCreate__desc">{{ contestTitle }}</span>
        </div>

        <div class="prizeCreate__row">
          <span class="prizeCreate__title">부문</span>
          <span class="prizeCreate__desc">{{ classificationTitle }}</span>
        </div>

        <div class="prizeCreate__row">
          <span class="prizeCreate__title">분류</span>
          <span class="prizeCreate__desc">{{ sectorTitle }}</span>
        </div>

        <div class="prizeCreate__row">
          <span class="prizeCreate__title">종목</span>
          <span class="prizeCreate__desc">{{ eventTitle }}</span>
        </div>

        <basic-input
          id="prizeName"
          label="수상명"
          placeholder="수상명을 입력해주세요"
          :text-value="createInfos.prizeName"
          @update:textValue="createInfos.prizeName = $event"
          required
        ></basic-input>
        <basic-input
          id="prizeMaxAllowed"
          label="수상인원"
          placeholder="수상인원을 입력해주세요"
          type="number"
          :text-value="createInfos.maxAllowed"
          @update:textValue="createInfos.maxAllowed = $event"
          required
        ></basic-input>
        <basic-input
          id="prizeTier"
          label="등급"
          placeholder="등급을 입력해주세요"
          type="number"
          :text-value="createInfos.tier"
          @update:textValue="createInfos.tier = $event"
          required
        ></basic-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" text @click="init"> 닫기 </v-btn>
        <v-btn color="blue-darken-1" text @click="prizeCreateHandler"> 생성 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue';
import BasicInput from '@/components/BasicInput.vue';

export default {
  name: 'PrizeCreateDialog',
  components: { BasicInput },
  props: {
    contestTitle: {
      type: String,
      default: '',
    },
    classificationTitle: {
      type: String,
      default: '',
    },
    sectorTitle: {
      type: String,
      default: '',
    },
    eventTitle: {
      type: String,
      default: '',
    },
    checkIsOpenDialog: {
      type: Function,
      default: () => {},
    },
    activeHandler: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const dialog = ref(false);

    const createInfos = ref({
      prizeName: '',
      tier: 0,
      maxAllowed: 0,
    });

    const init = () => {
      dialog.value = false;
      createInfos.value = {
        prizeName: '',
        tier: 0,
        maxAllowed: 0,
      };
    };

    const prizeCreateHandler = async () => {
      const { prizeName, tier, maxAllowed } = createInfos.value;
      try {
        await props.activeHandler(prizeName, tier, maxAllowed);
        init();
      } catch (e) {
        // err
      }
    };

    const updateDialog = (isOpen) => {
      if (isOpen) {
        if (props.checkIsOpenDialog()) {
          dialog.value = true;
        }
      } else {
        init();
      }
    };

    return { dialog, prizeCreateHandler, updateDialog, createInfos, init };
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  width: 40rem;
  padding: 1rem;
}

.prizeCreate {
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__title {
    width: 10rem;
    font-weight: 500;
  }

  &__desc {
    width: 100%;
    color: #394150;
  }

  &__createBtn {
    background-color: #0070bc;
    color: white;
  }
}
</style>
