<template>
  <tr>
    <td v-if="showCheckbox">
      <div class="my-auto form-check check-box">
        <input type="checkbox" :id="articleId" class="form-check-input" :data-element-name="tableCheckbox" :data-article-id="articleId" />
      </div>
    </td>
    <slot name="table-data" />
  </tr>
</template>

<style scoped lang="scss">
.check-box {
  margin-left: 20px;
}

.action_button {
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  border-radius: 20px;

  &:hover {
    background: aliceblue;
  }
}
</style>

<script>
import { TABLE_CHECKBOX } from '@/constants/news/news';

export default {
  name: 'NewsTableRow',
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    articleId: {
      type: Number,
      required: true,
    },
    displayStatus: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      tableCheckbox: TABLE_CHECKBOX,
    };
  },
};
</script>
