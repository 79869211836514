<template>
  <div class="pb-0 card-header mb-3 mb-lg-5">
    <div class="justify-content-between">
      <div>
        <div class="row mb-2">
          <div class="col-auto">
            <button class="btn px-0" style="background: none; box-shadow: none" @click="this.$router.go(-1)">
              <i class="fas fa-arrow-left" />
            </button>
          </div>
          <div class="col-auto ms-auto">
            <div>
              <slot name="header-utils" />
            </div>
          </div>
        </div>
        <div class="row mb-2 border-bottom">
          <div class="col-auto">
            <i class="fa fa-user me-2" />
            <span class="h5">{{ author }}</span>
          </div>
          <div class="ms-auto col-auto float-end">
            <div class="h6">[최근 수정일] {{ !!modifiedDate ? modifiedDate : createdDate }} | [조회수] {{ views }}</div>
          </div>
        </div>
        <h4 v-if="!!title" class="mt-3">{{ title }}</h4>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

<script>
export default {
  name: 'PostCardHeader',
  props: {
    title: {
      type: String,
    },
    views: {
      type: Number,
    },
    author: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    modifiedDate: {
      type: String,
    },
  },
};
</script>
