<template>
  <div class="pb-0 card-header mb-3 mb-lg-5">
    <div class="d-lg-flex justify-content-between w-100">
      <div>
        <h5 class="mb-0">댓글 목록</h5>
      </div>
      <div style="display: flex">
        <slot name="header-utils" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReplyCardHeader',
};
</script>
