import moment from 'moment';

export const toYYYYMMDD = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const toYYYYMMDDHHMMSS = (date) => {
  return moment(date).format('YYYY-MM-DD hh:mm:ss');
};

export const korFormatToYYYYMMDDHHMMSS = (date) => {
  return moment(date, 'YYYY년 MM월 DD일 hh:mm a').format('YYYY-MM-DDThh:mm:ss');
};

export const YYYYMMDDHHMMSSToKorFormat = (date) => {
  return moment(date, 'YYYY-MM-DDThh:mm:ss').format('YYYY년 MM월 DD일 hh:mm a');
};

export const isAfterFromNow = (date) => {
  return moment(date).isAfter(new Date());
};

export const isBetweenFromNow = (start, end) => {
  return moment(new Date()).isBetween(moment(start), moment(end));
};
