import axios from '@/lib/axiosController';

export const showArticle = (articleId) => axios.put(`/article/show/${articleId}`);

export const showArticles = (articleIds) => axios.put(`/article/show-articles`, { articleIds });

export const getArticle = (articleId) => axios.get(`/article/${articleId}`);

export const updateArticle = (articleId, body) =>
  axios.put(`/article/${articleId}`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const createArticle = (body) =>
  axios.post(`/article/upload`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const searchArticles = (condition) => axios.get(`/article/search`, { params: condition });

export const deleteArticle = (articleId) => axios.delete(`/article/delete/${articleId}`);

export const deleteArticles = (articleIds) => axios.delete(`/article/delete-articles`, { data: { articleIds } });

export const registerImportantArticles = (articleIds) => axios.put(`/article/important`, { articleIds });

export const clearImportantArticles = (articleIds) => axios.put(`/article/unimportant`, { articleIds });

export const uploadArticleImage = (body) =>
  axios.post(`/article/upload-image`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
