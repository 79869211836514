<template>
  <main-content-box>
    <template #main>
      <div class="prize__header">
        <div class="prize__input-wrapper">
          <contest-select-group :defaultSelectedInfo="contestItemSelectedId" :selectHandler="itemSelectHandler" :mode="EVENT"></contest-select-group>
        </div>
        <span>수상관리는 종목을 선택하시고, 검색후 등록/수정하실 수 있습니다.</span>
      </div>
      <div>
        <grid-view ref="gridRef" :columns="PRIZE_MANAGEMENT_HEADER" :data="data" :updateRows="updateRows" :validationFailHandler="validationFailHandler">
          <template #addBtn>
            <excel-export-btn :selected-event-id="contestItemSelectedId[EVENT]" :grid-ref="gridRef" file-name="수상명 관리" :disabled="data.length === 0" />
            <prize-create-dialog
              :contestTitle="eventInfo.contestTitle"
              :classificationTitle="eventInfo.classificationTitle"
              :sectorTitle="eventInfo.sectorTitle"
              :eventTitle="eventInfo.title"
              :checkIsOpenDialog="checkIsOpenDialog"
              :activeHandler="createPrizeHandler"
            ></prize-create-dialog>
          </template>
        </grid-view>
      </div>
    </template>
  </main-content-box>
</template>

<script>
import { ref } from 'vue';
import { getPrizeListByContestItemId, createPrize, updatePrizeByPrizeId } from '@/apis/prize';
import { useContestStore } from '@/store/contest';

import MainContentBox from '@/components/MainContentBox.vue';
import ExcelExportBtn from '@/components/ExcelExportBtn';

import GridView from '@/views/components/common/GridView.vue';
import ContestSelectGroup from '@/views/components/contest/ContestSelectGroup.vue';
import PrizeCreateDialog from '@/views/components/contest/PrizeCreateDialog.vue';

import { PRIZE_MANAGEMENT_HEADER, CONTEST, CLASSIFICATION, SECTOR, EVENT } from '@/constants/contest/gridHeader';
import { errorToast, successToast, warningToast } from '@/utils/toastUtil';

const makeGridData = (prizes, contestTitle, classificationTitle, sectorTitle, eventTitle) =>
  prizes.map((v) => ({
    contestTitle,
    classificationTitle,
    sectorTitle,
    eventTitle,
    ...v,
  }));

export default {
  name: 'PrizeManagement',
  components: { MainContentBox, ContestSelectGroup, GridView, PrizeCreateDialog, ExcelExportBtn },
  setup() {
    const contestStore = useContestStore();
    const eventInfo = ref({
      id: 0,
      contestTitle: '',
      classificationTitle: '',
      sectorTitle: '',
      title: '',
    });
    const data = ref([]);

    const contestItemSelectedId = ref({
      [CONTEST]: '',
      [CLASSIFICATION]: '',
      [SECTOR]: '',
      [EVENT]: '',
    });

    const gridRef = ref({});

    const itemSelectHandler = async (contestItemId, type) => {
      if (type === EVENT) {
        eventInfo.value = contestStore[EVENT].find((v) => v.id === contestItemId);
        fetchPrizeData();
      } else {
        eventInfo.value = {};
      }
    };

    const fetchPrizeData = async (notificaiton = true) => {
      const { id, contestTitle, classificationTitle, sectorTitle, eventTitle } = eventInfo.value;
      const prizes = await getPrizeListByContestItemId(id);
      data.value = makeGridData(prizes, contestTitle, classificationTitle, sectorTitle, eventTitle);

      if (notificaiton) {
        successToast('데이터 조회가 완료되었습니다.');
      }
    };

    const validationFailHandler = () => {
      warningToast('수상인원, 등급은 숫자만 입력할 수 있습니다.');
    };

    const updateRows = async (updatedRows) => {
      await Promise.all(updatedRows.map((v) => updatePrizeByPrizeId(v.id, v.title, v.tier, v.maxAllowed)));

      fetchPrizeData(false);
      return true;
    };

    const createPrizeHandler = async (title, tier, maxAllowed) => {
      const contestItemId = eventInfo.value.id;
      await createPrize(contestItemId, title, tier, maxAllowed);
      fetchPrizeData();
    };

    const checkIsOpenDialog = () => {
      if (!eventInfo.value.title) {
        errorToast('종목을 선택해주세요.');
        return false;
      }

      return true;
    };

    return {
      gridRef,
      PRIZE_MANAGEMENT_HEADER,
      EVENT,
      contestItemSelectedId,
      data,
      eventInfo,
      itemSelectHandler,
      createPrizeHandler,
      validationFailHandler,
      updateRows,
      checkIsOpenDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.prize {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
