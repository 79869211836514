<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <article-create-header />
        <div class="card-body pt-0">
          <Form as="v-form" :validation-schema="schema" @submit="onSubmit">
            <div class="autocomplete__deep-wrapper">
              <card-header title="* 분류 선택" class="mb-2 p-0" />
              <v-select v-model="selectedNewsTable" :items="newsTables" :menu-props="{ maxHeight: 300 }" label="분류 선택" item-title="title" item-value="id" />
            </div>

            <card-header title="* 기사 제목" class="mb-2 p-0" />
            <text-field-with-validation name="title" type="text" v-model="title" label="기사 제목" :rules="schema.title" />

            <card-header title="* 기사 본문 요약" class="mb-2 ps-1" />
            <text-area-with-validation name="summary" label="기사 본문 요약" v-model="summary" :rules="schema.summary"></text-area-with-validation>

            <card-header title="* 기사 본문" class="mb-2 ps-1" />
            <article-publishing-editor :update-content="updateContent" selector="publishing-editor" />

            <card-header title="* 기자 선택" class="mb-2 ps-1" />
            <div class="mb-2 select-wrapper">
              <select class="form-select" aria-label="기자 선택" v-model="selectedOption">
                <option v-for="option of options" :key="option.id" :value="option.id">{{ option.reporterName }} - {{ option.reporterEmail }}</option>
              </select>
            </div>
            <card-header title="* 썸네일 선택" class="mb-2 ps-1" />
            <form action="/file-upload" class="form-control dropzone mb-4" id="dropzone" v-on:change="onFileChange">
              <div class="fallback">
                <input name="file" type="file" />
              </div>
            </form>
            <button class="submit-btn btn btn-success" type="submit" @click.prevent="onSubmit">저장 하기</button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createArticle } from '@/apis/article';
import { ref, onMounted } from 'vue';
import { Form } from 'vee-validate';
import { object, string, number } from 'yup';
import CardHeader from '@/components/Card/CardHeader';
import ArticlePublishingEditor from '@/views/components/news/articleCard/ArticlePublishingEditor';
import { useRouter } from 'vue-router';
import { getAllReporter } from '@/apis/reporter';
import ArticleCreateHeader from '@/views/components/news/articleCard/ArticleCreateHeader';
import TextFieldWithValidation from '@/views/components/common/TextFieldWithValidation';
import TextAreaWithValidation from '@/views/components/common/TextAreaWithValidation';
import { inject } from 'vue';
import { getAllNews } from '@/apis/news';

const schema = object({
  title: string().required('제목을 입력해주세요.').label('기사 제목'),
  summary: string().required('본문 요약을 입력해주세요.').label('기사 본문 요악'),
  content: string().required('기사 본문을 입력해주세요.').label('기사 본문'),
  reporter: number().required('보도 기자를 추가해주세요.').label('보도 기자'),
});

const useArticleDetail = () => {
  const getReporters = () => {
    return getAllReporter();
  };
  return { getReporters };
};

export default {
  name: 'ArticleCreate',
  props: {
    newsId: {
      type: String,
      required: true,
    },
  },
  components: {
    ArticlePublishingEditor,
    CardHeader,
    ArticleCreateHeader,
    Form,
    TextFieldWithValidation,
    TextAreaWithValidation,
  },
  setup() {
    const swal = inject('$swal');
    const articleEditor = ref();
    const selectedOption = ref();
    const router = useRouter();
    const title = ref('');
    const summary = ref('');
    const content = ref('');
    const options = ref([]);
    const thumbnailFile = ref(null);
    const newsTables = ref([]);
    const selectedNewsTable = ref(null);

    onMounted(async () => {
      const result = await getReporters();
      options.value = result;
      const newsTableResult = await getAllNews();
      newsTables.value = newsTableResult;
    });

    const { getReporters } = useArticleDetail();

    const updateContent = (html) => {
      content.value = html;
    };

    const onFileChange = (e) => {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      thumbnailFile.value = files[0];
    };

    const validate = () => {
      if (!selectedNewsTable.value) {
        return { isValid: false, message: '업로드할 기사 게시판을 선택해주세요.' };
      }

      if (!title.value) {
        return { isValid: false, message: '제목을 입력해주세요.' };
      }

      if (!summary.value) {
        return { isValid: false, message: '본문 요약을 입력해주세요.' };
      }

      if (!content.value) {
        return { isValid: false, message: '기사 본문을 입력해주세요.' };
      }

      if (!selectedOption.value) {
        return { isValid: false, message: '보도 기자를 추가해주세요.' };
      }

      if (!thumbnailFile.value) {
        return { isValid: false, message: '썸네일을 추가해주세요.' };
      }

      return { isValid: true };
    };

    const onSubmit = async () => {
      /** validation */
      const { isValid, message } = validate();
      if (!isValid) {
        swal.fire({
          icon: 'warn',
          text: message,
        });
        return;
      }

      const formData = new FormData();
      formData.append(
        'request',
        new Blob(
          [
            JSON.stringify({
              newsId: selectedNewsTable.value,
              title: title.value,
              body: content.value,
              bodySummary: summary.value,
              reporterId: selectedOption.value,
            }),
          ],
          { type: 'application/json' },
        ),
      );
      if (thumbnailFile.value) {
        formData.append('thumbnail', thumbnailFile.value);
      }
      try {
        await createArticle(formData);
      } catch (e) {
        swal.fire({
          icon: 'error',
          text: '업로드에 실패했습니다.',
        });
        return;
      }
      router.back();
    };

    return {
      schema,
      articleEditor,
      title,
      summary,
      options,
      selectedOption,
      onFileChange,
      updateContent,
      onSubmit,
      newsTables,
      selectedNewsTable,
    };
  },
};
</script>

<style lang="scss" scoped>
.editor-wrapper {
}
.select-wrapper:deep .choices__item--choice {
  padding: 0.3rem 0.5rem;
}
</style>
