<template>
  <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
  <sidenav v-if="showSidenav" />
  <argon-snackbar v-if="showSnackbar" :title="snackbarTitle" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <navbar v-if="showNavbar" />
    <router-view :key="$route.fullPath" />
    <app-footer v-show="showFooter" />
    <configurator :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']" />
  </main>
</template>
<script>
import { storeToRefs } from 'pinia';
import Configurator from '@/examples/Configurator.vue';
import Navbar from '@/components/Navbar.vue';
import AppFooter from '@/examples/Footer.vue';
import Sidenav from './examples/Sidenav';
import useMetaStore from '@/store/meta';
import { useAuthStore } from '@/store/auth';
import { useRoute, useRouter } from 'vue-router';
import { watch } from 'vue';
import { RouterKey } from '@/constants/routerKey';
import ArgonSnackbar from '@/components/ArgonSnackbar';
import { getAuthority, getToken } from '@/utils/tokenUtil';
import { setAuthHeader } from '@/lib/axiosController';

export default {
  name: 'App',
  data() {
    return {
      showSnackbar: false,
      snackbarTitle: '',
    };
  },
  created() {
    const token = getToken();
    if (token) {
      setAuthHeader(token);
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const metaStore = useMetaStore();
    const authStore = useAuthStore();
    const { layout, showSidenav, showNavbar, showFooter, showConfig, hideConfigButton } = storeToRefs(metaStore);
    authStore.setInitToken();

    watch(route, (to) => {
      if (to?.path === RouterKey.login) {
        metaStore.$patch((state) => {
          state.showSidenav = false;
          state.showNavbar = false;
        });
      } else {
        try {
          const authority = getAuthority();
          if (!authority) {
            // todo change with snackbar
            alert('인증이 만료되어 로그인 화면으로 이동합니다.');
            authStore.logout();
            router?.replace({ path: '/login' });
          }
        } catch (e) {
          console.error(e);
        }
        metaStore.$patch((state) => {
          state.showSidenav = true;
          state.showNavbar = true;
        });
      }
    });
    return {
      layout,
      showSidenav,
      showNavbar,
      showFooter,
      showConfig,
      hideConfigButton,
    };
  },
  components: {
    ArgonSnackbar,
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  computed: {},
};
</script>

<style>
* {
  scroll-behavior: auto !important;
}
</style>
