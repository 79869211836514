<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <!--        카드 바디-->
        <div class="px-0 pb-0 card-body pt-0">
          <!--          드롭다운 & 검색창-->
          <div class="w-15 mt-lg-0 select-wrapper ml-6">
            <select
              :id="'choices-authority'"
              @change="changeOption"
              v-model="selectedOption"
              class="form-control select mt-6"
              :name="'choices-' + selectId"
              :selected="true"
            >
              <option v-for="(option, idx) in options" :key="idx" :value="option.value">
                <!--              :selected="isSelected(option.value)"-->
                {{ option.label }}
              </option>
            </select>
          </div>
          <!--       테이블-->
          <user-menu-table-container>
            <template #table-header>
              <authority-table-header :show-checkbox="false" :head-cells="headCells" />
            </template>
            <template #table-body>
              <tbody>
                <user-menu-table-row v-for="row of rows" :key="row.id">
                  <template #table-data>
                    <td class="text-sm text-start ps-6">
                      <!--                      <div class="form-check" style="margin-left: 20px">-->
                      {{ row.name }}
                      <!--                      </div>-->
                    </td>
                    <td class="text-sm text-start ps-6">
                      <!--                      <div class="form-check" style="margin-left: 20px">-->
                      {{ row.path }}
                      <!--                      </div>-->
                    </td>
                    <td class="text-sm text-start ps-13">
                      <div class="form-check">
                        <input id="writeAuthorityCheck" class="form-check-input" type="checkbox" :checked="row.writeAuthority" onclick="return false;" />
                      </div>
                    </td>
                    <td class="text-sm text-start ps-6">
                      <span :class="`badge badge-${row.displayStatus === 'SHOW' ? 'success' : 'danger'} badge-sm`">
                        {{ row.displayStatus === 'SHOW' ? '활성화됨' : '삭제됨' }}
                      </span>
                    </td>
                    <!--                    <td class="text-sm justify-center">-->
                    <!--                      <div class="form-check" style="margin-left: 75px">-->
                    <!--                        <input id="replyWriteAuthorityCheck" class="form-check-input" type="checkbox" :checked="row.writeAuthority" />-->
                    <!--                      </div>-->
                    <!--                    </td>-->
                  </template>
                </user-menu-table-row>
              </tbody>
            </template>
          </user-menu-table-container>
          <argon-pagination>
            <argon-pagination-item v-if="pageInfo.isFirst" :key="pageInfo.first" :active="false" :first="true" @click="getPageMenus(pageInfo.first)" />
            <argon-pagination-item v-if="pageInfo.isPrev" :key="pageInfo.prev" :active="false" :prev="true" @click="getPageMenus(pageInfo.prev)" />
            <argon-pagination-item v-for="i in pageInfo.pageItems" :key="i" :label="i.toString()" :active="i === pageInfo.current" @click="getPageMenus(i)" />
            <argon-pagination-item v-if="pageInfo.isNext" :key="pageInfo.next" :active="false" :next="true" @click="getPageMenus(pageInfo.next)" />
            <argon-pagination-item v-if="pageInfo.isLast" :key="pageInfo.last" :active="false" :last="true" @click="getPageMenus(pageInfo.last)" />
          </argon-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-utils-buttons {
  & > button + button {
    margin-left: 8px;
  }
}
</style>

<script>
import AuthorityTableHeader from '@/views/components/authority/AuthorityTableHeader';
import { OPTIONS, HEAD_CELLS } from '@/constants/menu/menuAuthority';
import { onMounted, ref } from 'vue';
import { getAllUserMenu } from '@/apis/menu';
import { UserAuthorityGroupType } from '@/constants/commons/menu';
import ArgonPagination from '@/components/ArgonPagination';
import ArgonPaginationItem from '@/components/ArgonPaginationItem';
import UserMenuTableRow from '@/views/components/authority/UserMenuTableRow';
import UserMenuTableContainer from '@/views/components/authority/UserMenuTableContainer';

const useMenus = (rows, pageInfo, condition, pagesSize) => {
  const searchPageMenus = async () => {
    const result = await getAllUserMenu(condition);
    rows.value = result.content;
    const { number, totalPages } = result;
    let size = pagesSize.value;

    const quotient = parseInt(number / size);

    const start = quotient * size + 1;
    const end = quotient * size + size > totalPages ? totalPages % size : size;

    pageInfo.value = {
      isFirst: quotient !== 0,
      isPrev: quotient !== 0,
      isNext: quotient * size + size < totalPages,
      isLast: quotient * size + size < totalPages,
      first: 1,
      last: totalPages,
      prev: quotient * size,
      next: (quotient + 1) * size + 1,
      current: number + 1,
      pageItems: Array.apply(0, Array(end)).map((element, index) => index + start),
    };
  };

  return { searchPageMenus };
};

export default {
  name: 'MenuAuthority',
  components: {
    ArgonPagination,
    ArgonPaginationItem,
    UserMenuTableContainer,
    AuthorityTableHeader,
    UserMenuTableRow,
  },
  setup() {
    const rows = ref([]);
    const pagesSize = ref(5);
    const pageInfo = ref({
      prev: 0,
      next: 0,
      first: 0,
      last: 0,
      isPrev: 0,
      isNext: 0,
      isFirst: 0,
      isLast: 0,
      current: 0,
      pageItems: [],
    });

    const condition = {
      size: 10,
      page: 0,
      type: UserAuthorityGroupType.AUTHORIZED_MEMBER,
    };

    const selectedOption = ref(UserAuthorityGroupType.AUTHORIZED_MEMBER);

    onMounted(async () => {
      await searchPageMenus();
    });

    const { searchPageMenus } = useMenus(rows, pageInfo, condition, pagesSize);

    const changeOption = () => {
      condition.type = selectedOption.value;
      condition.page = 0;
      searchPageMenus();
    };

    const getPageMenus = async (i) => {
      condition.page = i - 1;
      await searchPageMenus();
    };

    return {
      options: OPTIONS,
      headCells: HEAD_CELLS,
      rows,
      pagesSize,
      pageInfo,
      selectedOption,
      changeOption,
      getPageMenus,
    };
  },
};
</script>

<style scoped lang="scss">
.input-search {
  margin-left: 4px;
  margin-right: 5px;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-right: 1px solid #d2d6da !important;
}

.select-wrapper:deep .choices__item--choice {
  padding: 0.3rem 0.5rem;
}
</style>
