<template>
  <main-content-box>
    <template #main>
      <div class="mirror__header">
        <div class="mirror__input-wrapper">
          <contest-select-group :defaultSelectedInfo="contestItemSelectedId" :selectHandler="itemSelectHandler" :mode="EVENT"></contest-select-group>
        </div>
        <strong class="mirror__title">총 {{ data.length }}명</strong>
      </div>
      <div>
        <grid-view
          ref="gridRef"
          :columns="columns"
          :data="data"
          :column-options="columnOptions"
          :row-headers="rowHeaders"
          :updateRows="updateRows"
          :validationFailHandler="validationFailHandler"
        >
          <template #addBtn>
            <excel-export-btn
              :no-margin="true"
              :selected-event-id="contestItemSelectedId[EVENT]"
              file-name="번호 목록"
              :grid-ref="gridRef"
              :column-names="columnNames"
              :disabled="data.length === 0"
            />
            <v-btn class="ml-2" color="primary" :disabled="data.length === 0" @click="changeSeq">참가번호 초기화</v-btn>
          </template>
        </grid-view>
      </div>
    </template>
  </main-content-box>
</template>

<script>
import { ref } from 'vue';

import { bulkUpdatePlayMirror, getPlayerMirrorsByContestItemId, changePlayerMirrorsSeqByContestItemId } from '@/apis/playerMirror.js';

import MainContentBox from '@/components/MainContentBox.vue';
import ExcelExportBtn from '@/components/ExcelExportBtn';
import GridView from '@/views/components/common/GridView.vue';
import ContestSelectGroup from '@/views/components/contest/ContestSelectGroup.vue';

import { MIRROR_MANAGEMENT_HEADER, CONTEST, CLASSIFICATION, SECTOR, EVENT } from '@/constants/contest/gridHeader';
import { successToast, warningToast } from '@/utils/toastUtil';
import { PARTICIPATION_STATUS } from '@/constants/contest/mirror';

export default {
  name: 'MirrorManagement',
  components: { MainContentBox, ContestSelectGroup, GridView, ExcelExportBtn },
  setup() {
    const data = ref([]);
    const contestItemSelectedId = ref({
      [CONTEST]: '',
      [CLASSIFICATION]: '',
      [SECTOR]: '',
      [EVENT]: '',
    });

    const columns = MIRROR_MANAGEMENT_HEADER;
    const columnOptions = {
      resizable: true,
    };
    const rowHeaders = ['rowNum', 'checkbox'];
    const gridRef = ref({});
    const contestItemIdRef = ref(-1);

    const columnNames = columns.map((e) => e.name).filter((n) => n !== 'image' && n !== 'certificate');

    const itemSelectHandler = async (contestItemId, type, _, notification = true) => {
      if (type === EVENT) {
        const ret = await getPlayerMirrorsByContestItemId(contestItemId);
        contestItemIdRef.value = contestItemId;

        data.value = ret.map((v) => ({
          ...v,
          participationStatus: PARTICIPATION_STATUS[v.participationStatus],
        }));

        if (notification) {
          successToast('데이터 조회가 완료되었습니다.');
        }
      } else {
        data.value = [];
      }
    };

    const changeSeq = async () => {
      await changePlayerMirrorsSeqByContestItemId(contestItemIdRef.value);
      await itemSelectHandler(contestItemIdRef.value, EVENT, '', false);
      successToast('참가 번호 초기화가 완료되었습니다.');
    };

    const updateRows = async (updatedRows) => {
      const parsedUpdateData = updatedRows.map((v) => ({
        id: v.id,
        mirrorSequence: v.mirrorSequence ? v.mirrorSequence : Number(v.mirrorSequence),
        playerSequence: v.playerSequence,
        participationStatus: v.participationStatus ? PARTICIPATION_STATUS[v.participationStatus] : 'PARTICIPATING',
      }));
      await bulkUpdatePlayMirror(parsedUpdateData);
      await itemSelectHandler(contestItemIdRef.value, EVENT, '', false);
      return parsedUpdateData;
    };

    const validationFailHandler = () => {
      warningToast('거울 번호는 양수만 입력할 수 있습니다.');
    };

    return {
      EVENT,
      columns,
      columnNames,
      columnOptions,
      contestItemSelectedId,
      itemSelectHandler,
      updateRows,
      validationFailHandler,
      data,
      rowHeaders,
      gridRef,
      changeSeq,
    };
  },
};
</script>

<style lang="scss" scoped>
.mirror {
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
