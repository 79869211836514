import { defineStore } from 'pinia';

import { getBranchOfficeList } from '@/apis/branch';

export const useBranchStore = defineStore('branch', {
  state: () => ({
    branchInfos: {},
  }),
  getters: {
    branchInfoArray: (state) => {
      return Object.values(state.branchInfos).flat();
    },
  },
  actions: {
    async fetchBranchInfos() {
      const branchInfos = await getBranchOfficeList();

      this.branchInfos = branchInfos;
    },
  },
});
