import { useToast } from 'vue-toastification';

const toast = useToast();

export const showToastMessage = (e) => {
  toast.info(`[${e.code}] ${e.message}`);
};

export const successToast = (message) => {
  toast.success(message);
};

export const errorToast = (message) => {
  toast.error(message);
};

export const warningToast = (message, timeout = 3000) => {
  toast.warning(message, {
    toastClassName: 'text-dark',
    timeout,
  });
};

export const infoToast = (message) => {
  toast.info(message);
};
