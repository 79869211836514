<template>
  <div class="grid-view" :class="{ 'grid-view__editMode': isEditorMode }">
    <div class="grid-view__btn-wrapper">
      <template v-if="!readOnly">
        <div v-if="isEditorMode">
          <span class="mr-4">칸을 더블클릭하시면 수정이 가능합니다</span>
          <v-btn v-if="isSave" class="mr-4 grid-view__btn-success" @click="saveEditing">저장하기</v-btn>
          <v-btn v-if="isCancel" @click="cancelEditing">취소하기</v-btn>
        </div>
        <div v-else style="width: 100%; display: flex; justify-content: end">
          <div class="d-flex align-items-center">
            <slot name="description"></slot>
          </div>
          <slot name="addBtn"></slot>
          <v-btn v-if="!isEditHide" :disabled="data.length === 0" class="ml-4 grid-view__btn-success" @click="onClickEditing">수정하기</v-btn>
          <div v-if="deletable" class="d-flex">
            <v-btn class="ml-4 text-white" color="danger" @click="onClickDelete">{{ deleteBtnText }}</v-btn>
          </div>
        </div>
      </template>
    </div>
    <div v-if="title">
      <h5>{{ title }}</h5>
      <div v-if="description" class="grid-view__desc" style="margin-top: -10px; font-size: 15px">
        {{ description }}
      </div>
    </div>
    <div style="max-height: 1400px" class="mt-5 mb-5" ref="gridEl"></div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { DEFAULT_OPTIONS } from '@/constants/grid/gridHeader';
import TuiGrid from 'tui-grid';
import { successToast, infoToast, warningToast } from '@/utils/toastUtil';

import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

export default {
  name: 'GridView',
  props: {
    header: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    rowHeaders: {
      type: Array,
      default: () => ['rowNum'],
    },
    columnOptions: {
      type: Object,
      default: () => ({
        resizable: true,
      }),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    deleteBtnText: {
      type: String,
      default: '',
    },
    updateRows: {
      type: Function,
      default: () => {},
    },
    onClickPrint: {
      type: Function,
      default: () => {},
    },
    validationFailHandler: {
      type: Function,
      default: () => {},
    },
    onDoubleClick: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
    onMountGrid: {
      type: Function,
      default: () => {},
    },
    onChangePage: {
      type: Function,
      default: () => {},
    },
    deleteRows: {
      type: Function,
      default: () => {},
    },
    isEditHide: {
      type: Boolean,
      default: false,
    },
    isSave: {
      type: Boolean,
      default: true,
    },
    isCancel: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
      default: 0,
    },
    totalElements: {
      type: Number,
      default: 0,
    },
    pageOptions: {
      type: Object,
      default: () => ({}),
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 820,
    },
    defaultEditable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    let grid = null;
    const isEditorMode = ref(props.defaultEditable);
    const gridEl = ref();

    // const pageOptionsRef = toRef(props, 'pageOptions');
    const editableGridColumns = props.columns.map((c) => ({ ...DEFAULT_OPTIONS, ...c }));
    const readOnlyGridColumn = props.columns.map((c) => ({ ...DEFAULT_OPTIONS, ...c, editor: null, formatter: null }));

    const createGrid = (columns) => {
      grid = new TuiGrid({
        el: gridEl.value,
        header: props.header,
        data: props.data,
        columns: columns,
        rowHeaders: props.rowHeaders,
        options: props.options,
        columnOptions: props.columnOptions,
        rowHeight: 'auto',
        pageOptions: props.pageOptions,
      });

      TuiGrid.applyTheme('striped');
      TuiGrid.setLanguage('ko');
      TuiGrid.usageStatistics = false;
      grid.setBodyHeight(props.height);
      props.onMountGrid(grid);

      grid.on('dblclick', props.onDoubleClick);
      grid.on('beforePageMove', props.onChangePage);
      grid.on('afterChange', onChangeColumn);

      if (props.pagination) {
        grid.setPaginationTotalCount(props.totalElements);
      }
    };

    watch(
      () => props.data,
      () => {
        // grid.resetData(props.data);
        // if (props.pagination) {
        //   grid.setPaginationTotalCount(props.totalElements);
        // }
        grid.destroy();
        createGrid(isEditorMode.value ? editableGridColumns : readOnlyGridColumn);
      },
    );

    onMounted(() => {
      createGrid(readOnlyGridColumn);
      if (isEditorMode.value) {
        onClickEditing();
      }
    });

    const exportExcel = (options) => {
      grid.export('xlsx', options);
    };

    const restore = () => {
      grid.restore();
    };

    const changeReadMode = () => {
      grid.setColumns(readOnlyGridColumn);
    };

    const cancelEditing = () => {
      isEditorMode.value = false;
      grid.blur();
      restore();
      changeReadMode();
      props.onMountGrid(grid);
      infoToast('수정이 취소되었습니다.');
    };

    const parsedErrorInfos = (failedValidInfos) => {
      const errorColumns = failedValidInfos.map((row) => ({
        rowKey: row.rowKey,
        columns: row.errors.map((col) => grid.getIndexOfColumn(col.columnName)),
      }));

      return errorColumns;
    };

    const onChangeColumn = (ev) => {
      ev.changes.map((v) => {
        grid.addCellClassName(v.rowKey, v.columnName, 'grid-view__changed');
      });
    };

    const saveEditing = async () => {
      grid.blur();

      try {
        const validationInfos = grid.validate();

        if (validationInfos.length === 0) {
          const page = grid.getPagination()?._currentPage;
          const isUpdate = await props.updateRows(grid.getModifiedRows().updatedRows);

          if (isUpdate) {
            successToast('저장이 완료되었습니다.');
          }

          isEditorMode.value = false;
          changeReadMode();
          grid.getPagination()?.movePageTo(page);
        } else {
          const firstErrorcolIdx = grid.getIndexOfColumn(validationInfos[0].errors[0].columnName);
          const errorColumnNames = parsedErrorInfos(validationInfos);

          props.validationFailHandler(errorColumnNames);
          grid.focusAt(validationInfos[0].rowKey, firstErrorcolIdx);
        }
      } catch (e) {
        console.log(e);
        restore();
      }
    };

    const onClickEditing = () => {
      isEditorMode.value = true;
      grid.setColumns(editableGridColumns);
      props.onMountGrid(grid);
    };

    const printPDF = () => {
      props.onClickPrint([...grid.getModifiedRows().updatedRows, ...grid.getModifiedRows().createdRows]);
    };

    const addRow = (row) => {
      grid.appendRow(row);
    };

    const getCheckedRows = () => {
      return grid.getCheckedRows();
    };

    const onClickDelete = () => {
      const rowIds = grid.getCheckedRows().map((v) => v.id);

      if (rowIds.length === 0) {
        warningToast('삭제할 행을 선택해주세요');
        return;
      }

      props.deleteRows(rowIds);
    };

    return { printPDF, addRow, gridEl, isEditorMode, saveEditing, cancelEditing, onClickEditing, onClickDelete, exportExcel, getCheckedRows, reset: restore };
  },
};
</script>

<style lang="scss" scoped>
.grid-view {
  margin-top: 1rem;
  padding: 1rem;

  &__editMode {
    border-top: 8px solid #0070bc;
    transition: border 0.1s ease-in-out;
  }

  &__btn {
    &-wrapper {
      display: flex;
      justify-content: end;
    }

    &-success {
      background-color: #20c997;
      color: white;
      font-weight: 600;
    }
  }

  &__desc {
    color: blue;
  }
}

:deep(.grid-view__changed) {
  background-color: rgba(0, 112, 188, 0.2);
}

:deep(.tui-grid-cell) {
  padding: 0 !important;
}
</style>
