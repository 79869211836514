<template>
  <div ref="viewer"></div>
</template>

<script>
import { onMounted, ref } from 'vue';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';

export default {
  props: {
    content: {
      type: String,
      default: '게시글이 본문이 존재하지 않습니다.',
    },
  },
  setup() {
    const viewer = ref();
    const editorRef = ref();

    const updateBody = (body) => {
      const elements = document.getElementsByClassName('toastui-editor-contents');
      elements[0].innerHTML = body;
      editorRef.value.options.el = '';
    };

    onMounted(async () => {
      const e = new Viewer({
        el: viewer.value,
        viewer: true,
      });
      editorRef.value = e;
    });
    return { viewer, editorRef, updateBody };
  },
};
</script>

<style></style>
