<template>
  <div v-show="layout === 'default'" class="min-height-300 position-absolute w-100" :class="`${darkMode ? 'bg-trasnparent' : 'bg-success'}`" />

  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4"
    :class="` ${sidebarType} ${isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'} ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}`"
  >
    <div>
      <router-link class="m-0 navbar-brand" to="/dashboard">
        <img :src="kobaLogo" class="navbar-brand-img h-100" alt="koba_logo" style="margin-left: -10px" />
        <span class="ms-2 font-weight-bold">대한미용사회 Admin</span>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list />
  </aside>
</template>

<script>
import logo from '@/assets/img/logo-ct-dark.png';
import logoWhite from '@/assets/img/logo-ct.png';
import image from '@/assets/img/profile-layout-header.jpg';
import kobaLogo from '@/assets/img/logos/kobaLogo.svg';

import { mapState } from 'vuex';
import SidenavList from './SidenavList.vue';

export default {
  name: 'Index',
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoWhite,
      image,
      kobaLogo,
    };
  },
  computed: {
    ...mapState(['layout', 'isRTL', 'sidebarType', 'darkMode']),
  },
  beforeUnmount() {
    this.$store.state.image;
  },
};
</script>
