import { defineStore } from 'pinia';

export default defineStore('meta', {
  state: () => ({
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    color: '',
    sidebarType: 'bg-white',
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: 'default',
  }),
  getters: {},
  actions: {
    toggleSidebarColor(payload) {
      this.sidebarType = payload;
    },
    toggleConfigurator() {
      this.showConfig = !this.showConfig;
    },
    toggleDefaultLayout() {
      this.showNavbar = !this.showNavbar;
      this.showSidenav = !this.showSidenav;
      this.showFooter = !this.showFooter;
    },
    setSidebarType(payload) {
      this.sidebarType = payload;
    },
    navbarFixed() {
      if (this.isNavFixed === false) {
        this.isNavFixed = true;
      } else {
        this.isNavFixed = false;
      }
    },
    navbarMinimize() {
      const sidenavShow = document.querySelector('#app');

      if (sidenavShow.classList.contains('g-sidenav-show') && sidenavShow.classList.contains('g-sidenav-hidden')) {
        sidenavShow.classList.add('g-sidenav-pinned');
        sidenavShow.classList.remove('g-sidenav-hidden');
        this.isPinned = true;
      } else if (sidenavShow.classList.contains('g-sidenav-show') && sidenavShow.classList.contains('g-sidenav-pinned')) {
        sidenavShow.classList.add('g-sidenav-hidden');
        sidenavShow.classList.remove('g-sidenav-pinned');
        this.isPinned = false;
      } else if (sidenavShow.classList.contains('g-sidenav-show')) {
        sidenavShow.classList.add('g-sidenav-pinned');
        this.isPinned = true;
      }
    },
  },
});
