import { jsPDF } from 'jspdf';

export const prizesSaveToPdf = async (prizes) => {
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
  });

  const _font = await import('@/fonts/BareunBatang');

  doc.addFileToVFS('bareunBatang.ttf', _font.default);
  doc.addFont('bareunBatang.ttf', 'bareunBatang', 'normal');
  doc.setFont('bareunBatang');
  doc.setFontSize(20);

  prizes.map((prize, index) => {
    doc.text(prize.title, 46, 149);
    doc.text(prize.playerName, 46, 177);

    if (prize.sectionName) {
      doc.text(prize.sectionName, 46, 163);
    }

    if (index < prizes.length - 1) {
      doc.addPage();
    }
  });

  doc.save('koba-prize.pdf');

  return;
};
