<template>
  <div class="card-header pb-0">
    <h5 class="mb-0">{{ title }}</h5>
  </div>
</template>

<script>
export default {
  name: 'CardHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style></style>
