<template>
  <div class="branchSelectGroup__row">
    <dropdown label="지역" titleKey="label" valueKey="id" :list="regions" :selectedItem="regionName" :onChangeSelectedItem="onClickMainBranch" />
    <dropdown label="지회/지부" :list="branches" titleKey="branchName" valueKey="id" :selectedItem="branchName" :onChangeSelectedItem="onClickSubBranch" />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

import { useBranchStore } from '@/store/branch';
import Dropdown from '@/components/Dropdown.vue';

import { branchMappedKo } from '@/constants/category';

export default {
  components: { Dropdown },
  props: {
    branchOfficeId: {
      type: [String, Number],
      default: 0,
    },
    regionName: {
      type: String,
      default: '',
    },
    branchName: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const branchStore = useBranchStore();

    const branchInfos = ref({});
    const regions = Object.values(branchMappedKo);
    const branches = ref([]);

    const fetchBranchInfos = async () => {
      await branchStore.fetchBranchInfos();
      branchInfos.value = branchStore.branchInfos;
      const { region, id } = branchStore.branchInfoArray.find((v) => v.id == props.branchOfficeId) ?? {};

      if (region) {
        onClickMainBranch(region);
        onClickSubBranch(id);
      }
    };

    onMounted(() => {
      fetchBranchInfos();
    });

    const onClickMainBranch = (regionName) => {
      emit('update:regionName', regionName);
      emit('update:branchOfficeId', 0);
      branches.value = branchInfos.value[regionName];
    };

    const onClickSubBranch = (branchId) => {
      emit('update:branchOfficeId', branchId);
    };

    return {
      branchInfos,
      regions,
      branches,
      onClickMainBranch,
      onClickSubBranch,
    };
  },
};
</script>

<style lang="scss" scoped>
.branchSelectGroup {
  &__row {
    display: flex;

    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
}
</style>
