<template>
  <div class="py-4 container-fluid">
    <div class="card basic-card card-body">
      <v-icon class="icon-wrqpper" color="#0070bc">{{ mdiAlertCircleOutline }}</v-icon>

      <div class="text-wrapper">
        <div class="text-wrapper__title">페이지 준비중입니다.</div>
        <div class="text-wrapper__desc">
          현재 페이지를 준비하고 있으니 조금만 기다려주세요. <br />
          감사합니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js';

export default {
  setup() {
    return { mdiAlertCircleOutline };
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 2rem;
  height: 30rem;
}

.icon-wrqpper {
  margin-bottom: 1rem;
  font-size: 120px;
}

.text-wrapper {
  text-align: center;

  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
  }

  &__desc {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
