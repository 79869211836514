export const TABLE_CHECKBOX = 'table-checkbox';
export const ACTIONS_SHOW_POST = 'actions-showPost';
export const ACTIONS_DELETE_POST = 'actions-deletePost';
export const BoardGroup = {
  COMMITTEE: 'COMMITTEE',
  RULE: 'RULE',
  FORM: 'FORM',
  NOTICE: 'NOTICE',
  QUESTION: 'QUESTION',
  SEMINAR: 'SEMINAR',
  JOB: 'JOB',
  ORGANIZATION: 'ORGANIZATION',
  BRANCH: 'BRANCH',
};
