export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}

export function getAuthority() {
  const token = localStorage.getItem('authToken');
  if (token) {
    const userView = parseJwt(token);
    return userView?.authority;
  }
  return null;
}

export const getToken = () => {
  return localStorage.getItem('authToken');
};
