<template>
  <tr>
    <td v-if="showCheckbox">
      <div class="my-auto form-check check-box">
        <input type="checkbox" :id="replyId" class="form-check-input" :data-element-name="tableCheckbox" :data-reply-id="replyId" />
      </div>
    </td>
    <slot name="table-data" />
    <td v-if="showActions" class="text-sm">
      <button class="action_button mx-3" name="showPost" :data-element-name="actionsShowPost" :data-reply-id="replyId">
        <i class="fas fa-eye text-secondary" :data-element-name="actionsShowPost" :data-reply-id="replyId" />
      </button>
      <button class="action_button" name="deletePost" :data-element-name="actionsDeletePost" :data-reply-id="replyId">
        <i class="fas fa-trash text-secondary" :data-element-name="actionsDeletePost" :data-reply-id="replyId" />
      </button>
    </td>
  </tr>
</template>

<style scoped lang="scss">
.check-box {
  margin-left: 20px;
}

.action_button {
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  border-radius: 20px;

  &:hover {
    background: aliceblue;
  }
}
</style>

<script>
import { ACTIONS_DELETE_REPLY, ACTIONS_SHOW_REPLY, TABLE_CHECKBOX } from '@/constants/community/reply';

export default {
  name: 'ReplyTableRow',
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    replyId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      tableCheckbox: TABLE_CHECKBOX,
      actionsShowPost: ACTIONS_SHOW_REPLY,
      actionsDeletePost: ACTIONS_DELETE_REPLY,
    };
  },
};
</script>
