<template>
  <main-content-box>
    <template #main>
      <div class="searchDateWrapper">
        <basic-date-picker
          label="조회 년/월"
          :date="searchDate"
          @update:date="searchDate = $event"
          placeholder="조회 년/월을 선택해주세요"
          required
          monthPicker
        />
      </div>
      <grid-view
        :columns="columns"
        :data="data"
        :column-options="columnOptions"
        :row-headers="rowHeaders"
        :updateRows="updateSchedule"
        :deleteRows="deleteSchedulesHandler"
        deleteBtnText="일정 삭제"
        deletable
      >
        <template #addBtn>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ props }">
              <v-btn class="mr-4" color="primary" v-bind="props"> 추가하기 </v-btn>
            </template>
            <v-card class="dialog">
              <v-card-title>
                <span class="text-h5">일정 만들기</span>
              </v-card-title>
              <v-card-text>
                <basic-input
                  id="title"
                  label="일정명"
                  placeholder="일정명을 입력해주세요"
                  :text-value="schduleInfo.title"
                  required
                  @update:textValue="schduleInfo.title = $event"
                />
                <basic-input
                  id="description"
                  label="일정요약"
                  placeholder="일정요약을 입력해주세요"
                  :text-value="schduleInfo.description"
                  required
                  @update:textValue="schduleInfo.description = $event"
                />
                <basic-date-picker
                  label="일정 시작일"
                  :date="schduleInfo.startDate"
                  @update:date="schduleInfo.startDate = $event"
                  placeholder="일정 시작일을 선택해주세요"
                  onlyDate
                  required
                />
                <basic-date-picker
                  label="일정 종료일"
                  :date="schduleInfo.endDate"
                  @update:date="schduleInfo.endDate = $event"
                  placeholder="일정 종료일을 선택해주세요"
                  onlyDate
                  required
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog"> 닫기 </v-btn>
                <v-progress-circular v-if="isCreateLoading" indeterminate color="primary"></v-progress-circular>
                <v-btn v-else color="blue-darken-1" text @click="createScheduleHandler"> 생성 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </grid-view>
    </template>
  </main-content-box>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

import { createSchedule, getSchedules, updateSchedulesBulk, deleteSchedules } from '@/apis/schedule';

import GridView from '@/views/components/common/GridView';
import { SCHEDULE_MANAGEMENT_HEADER } from '@/constants/schedule';
import MainContentBox from '@/components/MainContentBox.vue';
import BasicInput from '@/components/BasicInput.vue';
import BasicDatePicker from '@/components/BasicDatePicker.vue';

import { successToast, warningToast } from '@/utils/toastUtil';
import { toYYYYMMDD } from '@/utils/dateUtil';

/**
 * 일정관리 페이지
 */
export default {
  name: 'ScheduleManagement',
  components: { GridView, MainContentBox, BasicDatePicker, BasicInput },
  setup() {
    const dialog = ref(false);
    const isCreateLoading = ref(false);
    const isDataLoading = ref(false);
    const searchDate = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });
    const schduleInfo = ref({
      title: '',
      description: '',
      startDate: new Date(),
      endDate: new Date(),
    });

    const columns = SCHEDULE_MANAGEMENT_HEADER;
    const columnOptions = {
      resizable: true,
    };
    const data = ref([]);
    const rowHeaders = ['rowNum', 'checkbox'];

    watch(
      () => searchDate.value,
      (next) => {
        searchDate.value.year = next.year;
        searchDate.value.month = next.month;
        fetchData();
      },
    );

    watch(
      () => dialog.value,
      (next) => {
        if (!next) {
          schduleInfo.value = {
            title: '',
            description: '',
            startDate: new Date(),
            endDate: new Date(),
          };
        }
      },
    );

    const closeDialog = () => {
      isCreateLoading.value = false;
      dialog.value = false;
    };

    const fetchData = async () => {
      isDataLoading.value = true;
      const year = searchDate.value.year;
      const month = searchDate.value.month + 1;

      const schdules = await getSchedules(year, month);
      data.value = schdules;

      isDataLoading.value = false;

      successToast(`${year}년 ${month}월 일정 조회에 성공했습니다.`);
    };

    const createScheduleHandler = async () => {
      if (schduleInfo.value.title === '') {
        warningToast('일정명을 입력해주세요');
        return;
      }

      if (new Date(schduleInfo.value.startDate).getTime() > new Date(schduleInfo.value.endDate).getTime()) {
        warningToast('일정 종료일이 시작일보다 빠를 수 없습니다', 5000);
        return;
      }

      isCreateLoading.value = true;

      try {
        const body = {
          title: schduleInfo.value.title,
          description: schduleInfo.value.description,
          startDate: toYYYYMMDD(schduleInfo.value.startDate),
          endDate: toYYYYMMDD(schduleInfo.value.endDate),
        };

        await createSchedule(body);
        await fetchData();
      } catch (e) {
        closeDialog();
      }

      successToast('일정 생성에 성공했습니다.');
      closeDialog();
    };

    const updateSchedule = async (dataArr) => {
      const parsedData = dataArr.map((v) => ({
        id: v.id,
        title: v.title,
        startDate: new Date(v.startDate),
        endDate: new Date(v.endDate),
        description: v.description,
      }));
      await updateSchedulesBulk(parsedData);
      await fetchData();

      return true;
    };

    onMounted(() => {
      fetchData();
    });

    const deleteSchedulesHandler = async (ids) => {
      await deleteSchedules(ids);
      await fetchData();
      successToast('삭제에 성공했습니다.');
    };

    return {
      dialog,
      isCreateLoading,
      searchDate,
      closeDialog,
      createScheduleHandler,
      updateSchedule,
      deleteSchedulesHandler,
      schduleInfo,
      columns,
      data,
      columnOptions,
      rowHeaders,
    };
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  width: 40rem;
}

.searchDateWrapper {
  width: fit-content;
}
</style>
