import { createRouter, createWebHistory } from 'vue-router';
import Landing from '../views/dashboards/Landing.vue';
import Default from '../views/dashboards/Default.vue';
import Automotive from '../views/dashboards/Automotive.vue';
import SmartHome from '../views/dashboards/SmartHome.vue';
import VRDefault from '../views/dashboards/vr/VRDefault.vue';
import VRInfo from '../views/dashboards/vr/VRInfo.vue';
import CRM from '../views/dashboards/CRM.vue';
import Overview from '../views/pages/profile/Overview.vue';
import Teams from '../views/pages/profile/Teams.vue';
import Projects from '../views/pages/profile/Projects.vue';
import General from '../views/pages/projects/General.vue';
import Timeline from '../views/pages/projects/Timeline.vue';
import NewProject from '../views/pages/projects/NewProject.vue';
import Pricing from '../views/pages/Pricing.vue';
import RTL from '../views/pages/Rtl.vue';
import Charts from '../views/pages/Charts.vue';
import SweetAlerts from '../views/pages/SweetAlerts.vue';
import Notifications from '../views/pages/Notifications.vue';
import Kanban from '../views/applications/Kanban.vue';
import Wizard from '../views/applications/wizard/Wizard.vue';
import DataTables from '../views/applications/DataTables.vue';
import Calendar from '../views/applications/Calendar.vue';
import Analytics from '../views/applications/analytics/Analytics.vue';
import EcommerceOverview from '../views/ecommerce/overview/Overview.vue';
import NewProduct from '../views/ecommerce/products/NewProduct.vue';
import EditProduct from '../views/ecommerce/EditProduct.vue';
import ProductPage from '../views/ecommerce/ProductPage.vue';
import ProductsList from '../views/ecommerce/ProductsList.vue';
import OrderDetails from '../views/ecommerce/Orders/OrderDetails.vue';
import OrderList from '../views/ecommerce/Orders/OrderList.vue';
import Referral from '../views/ecommerce/Referral.vue';
import Reports from '../views/pages/Users/Reports.vue';
import NewUser from '../views/pages/Users/NewUser.vue';
import Settings from '../views/pages/Account/Settings.vue';
import Billing from '../views/pages/Account/Billing.vue';
import Invoice from '../views/pages/Account/Invoice.vue';
import Security from '../views/pages/Account/Security.vue';
import Widgets from '../views/pages/Widgets.vue';
import Illustration from '../views/auth/signin/login.vue';
import ResetBasic from '../views/auth/reset/Basic.vue';
import ResetCover from '../views/auth/reset/Cover.vue';
import ResetIllustration from '../views/auth/reset/Illustration.vue';
import VerificationBasic from '../views/auth/verification/Basic.vue';
import VerificationCover from '../views/auth/verification/Cover.vue';
import VerificationIllustration from '../views/auth/verification/Illustration.vue';
import SignupBasic from '../views/auth/signup/Basic.vue';
import SignupCover from '../views/auth/signup/Cover.vue';
import SignupIllustration from '../views/auth/signup/Illustration.vue';
import Error404 from '../views/auth/error/Error404.vue';
import Error500 from '../views/auth/error/Error500.vue';
import lockBasic from '../views/auth/lock/Basic.vue';
import lockCover from '../views/auth/lock/Cover.vue';
import lockIllustration from '../views/auth/lock/Illustration.vue';
import CommonBoard from '@/views/pages/CommonBoard';
import { BoardGroup } from '@/constants/community/board';
import { RouterKey } from '@/constants/routerKey';

// incognita views
import Dashboard from '@/views/Dashboard.vue';
import PostDetail from '@/views/pages/community/PostDetail.vue';
import ArticleDetail from '@/views/pages/news/ArticleDetail.vue';
import NewsArticleList from '@/views/pages/news/NewsArticleList.vue';
import ArticleUpdate from '@/views/pages/news/ArticleUpdate.vue';
import MenuAuthority from '@/views/pages/authority/MenuAuthority.vue';
import ArticleCreate from '@/views/pages/news/ArticleCreate.vue';

import ImageViewer from '@/views/components/common/ImageViewer';
import PreparePageCard from '@/components/PreparePageCard.vue';
import NotFoundPage from '@/components/NotFoundPage.vue';
import ContestManagement from '@/views/pages/contest/ContestManagement';
import ContestantList from '@/views/pages/contest/ContestantList';
import PrintPrize from '@/views/pages/contest/PrintPrize';
import PrizeManagement from '@/views/pages/contest/PrizeManagement';
import ContestantRegist from '@/views/pages/contest/ContestantRegist';
import MirrorManagement from '@/views/pages/contest/MirrorManagement';
import ContestItemManagement from '@/views/pages/contest/ContestItemManagement';
import ScoreManagement from '@/views/pages/contest/ScoreManagement';
import WinnerManagement from '@/views/pages/contest/WinnerManagement';
import Statistics from '@/views/pages/contest/Statistics';
import ScheduleManagement from '@/views/pages/ScheduleManagement';
import UserManagement from '@/views/pages/UserManagement';
import PushHistory from '@/views/pages/push/PushHistory';
import PushManage from '@/views/pages/push/PushManage';

const newRoutes = [
  {
    path: RouterKey.login,
    name: 'Signin',
    component: Illustration,
  },
  {
    path: '/',
    name: '',
    redirect: RouterKey.login,
    component: Dashboard,
  },
  {
    path: '/dashboard',
    name: '대시보드',
    component: Dashboard,
  },
  {
    path: '/user/list',
    name: '회원 목록',
    component: PreparePageCard,
  },
  {
    path: '/authority/management/menu',
    name: '홈페이지 메뉴 권한관리',
    component: MenuAuthority,
  },
  {
    path: '/news/management/:newsType',
    name: '뉴스 관리',
    component: NewsArticleList,
  },
  {
    path: '/article-detail/:articleId',
    name: '뉴스 기사 상세보기',
    component: ArticleDetail,
    props: true,
  },
  {
    path: '/article-update/:articleId',
    name: '뉴스 기사 수정하기',
    component: ArticleUpdate,
    props: true,
  },
  {
    path: '/article-create/:newsId',
    name: '뉴스 기사 생성하기',
    component: ArticleCreate,
    props: true,
  },
  {
    path: '/community/management/rule',
    name: '규정 관리 관리',
    component: CommonBoard,
    props: {
      tableId: 'rule-management',
      boardGroup: BoardGroup.RULE,
      title: '규정 관리',
      description: '규정 게시판을 관리하는 페이지 입니다.',
    },
  },
  {
    path: '/community/management/template',
    name: '협회 양식 관리',
    component: CommonBoard,
    props: {
      tableId: 'template-board',
      boardGroup: BoardGroup.FORM,
      title: '협회 양식',
      description: '협회 양식 게시판을 관리하는 페이지 입니다.',
    },
  },
  {
    path: '/community/management/notice',
    name: '공지사항 관리',
    component: CommonBoard,
    props: {
      tableId: 'notice-management',
      boardGroup: BoardGroup.NOTICE,
      title: '공지사항',
      description: '공지사항 게시판을 관리하는 페이지 입니다.',
    },
  },
  {
    path: '/community/management/qna',
    name: 'Q&A',
    component: CommonBoard,
    props: {
      tableId: 'qna-management',
      boardGroup: BoardGroup.QUESTION,
      title: 'Q&A',
      description: 'Q&A 게시판을 관리하는 페이지 입니다.',
    },
  },
  {
    path: '/community/management/seminar',
    name: '세미나 일정 관리',
    component: CommonBoard,
    props: {
      tableId: 'seminar-management',
      boardGroup: BoardGroup.SEMINAR,
      title: '세미나 일정',
      description: '세미나 일정 게시판을 관리하는 페이지 입니다.',
    },
  },
  {
    path: '/community/management/job-opening',
    name: '구인 매매 관리',
    component: CommonBoard,
    props: {
      tableId: 'job-opening-management',
      boardGroup: BoardGroup.JOB,
      title: '구인 매매',
      description: '구인 매매 게시판을 관리하는 페이지 입니다.',
    },
  },
  {
    path: '/community/management/group',
    name: '관련 단체',
    component: CommonBoard,
    props: {
      tableId: 'job-opening-management',
      boardGroup: BoardGroup.ORGANIZATION,
      title: '관련 단체',
      description: '관련 단체 게시판을 관리하는 페이지 입니다.',
    },
  },
  {
    path: '/community/post-detail/:postId',
    name: '게시판 상세보기',
    component: PostDetail,
    props: true,
  },
  {
    path: '/community/management/committee-board',
    name: '위원회 전용 게시판 관리',
    component: CommonBoard,
    props: {
      tableId: 'committee-board',
      boardGroup: BoardGroup.COMMITTEE,
      title: '위원회 전용 게시판 관리',
      description: '위원회 전용 게시글을 복구하거나 삭제하는 페이지 입니다.',
    },
  },
  {
    path: '/community/management/branch-board',
    name: '지부별 전용 게시판 관리',
    component: CommonBoard,
    props: {
      tableId: 'branch-board',
      boardGroup: BoardGroup.BRANCH,
      title: '지부별 전용 게시판 관리',
      description: '지부별 전용 게시글을 복구하거나 삭제하는 페이지 입니다.',
    },
  },
  {
    path: '/contest/management',
    name: '대회 관리',
    component: ContestManagement,
  },
  {
    path: '/contest/prize-management',
    name: '수상명관리',
    component: PrizeManagement,
  },
  {
    path: '/contest/type-management',
    name: '종목관리',
    component: ContestItemManagement,
  },
  {
    path: '/contest/list-contestant',
    name: '선수목록',
    component: ContestantList,
  },
  {
    path: '/contest/contestant-regist',
    name: '선수등록',
    component: ContestantRegist,
  },
  {
    path: '/contest/mirror-management',
    name: '거울번호관리',
    component: MirrorManagement,
  },
  {
    path: '/contest/score-management',
    name: '점수관리',
    component: ScoreManagement,
  },
  {
    path: '/contest/winner-management',
    name: '수상자관리',
    component: WinnerManagement,
  },
  {
    path: '/contest/print-prize',
    name: '수상임의출력',
    component: PrintPrize,
  },
  {
    path: '/contest/statistics',
    name: '통계',
    component: Statistics,
  },
  {
    path: '/static/image/:url',
    name: '',
    component: ImageViewer,
  },
  {
    path: '/schedule',
    name: '일정 관리',
    component: ScheduleManagement,
  },
  {
    path: '/user-management',
    name: '사용자 관리',
    component: UserManagement,
  },
  {
    path: '/push/management/send',
    name: '푸시 발송 관리',
    component: PushManage,
    props: {
      title: '푸시 발송 관리',
      description: '푸시 발송을 관리하는 페이지 입니다.',
    },
  },
  {
    path: '/push/management/history',
    name: '푸시 히스토리 조회',
    component: PushHistory,
    props: {
      title: '푸시 히스토리 조회',
      description: '푸시 히스토리를 조회하는 페이지 입니다.',
    },
  },

  // {
  //   path: '/dashboards/job-board',
  //   name: '구인구직 게시판 관리',
  //   component: { ...CommonBoard },
  //   props: {
  //     tableId: 'job-board',
  //     boardGroup: BoardGroup.JOB,
  //     title: '구인구직 게시판 관리',
  //     description: '구인구직 게시글을 복구하거나 삭제하는 페이지 입니다.',
  //   },
  // },
  // {
  //   path: '/dashboards/notice-board',
  //   name: '공지사항 게시판 관리',
  //   component: { ...CommonBoard },
  //   props: {
  //     tableId: 'notice-board',
  //     boardGroup: BoardGroup.NOTICE,
  //     title: '공지사항 게시판 관리',
  //     description: '공지사항 게시글을 복구하거나 삭제하는 페이지 입니다.',
  //   },
  // },
  // {
  //   path: '/community/management/rule',
  //   name: '규정 관리',
  //   component: { ...CommonBoard },
  //   props: {
  //     tableId: 'rule-board',
  //     boardGroup: BoardGroup.RULE,
  //     title: '규정 관리',
  //     description: '규정을 복구하거나 삭제하는 페이지 입니다.',
  //   },
  // },
];

const oldRoutes = [
  // {
  //   path: '/',
  //   name: '/',
  //   redirect: RouterKey.login,
  // },
  // {
  //   path: '/dashboard',
  //   name: '대시보드',
  //   component: Dashboard,
  // },
  {
    path: '/dashboards/dashboard-default',
    name: 'Default',
    component: Default,
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboards/landing',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/dashboards/automotive',
    name: 'Automotive',
    component: Automotive,
  },
  {
    path: '/dashboards/smart-home',
    name: 'Smart Home',
    component: SmartHome,
  },
  {
    path: '/dashboards/vr/vr-default',
    name: 'VR Default',
    component: VRDefault,
  },
  {
    path: '/dashboards/vr/vr-info',
    name: 'VR Info',
    component: VRInfo,
  },
  {
    path: '/dashboards/crm',
    name: 'CRM',
    component: CRM,
  },

  {
    path: '/pages/profile/overview',
    name: 'Profile Overview',
    component: Overview,
  },
  {
    path: '/pages/profile/teams',
    name: 'Teams',
    component: Teams,
  },
  {
    path: '/pages/profile/projects',
    name: 'All Projects',
    component: Projects,
  },
  {
    path: '/pages/projects/general',
    name: 'General',
    component: General,
  },
  {
    path: '/pages/projects/timeline',
    name: 'Timeline',
    component: Timeline,
  },
  {
    path: '/pages/projects/new-project',
    name: 'New Project',
    component: NewProject,
  },
  {
    path: '/pages/pricing-page',
    name: 'Pricing Page',
    component: Pricing,
  },
  {
    path: '/pages/rtl-page',
    name: 'RTL',
    component: RTL,
  },
  {
    path: '/pages/charts',
    name: 'Charts',
    component: Charts,
  },
  {
    path: '/pages/widgets',
    name: 'Widgets',
    component: Widgets,
  },
  {
    path: '/pages/sweet-alerts',
    name: 'Sweet Alerts',
    component: SweetAlerts,
  },
  {
    path: '/pages/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/applications/kanban',
    name: 'Kanban',
    component: Kanban,
  },
  {
    path: '/applications/wizard',
    name: 'Wizard',
    component: Wizard,
  },
  {
    path: '/applications/data-tables',
    name: 'Data Tables',
    component: DataTables,
  },
  {
    path: '/applications/calendar',
    name: 'Calendar',
    component: Calendar,
  },
  {
    path: '/applications/analytics',
    name: 'Analytics',
    component: Analytics,
  },
  {
    path: '/ecommerce/overview',
    name: 'Overview',
    component: EcommerceOverview,
  },
  {
    path: '/ecommerce/products/new-product',
    name: 'New Product',
    component: NewProduct,
  },
  {
    path: '/ecommerce/products/edit-product',
    name: 'Edit Product',
    component: EditProduct,
  },
  {
    path: '/ecommerce/products/product-page',
    name: 'Product Page',
    component: ProductPage,
  },
  {
    path: '/ecommerce/products/products-list',
    name: 'Products List',
    component: ProductsList,
  },
  {
    path: '/ecommerce/Orders/order-details',
    name: 'Order Details',
    component: OrderDetails,
  },
  {
    path: '/ecommerce/Orders/order-list',
    name: 'Order List',
    component: OrderList,
  },
  {
    path: '/ecommerce/referral',
    name: 'Referral',
    component: Referral,
  },
  {
    path: '/pages/users/reports',
    name: 'Reports',
    component: Reports,
  },
  {
    path: '/pages/users/new-user',
    name: 'New User',
    component: NewUser,
  },
  {
    path: '/pages/account/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/pages/account/billing',
    name: 'Billing',
    component: Billing,
  },
  {
    path: '/pages/account/invoice',
    name: 'Invoice',
    component: Invoice,
  },
  {
    path: '/pages/account/Security',
    name: 'Security',
    component: Security,
  },
  {
    path: '/authentication/reset/basic',
    name: 'Reset Basic',
    component: ResetBasic,
  },
  {
    path: '/authentication/reset/cover',
    name: 'Reset Cover',
    component: ResetCover,
  },
  {
    path: '/authentication/reset/illustration',
    name: 'Reset Illustration',
    component: ResetIllustration,
  },
  {
    path: '/authentication/lock/basic',
    name: 'Lock Basic',
    component: lockBasic,
  },
  {
    path: '/authentication/lock/cover',
    name: 'Lock Cover',
    component: lockCover,
  },
  {
    path: '/authentication/lock/illustration',
    name: 'Lock Illustration',
    component: lockIllustration,
  },
  {
    path: '/authentication/verification/basic',
    name: 'Verification Basic',
    component: VerificationBasic,
  },
  {
    path: '/authentication/verification/cover',
    name: 'Verification Cover',
    component: VerificationCover,
  },
  {
    path: '/authentication/verification/illustration',
    name: 'Verification Illustration',
    component: VerificationIllustration,
  },
  {
    path: '/authentication/signup/basic',
    name: 'Signup Basic',
    component: SignupBasic,
  },
  {
    path: '/authentication/signup/cover',
    name: 'Signup Cover',
    component: SignupCover,
  },
  {
    path: '/authentication/signup/illustration',
    name: 'Signup Illustration',
    component: SignupIllustration,
  },
  {
    path: '/authentication/error/error404',
    name: 'Error Error404',
    component: Error404,
  },
  {
    path: '/authentication/error/error500',
    name: 'Error Error500',
    component: Error500,
  },
  {
    path: '/404',
    name: '페이지를 찾을 수 없습니다',
    component: NotFoundPage,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: newRoutes.concat(oldRoutes),
  linkActiveClass: 'active',
});

export default router;
