/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall
be included in all copies or substantial portions of the Software.
*/

import { createApp, markRaw } from 'vue';
import VueTilt from 'vue-tilt.js';
import VueSweetalert2 from 'vue-sweetalert2';
import mitt from 'mitt';
import { createPinia } from 'pinia';
import Toast, { POSITION } from 'vue-toastification';

import App from './App.vue';
import router from './router';
import store from './store';
import ArgonDashboard from './argon-dashboard';

import vuetify from '@/plugins/vuetify';
import { useAuthStore } from '@/store/auth';
import { setupWorker } from 'msw';
import { handlers } from '@/mocks/handlers';

import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import 'vue-toastification/dist/index.css';
import 'tui-grid/dist/tui-grid.css';
import 'tui-date-picker/dist/tui-date-picker.css'; // use grid datepicker
import 'tui-pagination/dist/tui-pagination.css'; // use grid pagination
import '@vuepic/vue-datepicker/dist/main.css'; // use component datepicker

const emitter = mitt();
if (process.env.VUE_APP_MOCK) {
  const worker = setupWorker(...handlers);
  worker.start();
}

const appInstance = createApp(App);
const pinia = createPinia();

router.beforeEach((to) => {
  const store = useAuthStore();

  if (to.meta.requiresAuth && !store.token) return '/authentication/signin/basic?redirect=1';
});

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

appInstance.use(pinia);
appInstance.use(router);
appInstance.use(store);
appInstance.use(VueTilt);
appInstance.use(vuetify);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(Toast, {
  position: POSITION.TOP_RIGHT,
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  newestOnTop: true,
  maxToasts: 2,
});

appInstance.config.globalProperties.emitter = emitter;
appInstance.mount('#app');
