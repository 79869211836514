<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky bg-white left-auto top-2 z-index-sticky'
    }`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="'me-sm-4'">
        <div class="pe-md-3 d-flex align-items-center" :class="'ms-md-auto'"></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <div @click="showLogoutDialog" class="px-0 nav-link font-weight-bold" :class="isNavFixed && 'opacity-8 text-dark'">
              <div class="d-flex flex-row align-center" v-if="!!loginId">
                <!--                <i class="fa fa-user" :class="'me-sm-1'"></i>-->
                <span class="flex-column d-flex ml-2">
                  <span class="align-self-end text-grey-darken-1">{{ branchOfficeName }} ({{ authorityName }})</span>
                  <span class="align-self-end d-sm-inline">{{ loginId }}님 환영합니다</span>
                </span>
              </div>
              <span v-else class="d-sm-inline d-none">로그인</span>
            </div>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a id="iconNavbarSidenav" href="#" class="bento p-0 nav-link text-white" @click.prevent="navbarMinimize">
              <div class="sidenav-toggler-inner">
                <i :class="`sidenav-toggler-line ${isNavFixed && 'opacity-8 bg-dark'}`"></i>
                <i :class="`sidenav-toggler-line ${isNavFixed && 'opacity-8 bg-dark'}`"></i>
                <i :class="`sidenav-toggler-line ${isNavFixed && 'opacity-8 bg-dark'}`"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import useMetaStore from '@/store/meta';
import { useAuthStore } from '@/store/auth';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'Navbar',
  components: {
    Breadcrumbs,
  },
  setup() {
    const { isNavFixed, darkMode, navbarMinimize, toggleConfigurator, toggleSidebarColor } = useMetaStore();
    const { loginId, branchOfficeName, authorityName } = useAuthStore();
    const router = useRouter();
    const { logout } = useAuthStore();

    const moveToLogin = () => {
      logout();
      router.replace({ path: '/login' });
    };
    const showLogoutDialog = () => {
      const result = confirm('로그아웃 하시겠습니까?');
      if (result) {
        moveToLogin();
      }
    };

    return {
      loginId,
      isNavFixed,
      darkMode,
      navbarMinimize,
      toggleConfigurator,
      toggleSidebarColor,
      branchOfficeName,
      authorityName,
      moveToLogin,
      showLogoutDialog,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      const dir = this.$route.path.split('/')[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
  },
};
</script>
