<template>
  <div class="form-group">
    <label :for="id">
      <slot />
    </label>
    <textarea :id="id" class="form-control" :rows="rows" :placeholder="placeholder" :value="body"></textarea>
  </div>
</template>

<script>
export default {
  name: 'ArgonTextarea',
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 5,
    },
    body: {
      type: String,
      required: true,
    },
  },
};
</script>
