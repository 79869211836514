import tinymce from 'tinymce';

/**
 *
 * @param {any} ed
 * @param {string} name
 * @param {string} cls - 클래스명
 * @param {[string]} rmCls - 지울 클래스명
 * @param {{tooltip?: string; text: string}} spec
 *
 * @description
 * spec - tooltip, text, icon 등
 */
const addToggleClassButton = (ed, cls, rmCls, name, spec) => {
  ed.ui.registry.addButton(name, {
    onAction: () => {
      const element = tinymce.activeEditor.selection.getNode();

      const $activeDom = tinymce.activeEditor.dom;

      rmCls.forEach((className) => {
        $activeDom.removeClass(element, className);
      });

      const hasClass = $activeDom.hasClass(element, cls);
      $activeDom.toggleClass(element, cls, !hasClass);
    },
    ...spec,
  });
};

export default addToggleClassButton;
