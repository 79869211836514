<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <article-update-header>
          <template #header-utils>
            <div class="header-utils-buttons">
              <!--              <button class="mb-0 btn btn-outline-danger btn-sm" @click="removeArticle(articleId)">삭제하기</button>-->
            </div>
          </template>
        </article-update-header>
        <div class="card-body pt-0">
          <Form as="v-form" :validation-schema="schema" @submit="onSubmit">
            <div class="autocomplete__deep-wrapper">
              <card-header title="* 분류 선택" class="mb-2 p-0" />
              <v-select disabled :menu-props="{ maxHeight: 300 }" label="기사 수정시에는 분류 변경이 불가합니다." />
            </div>

            <card-header title="* 기사 제목" class="mb-2 p-0" />
            <text-field-with-validation name="title" type="text" v-model="title" label="기사 제목" />

            <card-header title="* 기사 본문 요약" class="mb-2 ps-1" />
            <text-area-with-validation name="summary" label="기사 본문 요약" v-model="summary" rules=""></text-area-with-validation>

            <card-header title="* 기사 본문" class="mb-2 ps-1" />
            <article-publishing-editor
              ref="articleEditor"
              :update-content="updateContent"
              selector="publishing-editor"
              :changeEditorState="changeEditorState"
            />

            <card-header title="* 기자 선택" class="mb-2 ps-1" />
            <div class="mb-2 select-wrapper">
              <select class="form-select" aria-label="기자 선택" v-model="selectedOption">
                <option v-for="option of options" :key="option.id" :value="option.id">{{ option.reporterName }}</option>
              </select>
            </div>

            <card-header title="썸네일 선택" class="mb-2 ps-1" />
            <p class="text__caption-xsmall ps-1 pa-1" style="background: #f7f7f7; border-radius: 8px">썸네일을 첨부하지 않으면 기존 썸네일이 유지됩니다.</p>
            <form action="/file-upload" class="form-control dropzone mb-4" id="dropzone" v-on:change="onFileChange">
              <div class="fallback">
                <input name="file" type="file" />
              </div>
            </form>
            <button class="submit-btn btn btn-success" @click.prevent="onSubmit">저장 하기</button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteArticle, getArticle, updateArticle } from '@/apis/article';
import { inject, ref, onMounted } from 'vue';
import moment from 'moment';
import ArticleUpdateHeader from '@/views/components/news/articleCard/ArticleUpdateHeader';
import ArticlePublishingEditor from '@/views/components/news/articleCard/ArticlePublishingEditor';
import { Form } from 'vee-validate';
import CardHeader from '@/components/Card/CardHeader';
import { useRouter } from 'vue-router';
import { getAllReporter } from '@/apis/reporter';
import TextFieldWithValidation from '@/views/components/common/TextFieldWithValidation';
import TextAreaWithValidation from '@/views/components/common/TextAreaWithValidation';
import { number, object, string } from 'yup';
import tinymce from 'tinymce';
import { NEWS_IMG_BASE_URL } from '@/constants/news/news';

const schema = object({
  title: string().required('제목을 입력해주세요.').label('기사 제목'),
  summary: string().required('본문 요약을 입력해주세요.').label('기사 본문 요악'),
  content: string().required('기사 본문을 입력해주세요.').label('기사 본문'),
  reporter: number().required('보도 기자를 추가해주세요.').label('보도 기자'),
});

const useArticleDetail = (article, reporter, title, summary) => {
  const swal = inject('$swal');
  const getArticleDetail = async (articleId) => {
    const result = await getArticle(articleId);
    article.value = {
      ...result,
      createdDate: moment(result.createdDate).format('YYYY년 MM월 DD일 HH:mm'),
      modifiedDate: result.modifiedDate ? moment(result.modifiedDate).format('YYYY년 MM월 DD일 HH:mm') : '',
    };
    title.value = result.title;
    summary.value = result.bodySummary;
    reporter.value = {
      ...result.reporterAdminView,
    };
  };
  const removeArticle = async (articleId) => {
    await swal.fire('삭제하시겠습니까?');
    await deleteArticle(articleId);
  };
  const getReporters = () => {
    return getAllReporter();
  };
  return { getArticleDetail, removeArticle, getReporters };
};

export default {
  name: 'ArticleUpdate',
  props: {
    articleId: {
      type: String,
      required: true,
    },
  },
  components: {
    ArticlePublishingEditor,
    CardHeader,
    ArticleUpdateHeader,
    Form,
    TextFieldWithValidation,
    TextAreaWithValidation,
  },
  setup(props) {
    const swal = inject('$swal');
    const article = ref({});
    const reporter = ref({});
    const articleEditor = ref();
    const selectedOption = ref();
    const router = useRouter();
    const title = ref('');
    const summary = ref('');
    const content = ref('');
    const options = ref([]);
    const thumbnailFile = ref(null);
    const isInit = ref(false);

    onMounted(async () => {
      await getArticleDetail(props.articleId);
      updateBody(article.value.body);
      const result = await getReporters();
      selectedOption.value = reporter.value.id;
      options.value = result;
    });

    const changeEditorState = () => {
      isInit.value = true;
    };

    const { getArticleDetail, removeArticle, getReporters } = useArticleDetail(article, reporter, title, summary);

    const updateBody = (body) => {
      const html = body?.replaceAll('src="/', `src="${NEWS_IMG_BASE_URL}`);
      updateContent(html);

      if (isInit.value) {
        tinymce.get('publishing-editor').setContent(html);
        return;
      }

      const intervalId = setInterval(function () {
        if (isInit.value) {
          tinymce.get('publishing-editor').setContent(html);
          clearInterval(intervalId);
        }
      }, 100);
    };

    const updateContent = (e) => {
      content.value = e;
    };

    const onFileChange = (e) => {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      thumbnailFile.value = files[0];
    };

    const validate = () => {
      if (!title.value) {
        return { isValid: false, message: '제목을 입력해주세요.' };
      }

      if (!summary.value) {
        return { isValid: false, message: '본문 요약을 입력해주세요.' };
      }

      if (!content.value) {
        return { isValid: false, message: '기사 본문을 입력해주세요.' };
      }

      if (!selectedOption.value) {
        return { isValid: false, message: '보도 기자를 추가해주세요.' };
      }

      return { isValid: true };
    };

    const onSubmit = async () => {
      /** validation */
      const { isValid, message } = validate();
      if (!isValid) {
        swal.fire({
          icon: 'warn',
          text: message,
        });
        return;
      }

      const formData = new FormData();
      formData.append(
        'request',
        new Blob(
          [
            JSON.stringify({
              title: title.value,
              body: content.value,
              reporterId: selectedOption.value,
              bodySummary: summary.value,
            }),
          ],
          { type: 'application/json' },
        ),
      );
      if (thumbnailFile.value) {
        formData.append('thumbnail', thumbnailFile.value);
      }
      try {
        await updateArticle(props.articleId, formData);
      } catch (e) {
        swal.fire({
          icon: 'error',
          text: '업로드에 실패했습니다.',
        });
        return;
      }
      router.back();
    };

    return {
      article,
      schema,
      reporter,
      articleEditor,
      title,
      summary,
      options,
      selectedOption,
      onFileChange,
      updateBody,
      updateContent,
      removeArticle,
      onSubmit,
      changeEditorState,
    };
  },
};
</script>

<style lang="scss" scoped>
.editor-wrapper {
}
.select-wrapper:deep .choices__item--choice {
  padding: 0.3rem 0.5rem;
}
</style>
