<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <template v-for="menu in menus" :key="menu.id">
        <side-nav-menu :menu="menu"></side-nav-menu>
      </template>
    </ul>
  </div>
</template>

<script>
import SidenavMenu from '@/examples/Sidenav/SidenavMenu';
import { getMenu } from '@/apis/menu';
import { getAuthority } from '@/utils/tokenUtil';
import { onBeforeMount, ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';

export default {
  name: 'SidenavList',
  components: {
    'side-nav-menu': SidenavMenu,
  },
  setup() {
    const { logout } = useAuthStore();
    const { router } = useRouter();
    const menus = ref([]);
    onBeforeMount(async () => {
      try {
        const authority = getAuthority();
        if (authority) {
          menus.value = await getMenu(authority);
        } else {
          logout();
          router?.replace({ path: '/login' });
        }
      } catch (e) {
        // ignored
      }
    });
    return {
      menus,
    };
  },
};
</script>
