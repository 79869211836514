/** 사용 중인 플러그인 목록 (import 를 해야 사용할 수 있다.) */
const plugins = ['advlist', 'autolink', 'link', 'image', 'lists', 'preview'];

/** 상단 메뉴에 표시될 메뉴들 */
const menubar = 'view insert';
const menu = {
  view: { title: '미리보기', items: 'preview' },
  insert: { title: '추가하기', items: 'image | link hr' },
};

/** 툴바에 표시될 버튼들 */
const toolbar =
  'undo redo | styles bold italic | alignleft aligncenter alignright alignjustify | ' +
  'bullist numlist | image floatLeft imageCenter floatRight | link | ' +
  'forecolor backcolor';

/** html 블럭 스타일 */
const block_formats = 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6';

/** content 의 기본 스타일 */
const content_css = ['/css/legacyNewsArticle.css', '/css/tinyNewsArticle.css'];

/** 기본 태그 스타일 */
const formats = {
  h1: { block: 'h1', classes: 'text__title-xLarge' },
  h2: { block: 'h2', classes: 'text__title-large' },
  h3: { block: 'h3', classes: 'text__title-medium' },
  h4: { block: 'h4', classes: 'text__title-small' },
  h5: { block: 'h5', classes: 'text__title-xsmall' },
  h6: { block: 'h6', classes: 'text__title-xxsmall' },
  p: { block: 'p', classes: 'text__body-medium' },
  div: { block: 'div', classes: 'text__body-medium' },
  li: { block: 'li', classes: 'text__body-medium' },
  blockquote: { block: 'blockquote', classes: 'text__body-small' },
  figcaption: { block: 'figcaption', classes: 'text__caption-xsmall' },
  /** Inline */
  bold: { inline: 'strong', selector: 'p,div,li', classes: 'bold' },
  italic: { inline: 'em', classes: 'italic' },
  underline: { inline: 'span', classes: 'underline' },
  strikethrough: { inline: 'del', classes: 'strikethrough' },
  superscript: { inline: 'sup', classes: 'superscript' },
  subscript: { inline: 'sub', classes: 'subscript' },
  /** Align */
  imageFloatLeft: { selector: 'img,figure', classes: 'editor_img-float_left' },
  imageFloatRight: { selector: 'img,figure', classes: 'editor_img-float_right' },
  imageCenter: { selector: 'img,figure', classes: 'editor_img-center' },
  /** ForeColor Backcolor(Hilitecolor) */
  forecolor: { inline: 'span', classes: 'forecolor', styles: { color: '%value' } },
  hilitecolor: { inline: 'span', classes: 'hilitecolor', styles: { backgroundColor: '%value' } },
};

const style_formats = [
  {
    title: 'Headings',
    items: [
      { title: 'Heading 1', format: 'h1' },
      { title: 'Heading 2', format: 'h2' },
      { title: 'Heading 3', format: 'h3' },
      { title: 'Heading 4', format: 'h4' },
      { title: 'Heading 5', format: 'h5' },
      { title: 'Heading 6', format: 'h6' },
    ],
  },
  {
    title: 'Inline',
    items: [
      { title: 'Bold', format: 'bold' },
      { title: 'Italic', format: 'italic' },
      { title: 'Underline', format: 'underline' },
      { title: 'Strikethrough', format: 'strikethrough' },
      { title: 'Superscript', format: 'superscript' },
      { title: 'Subscript', format: 'subscript' },
    ],
  },
  {
    title: 'Blocks',
    items: [
      { title: 'Paragraph', format: 'p' },
      { title: 'Blockquote', format: 'blockquote' },
      { title: 'Div', format: 'div' },
    ],
  },
  {
    title: 'Align',
    items: [
      { title: 'Left', format: 'alignleft' },
      { title: 'Center', format: 'aligncenter' },
      { title: 'Right', format: 'alignright' },
      { title: 'Justify', format: 'alignjustify' },
    ],
  },
];

/** 기본 태그 설정 */
const forced_root_block = 'p';
const forced_root_block_attrs = {
  class: 'text__body-medium',
};

/** tinyMce 에디터의 기본 static option */
const tinyEditorDefaultOption = {
  language: 'ko_KR',
  width: '100%',
  height: 600,
  branding: false,
  promotion: false,
  paste_as_text: true, // 서식 제외 붙여넣기
  images_file_types: 'jpg,jpeg,svg,webp,png',
  image_caption: true,
  resize_img_proportional: true,
  image_uploadtab: true,
  automatic_uploads: true,
  plugins,
  toolbar,
  menu,
  menubar,
  block_formats,
  content_css,
  formats,
  style_formats,
  style_formats_autohide: true,
  forced_root_block,
  forced_root_block_attrs,
};

export default tinyEditorDefaultOption;
