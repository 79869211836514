<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <!--        카드 헤더-->
        <news-card-header :title="`${newsType} 관리`" :description="`${newsType}를 관리하는 페이지 입니다.`">
          <template #header-utils>
            <div class="header-utils-buttons">
              <router-link :to="`/article-create/${newsTypeId}`">
                <button class="mb-0 me-2 btn btn-info"><i class="fas fa-plus me-2" /> 기사 생성</button>
              </router-link>
              <button class="mb-0 btn btn-success" v-if="!disableHeaderUtils" @click="showArticles"><i class="fas fa-eye me-2" />선택 보여주기</button>
              <button class="mb-0 btn btn-warning" v-if="!disableHeaderUtils" @click="deleteArticles"><i class="fas fa-trash me-2" />선택 삭제하기</button>
              <button class="mb-0 btn btn-light" v-if="!disableHeaderUtils" @click="registerImportantArticles">
                <i class="fas fa-star me-2" />중요 기사 등록
              </button>
              <button class="mb-0 btn btn-dark" v-if="!disableHeaderUtils" @click="clearImportantArticles">
                <i class="fas fa-minus me-2" />중요 기사 해제
              </button>
            </div>
          </template>
        </news-card-header>
        <!--        카드 바디-->
        <div class="px-0 pb-0 card-body pt-0">
          <!--          드롭다운 & 검색창-->
          <news-search-box select-id="search-types" :news-list="newsList" :options="options" :onSubmit="submitHandler" />
          <!--       테이블-->
          <news-table-container @click="tableHandler" :table-id="tableId">
            <template #table-header>
              <news-table-header :head-cells="headCells" show-checkbox />
            </template>
            <template #table-body>
              <tbody>
                <news-table-row v-for="row of rows" :key="row.id" :article-id="row.id" show-actions show-checkbox :displayStatus="row.displayStatus">
                  <template #table-data>
                    <td class="text-sm">
                      <router-link :to="`/article-detail/${row.id}`">{{ row.title }}</router-link>
                    </td>
                    <td class="text-sm">{{ row.newsTitle }}</td>
                    <td class="text-sm">{{ row.reporterName }}</td>
                    <td class="text-sm">{{ row.createdDate }}</td>
                    <td class="text-sm">{{ row.modifiedDate }}</td>
                    <td class="text-sm">
                      <span :class="`badge badge-${row.displayStatus === 'SHOW' ? 'success' : 'danger'} badge-sm`">
                        {{ row.displayStatus === 'SHOW' ? '활성화됨' : '삭제됨' }}
                      </span>
                    </td>
                    <td class="text-sm text-center">
                      <span v-if="row.important">O</span>
                    </td>
                    <td class="text-sm">
                      <button
                        v-if="row.displayStatus === 'DELETE'"
                        class="action_button"
                        name="showArticle"
                        :data-element-name="actionsShowArticle"
                        :data-article-id="row.id"
                      >
                        보여주기
                      </button>
                      <button v-else class="action_button" name="deleteArticle" :data-element-name="actionsDeleteArticle" :data-article-id="row.id">
                        삭제하기
                      </button>
                    </td>
                  </template>
                </news-table-row>
              </tbody>
            </template>
          </news-table-container>
          <div class="text-center" v-if="!rows || rows.length === 0">등록된 기사글이 없습니다.</div>
          <argon-pagination>
            <argon-pagination-item v-if="pageInfo.isFirst" :key="pageInfo.first" :active="false" :first="true" @click="getPageArticles(pageInfo.first)" />
            <argon-pagination-item v-if="pageInfo.isPrev" :key="pageInfo.prev" :active="false" :prev="true" @click="getPageArticles(pageInfo.prev)" />
            <argon-pagination-item
              v-for="i in pageInfo.pageItems"
              :key="i"
              :label="i.toString()"
              :active="i === pageInfo.current"
              @click="getPageArticles(i)"
            />
            <argon-pagination-item v-if="pageInfo.isNext" :key="pageInfo.next" :active="false" :next="true" @click="getPageArticles(pageInfo.next)" />
            <argon-pagination-item v-if="pageInfo.isLast" :key="pageInfo.last" :active="false" :last="true" @click="getPageArticles(pageInfo.last)" />
          </argon-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-utils-buttons {
  & > button + button {
    margin-left: 8px;
  }
}
</style>

<script>
import { computed, onMounted, ref, inject } from 'vue';
import { deleteArticles, deleteArticle, showArticle, showArticles, registerImportantArticles, clearImportantArticles, searchArticles } from '@/apis/article';
import ArgonPagination from '@/components/ArgonPagination';
import ArgonPaginationItem from '@/components/ArgonPaginationItem';
import moment from 'moment';
import NewsCardHeader from '@/views/components/news/newsCard/NewsCardHeader';
import NewsSearchBox from '@/views/components/news/newsCard/NewsSearchBox';
import NewsTableContainer from '@/views/components/news/newsCard/NewsTableContainer';
import NewsTableHeader from '@/views/components/news/newsCard/NewsTableHeader';
import NewsTableRow from '@/views/components/news/newsCard/NewsTableRow';
import { ACTIONS_DELETE_ARTICLE, ACTIONS_SHOW_ARTICLE, HEAD_CELLS, NEWS_TYPE, OPTIONS, TABLE_CHECKBOX } from '@/constants/news/news';
import { useRoute } from 'vue-router';
import { getAllNews } from '@/apis/news';
import { successToast } from '@/utils/toastUtil';
import Choices from 'choices.js';

const useArticle = (selected, rows, pageInfo, condition, pagesSize) => {
  const swal = inject('$swal');

  const removeArticles = async () => {
    const selectedList = [...selected.value].map((s) => s.id);
    await swal('삭제하시겠습니까?');
    try {
      await deleteArticles(selectedList);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    [...selected.value].forEach((s) => (s.checked = false));
    await searchPageArticles();
  };
  const removeArticle = async (articleId) => {
    await swal.fire('삭제하시겠습니까?');
    try {
      await deleteArticle(articleId);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    await searchPageArticles();
  };
  const openArticles = async () => {
    const selectedList = [...selected.value].map((s) => s.id);
    await swal.fire('복구 하시겠습니까?');
    try {
      await showArticles(selectedList);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    [...selected.value].forEach((s) => (s.checked = false));
    await searchPageArticles();
  };
  const openArticle = async (articleId) => {
    await swal.fire('복구 하시겠습니까?');
    try {
      await showArticle(articleId);
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
    await searchPageArticles();
  };

  const changeImportantArticles = async () => {
    const selectedList = [...selected.value].map((s) => s.id);
    await swal('중요 기사로 등록하시겠습니까?');
    try {
      await registerImportantArticles(selectedList);
      successToast('중요 기사 등록이 완료되었습니다.');
      [...selected.value].forEach((s) => (s.checked = false));
      await searchPageArticles();
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
  };

  const changeUnImportantArticles = async () => {
    const selectedList = [...selected.value].map((s) => s.id);
    await swal('중요 기사 상태를 해제하시겠습니까?');
    try {
      await clearImportantArticles(selectedList);
      successToast('중요 기사 해제가 완료되었습니다.');
      [...selected.value].forEach((s) => (s.checked = false));
      await searchPageArticles();
    } catch (e) {
      swal.fire({
        icon: 'error',
        text: e.message,
      });
    }
  };

  const searchPageArticles = async () => {
    selected.value = new Set();
    const result = await searchArticles(condition);
    rows.value = result.content.map((p) => {
      return {
        ...p,
        createdDate: moment(p.createdDate).format('YYYY년 MM월 DD일 HH:mm'),
        modifiedDate: p.modifiedDate ? moment(p.modifiedDate).format('YYYY년 MM월 DD일 HH:mm') : '',
      };
    });

    const { number, totalPages } = result;
    let size = pagesSize.value;

    const quotient = parseInt(number / size);

    const start = quotient * size + 1;
    const end = quotient * size + size > totalPages ? totalPages % size : size;

    pageInfo.value = {
      isFirst: quotient !== 0,
      isPrev: quotient !== 0,
      isNext: quotient * size + size < totalPages,
      isLast: quotient * size + size < totalPages,
      first: 1,
      last: totalPages,
      prev: quotient * size,
      next: (quotient + 1) * size + 1,
      current: number + 1,
      pageItems: Array.apply(0, Array(end)).map((element, index) => index + start),
    };
  };

  return {
    deleteArticles: removeArticles,
    deleteArticle: removeArticle,
    showArticles: openArticles,
    showArticle: openArticle,
    registerImportantArticles: changeImportantArticles,
    clearImportantArticles: changeUnImportantArticles,
    searchPageArticles,
  };
};

export default {
  name: 'NewsArticleList',
  components: {
    ArgonPagination,
    ArgonPaginationItem,
    NewsCardHeader,
    NewsSearchBox,
    NewsTableContainer,
    NewsTableHeader,
    NewsTableRow,
  },
  setup() {
    const rows = ref([]);
    const route = useRoute();
    const pagesSize = ref(5);
    const newsType = ref(NEWS_TYPE[route.params.newsType.toUpperCase()]);
    const newsList = ref([]);
    const pageInfo = ref({
      prev: 0,
      next: 0,
      first: 0,
      last: 0,
      isPrev: 0,
      isNext: 0,
      isFirst: 0,
      isLast: 0,
      current: 0,
      pageItems: [],
    });
    const newsTypeId = computed(() => {
      return newsList.value.find(({ type }) => type === route.params.newsType.toUpperCase())?.id || '';
    });

    const condition = {
      size: 10,
      page: 0,
      newsId: null,
      newsType: route.params.newsType.toUpperCase(),
      keyword: null,
      reporterName: null,
    };

    const selected = ref(new Set());

    onMounted(async () => {
      await searchPageArticles();
      await getNewsList();
    });

    const getChoices = (id) => {
      if (document.getElementById(id)) {
        const element = document.getElementById(id);
        return new Choices(element, {
          shouldSort: false,
          searchEnabled: false,
          itemSelectText: '',
        });
      }
    };

    const getNewsList = async () => {
      const result = await getAllNews();
      newsList.value = result.filter((n) => n.type === route.params.newsType.toUpperCase());
      await getChoices('choices-search-types');
      await getChoices('choices-news-search-types');
    };

    const disableHeaderUtils = computed(() => selected.value.size <= 0);

    const { deleteArticles, deleteArticle, showArticles, showArticle, registerImportantArticles, clearImportantArticles, searchPageArticles } = useArticle(
      selected,
      rows,
      pageInfo,
      condition,
      pagesSize,
    );

    const submitHandler = ({ keyword, option, newsId }) => {
      if (option === 'title') {
        condition.keyword = keyword;
        condition.reporterName = null;
      } else {
        condition.reporterName = keyword;
        condition.keyword = null;
      }
      condition.page = 0;
      condition.newsId = newsId;
      searchPageArticles();
    };

    const checkboxHandler = (target) => {
      if (selected.value.has(target)) {
        selected.value.delete(target);
        return;
      }
      selected.value.add(target);
    };

    const tableHandler = (e) => {
      const { elementName, articleId } = e.target.dataset;

      if (!elementName) return;

      if (elementName === TABLE_CHECKBOX) {
        checkboxHandler(e.target);
        return;
      }

      if (elementName === ACTIONS_DELETE_ARTICLE) {
        deleteArticle(articleId);
        return;
      }

      if (elementName === ACTIONS_SHOW_ARTICLE) {
        showArticle(articleId);
      }
    };

    const getPageArticles = async (i) => {
      condition.page = i - 1;
      await searchPageArticles();
    };

    return {
      tableId: route.params.newsId,
      selected,
      newsTypeId,
      newsType,
      newsList,
      disableHeaderUtils,
      options: OPTIONS,
      headCells: HEAD_CELLS,
      rows,
      pagesSize,
      pageInfo,
      submitHandler,
      deleteArticles,
      deleteArticle,
      showArticles,
      showArticle,
      registerImportantArticles,
      clearImportantArticles,
      checkboxHandler,
      tableHandler,
      getPageArticles,
      actionsShowArticle: ACTIONS_SHOW_ARTICLE,
      actionsDeleteArticle: ACTIONS_DELETE_ARTICLE,
    };
  },
};
</script>
