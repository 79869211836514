<template>
  <svg
    :width="size"
    viewBox="0 0 301 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f)">
      <mask
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="301"
        height="156"
      >
        <rect width="301" height="156" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M150 250.825C209.094 250.825 257 202.92 257 143.825C257 84.7307 209.094 36.8252 150 36.8252C90.9055 36.8252 43 84.7307 43 143.825C43 202.92 90.9055 250.825 150 250.825Z"
          fill="#CED4DA"
        />
        <path
          d="M150 277.825C224.006 277.825 284 217.831 284 143.825C284 69.819 224.006 9.8252 150 9.8252C75.9938 9.8252 16 69.819 16 143.825C16 217.831 75.9938 277.825 150 277.825Z"
          stroke="#CED4DA"
          stroke-width="10"
          stroke-linecap="round"
          stroke-dasharray="1 66"
        />
        <g filter="url(#filter1_f)">
          <path
            d="M135.743 126.588C166.447 110.28 183.244 81.824 173.262 63.0298C163.28 44.2355 130.297 42.22 99.5936 58.528C68.8901 74.8359 52.0924 103.292 62.0748 122.086C72.0573 140.88 105.04 142.896 135.743 126.588Z"
            fill="white"
          />
        </g>
        <path d="M182 143.435H122V156.435H182V143.435Z" fill="white" />
        <path
          d="M6.5 151.935H124.063L151.927 133.325L180.5 151.935H294.5"
          stroke="#CED4DA"
          stroke-width="10"
          stroke-linecap="round"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f"
        x="0.5"
        y="3.8252"
        width="300"
        height="153.175"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter1_f"
        x="24"
        y="9"
        width="187.337"
        height="167.116"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur" />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'Lights',
  props: {
    size: {
      type: String,
      default: '72px',
    },
  },
};
</script>
