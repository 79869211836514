<template>
  <main-content-box>
    <template #main>
      <push-card-header :title="title" :description="description" />
      <div class="content-wrapper">
        <v-radio-group class="radio-group" v-model="radios">
          <template v-slot:label> 푸시를 발송할 지부를 선택해주세요. </template>
          <v-radio :value="ALL" @change="getSummary">
            <template v-slot:label> 전체 지부 발송 </template>
          </v-radio>
          <v-radio :value="BRANCH_OFFICE" @change="getSummary">
            <template v-slot:label> 특정 지부 발송 </template>
          </v-radio>
        </v-radio-group>

        <div v-if="radios === BRANCH_OFFICE">
          <div class="font-weight-bold" style="color: #344767; margin-bottom: 0.5rem; margin-left: 0.25rem">선택된 지부 목록</div>
          <div class="chip-group">
            <p v-if="selectedBranches.length === 0" class="mb-0" style="opacity: 0.5">푸시를 발송할 지부를 선택해주세요.</p>
            <template v-for="(selectedBranch, idx) in selectedBranches" :key="idx">
              <v-chip style="margin: 0 0.3rem 1rem 0">{{ selectedBranch }}</v-chip>
            </template>
          </div>
        </div>

        <form no-validate>
          <div class="autocomplete__deep-wrapper">
            <v-autocomplete
              v-model="selectedBranches"
              :items="branchSelects"
              :loading="selectsLoading"
              :menu-props="{ maxHeight: 300 }"
              no-data-text="일치하는 지부가 없습니다."
              clearable
              filled
              multiple
              label="지부 선택"
              :disabled="autoCompleteDisabled"
              @blur="getSummary"
            >
              <template #selection></template>
            </v-autocomplete>
          </div>
          <v-text-field v-model.trim="pushContent.title" label="푸시 제목*" :max-length="TITLE_LIMIT" :placeholder="`${TITLE_LIMIT}자 이내로 적어주세요.`" />
          <v-textarea v-model.trim="pushContent.body" label="푸시 내용*" :max-length="BODY_LIMIT" :placeholder="`${BODY_LIMIT}자 이내로 적어주세요.`" />
        </form>
        <div class="target-info-box">
          수신자 정보 (총 <strong>{{ targetSummary.toLocaleString() }}</strong> 명에게 발송됩니다.)
        </div>
        <v-btn type="button" color="primary" :loading="loading" :disabled="disabled" class="mt-2" @click="onSubmit">보내기</v-btn>
      </div>
    </template>
  </main-content-box>

  <!--  모달창-->
  <v-dialog v-model="isDialogOpen">
    <v-card class="pa-1">
      <template v-if="isValidDialog">
        <v-card-title>정말로 푸시를 발송하시겠습니까?</v-card-title>
        <v-card-subtitle>아래 내용으로 발송됩니다.</v-card-subtitle>
        <v-card-text>
          <div class="pushContent-wrapper">
            <div>제목</div>
            <p class="pushContent-body">{{ pushContent.title }}</p>
          </div>
          <div class="pushContent-wrapper">
            <div>내용</div>
            <p class="pushContent-body">{{ pushContent.body }}</p>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn type="button" @click="closeDialog">취소</v-btn>
          <v-btn type="button" color="blue-darken-1" :loading="sendButtonLoading" :disabled="sendButtonDisabled" @click="sendPushMessage">발송</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text class="text-center">
          <template v-for="(message, idx) in inValidMessage.split('\n')" :key="idx">
            <br v-if="idx !== 0" />
            {{ message }}
          </template>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn type="button" @click="closeDialog">닫기</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import MainContentBox from '@/components/MainContentBox';
import PushCardHeader from '@/views/components/push/PushCardHeader';

import { getTargetBranchOffices, getTargetSummary, sendPushToUsers } from '@/apis/push';
import { successToast } from '@/utils/toastUtil';
import { isEmpty } from 'lodash-es';

const [ALL, BRANCH_OFFICE] = ['ALL', 'BRANCH_OFFICE'];
const [TITLE_LIMIT, BODY_LIMIT] = [30, 200];

export default {
  name: 'PushManage',
  components: { PushCardHeader, MainContentBox },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  setup() {
    /** select */
    const radios = ref(ALL);
    const selectsLoading = ref(false);
    const selectedBranches = ref([]);
    const branches = ref([]);
    const branchSelects = computed(() => branches.value.map((branch) => branch.name));
    const branchOfficeIds = computed(() => {
      const officeIds = selectedBranches.value.map((selected) => {
        const branch = branches.value.find((branch) => branch.name === selected);
        return branch.branchOfficeId;
      });

      return officeIds;
    });

    /** form */
    const autoCompleteDisabled = computed(() => radios.value === ALL);
    const selectedBranchOfficeList = ref([]);
    const pushContent = ref({
      title: '',
      body: '',
    });

    const resetForm = () => {
      pushContent.value = { title: '', body: '' };
      branchOfficeIds.value = [];
      selectedBranches.value = [];
      targetSummary.value = 0;
    };

    /** info box */
    const summaryLoading = ref(false);
    const targetSummary = ref(0);

    /** button */
    const loading = computed(() => selectsLoading.value || isDialogOpen.value || summaryLoading.value);
    const disabled = computed(() => loading.value);

    /** dialog */
    const isDialogOpen = ref(false);
    const sendButtonLoading = ref(false);
    const sendButtonDisabled = computed(() => sendButtonLoading.value);

    const isValidDialog = ref(true);
    const inValidMessage = ref('');

    const openDialog = () => {
      isDialogOpen.value = true;
    };

    const closeDialog = () => {
      isDialogOpen.value = false;
    };

    const validate = () => {
      const { title, body } = pushContent.value;

      if (targetSummary.value <= 0) {
        return { isValid: false, message: '푸시 메시지를 받을 대상이 없어\n발송에 실패하였습니다.' };
      }

      if (!title) {
        return { isValid: false, message: '제목을 입력해주세요.' };
      }

      if (title.length > TITLE_LIMIT) {
        return { isValid: false, message: `제목은 ${TITLE_LIMIT}자 이내로 작성해주세요.` };
      }

      if (!body) {
        return { isValid: false, message: '내용을 입력해주세요.' };
      }

      if (body.length > BODY_LIMIT) {
        return { isValid: false, message: `내용은 ${BODY_LIMIT}자 이내로 작성해주세요.` };
      }

      return { isValid: true };
    };

    const onSubmit = (e) => {
      e.preventDefault();

      const { isValid, message } = validate();

      isValidDialog.value = isValid;
      inValidMessage.value = isValid ? '' : message;

      openDialog();
    };

    const sendPushMessage = async () => {
      sendButtonLoading.value = true;
      try {
        const isSendAll = radios.value === ALL;
        await sendPushToUsers({ isSendAll, branchOfficeIds: branchOfficeIds.value, ...pushContent.value });
        successToast(`총 ${targetSummary.value}명에게 푸시 발송이 완료되었습니다.`);
      } catch (e) {
        //
      } finally {
        resetForm();
        closeDialog();
        sendButtonLoading.value = false;
      }
    };

    /** fetch */
    const getSummary = async () => {
      summaryLoading.value = true;
      try {
        /** 전체 지부 발송으로 변경될 때 기존 선택된 지부들 리셋 */
        if (radios.value === ALL) {
          selectedBranches.value = [];
          targetSummary.value = 0;
        }

        /** 지부 선택으로 변경될 때 선택된 브랜치가 없는 경우 api 호출 X */
        if (radios.value === BRANCH_OFFICE && isEmpty(branchOfficeIds.value)) {
          targetSummary.value = 0;
          return;
        }

        const isSendAll = radios.value === ALL;
        const summary = (await getTargetSummary({ isSendAll, branchOfficeIds: branchOfficeIds.value })) || {};
        targetSummary.value = summary.count || 0;
      } catch (e) {
        //
      } finally {
        summaryLoading.value = false;
      }
    };

    const getBranches = async () => {
      selectsLoading.value = true;
      try {
        const targetBranchOffices = (await getTargetBranchOffices()) || [];
        branches.value = targetBranchOffices;
      } catch (e) {
        //
      } finally {
        selectsLoading.value = false;
      }
    };

    /** lifeCycle */
    onMounted(() => {
      getBranches();
      getSummary();
    });

    return {
      branchOfficeIds,
      radios,
      selectsLoading,
      selectedBranches,
      branchSelects,
      ALL,
      BRANCH_OFFICE,
      autoCompleteDisabled,
      selectedBranchOfficeList,
      pushContent,
      targetSummary,
      loading,
      disabled,
      isDialogOpen,
      sendButtonLoading,
      sendButtonDisabled,
      isValidDialog,
      inValidMessage,
      TITLE_LIMIT,
      BODY_LIMIT,
      getSummary,
      onSubmit,
      closeDialog,
      sendPushMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin-top: 1rem;
  padding-top: 1rem;
}

.radio-group:deep {
  .v-input__control {
    flex-direction: column;
  }

  .v-selection-control-group {
    display: inline-flex;
    width: fit-content;
    gap: 1rem;
    flex-wrap: wrap;

    .v-label {
      margin: 0;
    }

    .v-selection-control {
      margin-right: 1rem;
    }
  }
}

.target-info-box {
  margin-bottom: 1rem;
  padding: 1rem;
  background: #f7f7f7;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  color: #677484;
}

.pushContent {
  &-wrapper {
    max-width: 400px;
  }

  &-body {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
}

.autocomplete__deep-wrapper:deep {
  .v-autocomplete__selection {
    margin: 0 !important;
  }
}

.chip-group {
  height: calc(2rem + 32px);
  overflow: scroll;
  border: 1px solid #d4d4d4;
  padding: 1rem 1rem 0 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  background: #f7f7f7;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #a9a9a9;
  }
}
</style>
