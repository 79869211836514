<template>
  <div class="card-body basic-card-body" :class="{ 'overflow-auto': scrollable }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardBody',
  props: {
    scrollable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
