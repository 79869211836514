export const OPTIONS = [
  {
    label: '정회원',
    value: 'AUTHORIZED_MEMBER',
  },
  {
    label: '준회원',
    value: 'NON_AUTHORIZED_MEMBER',
  },
  {
    label: '비회원',
    value: 'NOT_MEMBER',
  },
];

export const HEAD_CELLS = [
  // { id: 'none', label: '' },
  { id: 'title', label: '메뉴 제목' },
  { id: 'path', label: '메뉴 path' },
  { id: 'writeAuthority', label: '글 작성 권한' },
  { id: 'displayStatus', label: '상태' },

  // { id: 'replyWriteAuthority', label: '댓글 작성 권한' },
];
