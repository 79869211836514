<template>
  <main-content-box>
    <template #main>
      <push-card-header :title="title" :description="description" />
      <div>
        <grid-view ref="gridRef" :columns="columns" :data="data" :column-options="columnOptions" is-edit-hide>
          <template #addBtn>
            <v-btn outlined type="button" :loading="loading" :disabled="disabled" class="mt-2" @click="getHistory"
              ><v-icon class="icon-wrapper">{{ mdiReload }}</v-icon>
            </v-btn>
          </template>
        </grid-view>
      </div>
    </template>
  </main-content-box>
</template>

<script>
import { onMounted, ref, computed } from 'vue';

import { mdiReload } from '@mdi/js';

import MainContentBox from '@/components/MainContentBox';
import PushCardHeader from '@/views/components/push/PushCardHeader';
import GridView from '@/views/components/common/GridView';

import { getPushHistory } from '@/apis/push';
import { PUSH_HISTORY_MANAGEMENT_HEADER } from '@/constants/contest/gridHeader';
import { successToast } from '@/utils/toastUtil';
import { toYYYYMMDDHHMMSS } from '@/utils/dateUtil';

export default {
  name: 'PushHistory',
  components: { GridView, PushCardHeader, MainContentBox },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  setup() {
    const loading = ref(false);
    const disabled = computed(() => loading.value);
    const data = ref([]);

    const columns = PUSH_HISTORY_MANAGEMENT_HEADER;
    const columnOptions = {
      resizable: true,
    };

    const getHistory = async () => {
      loading.value = true;

      try {
        const res = (await getPushHistory()) || [];
        data.value = res.map((o) => ({ ...o, createdDate: toYYYYMMDDHHMMSS(o.createdDate) }));

        successToast('히스토리 조회가 완료되었습니다.');
      } catch (e) {
        //
      } finally {
        loading.value = false;
      }
    };

    /** lifeCycle */
    onMounted(() => {
      getHistory();
    });

    return { loading, disabled, data, columns, columnOptions, getHistory, mdiReload };
  },
};
</script>

<style lang="scss" scoped></style>
