import { defineStore } from 'pinia';
import { loginAPI } from '@/apis/user';
import { parseJwt } from '@/utils/tokenUtil';
import { setAuthHeader } from '@/lib/axiosController';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    userId: '',
    loginId: '',
    authority: '',
    authorityName: '',
    sub: '',
    exp: '',
    iat: '',
    branchOfficeId: '',
    branchOfficeName: '',
  }),
  actions: {
    tokenParser(token) {
      const { id, loginId: parsedLoginId, branchOfficeId, branchOfficeName, authority, authorityName, sub, exp, iat } = parseJwt(token);

      this.userId = id;
      this.loginId = parsedLoginId;
      this.authority = authority;
      this.sub = sub;
      this.exp = exp;
      this.iat = iat;
      this.branchOfficeId = branchOfficeId;
      this.authorityName = authorityName;
      this.branchOfficeName = branchOfficeName;
    },
    setInitToken() {
      const token = localStorage.getItem('authToken');
      if (token) {
        setAuthHeader(token);
        this.tokenParser(token);
      }
    },
    async requestSignin(loginId, password) {
      const result = await loginAPI({
        loginId,
        password,
      });
      localStorage.setItem('authToken', result);
      setAuthHeader(result);
      this.tokenParser(result);
    },
    async logout() {
      localStorage.removeItem('authToken');
      this.userId = '';
      this.loginId = '';
      this.authority = '';
      this.sub = '';
      this.exp = '';
      this.iat = '';
      this.branchOfficeId = '';
      this.authorityName = '';
      this.branchOfficeName = '';
    },
    isAuthorized() {
      return this.userId && this.authority;
    },
    getAuthority() {
      return this.authority;
    },
  },
});
