import axios from '@/lib/axiosController';

export const modifyMenu = (menuId, body) => axios.put(`/menu/${menuId}`, body);

export const deleteMenu = (menuId) => axios.delete(`/menu/${menuId}`);

export const hasAuthMenu = (menuId, adminAuthorityType) => axios.get(`/menu/auth/${menuId}`, { params: { adminAuthorityType } });

export const addMenuInAuth = (menuId, adminAuthorityType) => axios.put(`/menu/auth/${menuId}`, { params: { adminAuthorityType } });

export const deleteMenuInAuth = (menuId, adminAuthorityType) => axios.delete(`/menu/auth/${menuId}`, { params: { adminAuthorityType } });

export const createMenu = (body) => axios.post('/menu/create', body);

export const getMenu = (adminAuthorityType) => axios.get('/menu', { params: { adminAuthorityType } });

export const getMenuSet = (adminAuthorityType) => axios.get('/menu/set', { params: { adminAuthorityType } });

export const getMenuCacheRefresh = (adminAuthorityType) => axios.get('/menu/refresh', { params: { adminAuthorityType } });

export const getMenuSetCacheRefresh = (adminAuthorityType) => axios.get('/menu/set/refresh', { params: { adminAuthorityType } });

export const getMenuCacheClear = (adminAuthorityType) => axios.get('/menu/clear', { params: { adminAuthorityType } });

export const getMenuSetCacheClear = (adminAuthorityType) => axios.get('/menu/set/clear', { params: { adminAuthorityType } });

//user menu
export const getAllUserMenu = (condition) => axios.get('/user-menu/all', { params: condition });
