<template>
  <form v-if="showDropdown" @submit="submitForm" style="display: flex" class="w-100 justify-content-end">
    <div class="w-15 mt-lg-0 select-wrapper">
      <select :id="'choices-' + selectId" @change="changeOption" class="form-control select" :name="'choices-' + selectId">
        <option v-for="(option, idx) in options" :key="idx" :value="option.value" :selected="isSelected(option.value)">
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="w-25 input-group input-search">
      <input type="search" :value="keyword" @input="changeKeyword" @keydown="keyDownHandler" @blur="blurInput" class="form-control" placeholder="검색하기" />
      <button type="submit" class="input-group-text text-body">
        <i class="fas fa-search" aria-hidden="true"></i>
      </button>
    </div>
  </form>
</template>

<style scoped lang="scss">
.input-search {
  margin-left: 4px;
  margin-right: 5px;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-right: 1px solid #d2d6da !important;
}

.select-wrapper:deep .choices__item--choice {
  padding: 0.3rem 0.5rem;
}
</style>

<script>
import { isEmpty } from 'lodash-es';
import Choices from 'choices.js';
import { computed, onMounted, ref } from 'vue';

const getChoices = (id) => {
  if (document.getElementById(id)) {
    const element = document.getElementById(id);
    return new Choices(element, {
      shouldSort: false,
      searchEnabled: false,
      itemSelectText: '',
    });
  }
};

const useEventHandler = (props, keyword, selectedOption) => {
  const changeOption = (e) => {
    selectedOption.value = e.target.value;
  };
  const changeKeyword = (e) => {
    keyword.value = e.target.value;
    props.onChange?.({ e, keyword: keyword.value, option: selectedOption.value });
  };
  const blurInput = (e) => {
    props.onblur?.({ e, keyword: keyword.value, option: selectedOption.value });
  };
  const submitForm = (e) => {
    e.preventDefault();
    props.onSubmit?.({ e, keyword: keyword.value, option: selectedOption.value });
  };

  const keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      submitForm(e);
    }
  };

  return { changeOption, changeKeyword, blurInput, submitForm, keyDownHandler };
};

export default {
  name: 'BoardSearchBox',
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectId: {
      type: String,
      required: true,
    },
    selectedValue: {
      type: String,
    },
    onChange: {
      type: Function,
    },
    onBlur: {
      type: Function,
    },
    onSubmit: {
      type: Function,
    },
  },
  setup(props) {
    onMounted(() => {
      getChoices('choices-search-types');
    });

    const showDropdown = computed(() => !isEmpty(props.options));

    const isSelected = (value) => {
      const defaultSelected = props.options[0].value;
      const selectedValue = props.selectedValue || defaultSelected;

      return value === selectedValue;
    };

    const keyword = ref('');
    const selectedOption = ref(props.options[0].value);

    const { changeOption, changeKeyword, blurInput, submitForm, keyDownHandler } = useEventHandler(props, keyword, selectedOption);

    return {
      showDropdown,
      keyword,
      selectedOption,
      isSelected,
      changeOption,
      changeKeyword,
      blurInput,
      submitForm,
      keyDownHandler,
    };
  },
};
</script>
