<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <article-card-header
          :title="article.title"
          :reporter-name="reporter.reporterName"
          :reporter-email="reporter.reporterEmail"
          :created-date="article.createdDate"
          :modified-date="article.modifiedDate"
        >
          <template #header-utils>
            <div class="header-utils-buttons">
              <router-link :to="`/article-update/${articleId}`">
                <button class="mb-0 btn btn-outline-primary btn-sm me-2">수정하기</button>
              </router-link>
              <button class="mb-0 btn btn-outline-danger btn-sm" @click="() => removeArticle(articleId)">삭제하기</button>
            </div>
          </template>
        </article-card-header>
        <div class="card-body">
          <div v-if="articleHtml" v-html="articleHtml" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteArticle, getArticle } from '@/apis/article';
import { inject, ref, onMounted, onUnmounted } from 'vue';
import moment from 'moment';
import ArticleCardHeader from '@/views/components/news/articleCard/ArticleCardHeader.vue';
import { NEWS_IMG_BASE_URL_DEV, NEWS_IMG_BASE_URL_PROD } from '@/constants/news/news';
import { getStyleSheet, removeStyleSheet } from '@/utils';
import { successToast } from '@/utils/toastUtil';

const useArticleDetail = (article, reporter) => {
  const swal = inject('$swal');
  const getArticleDetail = async (articleId) => {
    const result = await getArticle(articleId);
    article.value = {
      ...result,
      createdDate: moment(result.createdDate).format('YYYY년 MM월 DD일 HH:mm'),
      modifiedDate: result.modifiedDate ? moment(result.modifiedDate).format('YYYY년 MM월 DD일 HH:mm') : '',
    };
    reporter.value = {
      ...result.reporterAdminView,
    };
  };
  const removeArticle = async (articleId) => {
    await swal.fire('삭제하시겠습니까?');
    await deleteArticle(articleId);
    successToast('삭제가 완료되었습니다.');
  };
  return { getArticleDetail, removeArticle };
};

const NEWS_DETAIL_LEGACY = 'news-detail-legacy';
const TINY_NEWS_ARTICLE = 'tiny-news-article';

export default {
  name: 'ArticleDetail',
  props: {
    articleId: {
      type: String,
      required: true,
    },
  },
  components: {
    ArticleCardHeader,
  },
  setup(props) {
    const article = ref({});
    const reporter = ref({});
    const articleHtml = ref(null);

    const updateBody = (body) => {
      const baseUrl = process.env.NODE_ENV === 'development' ? NEWS_IMG_BASE_URL_DEV : NEWS_IMG_BASE_URL_PROD;
      articleHtml.value = body?.replaceAll('src="/', `src="${baseUrl}`);
    };

    onMounted(async () => {
      await getArticleDetail(props.articleId);
      updateBody(article.value.body);
    });

    onMounted(() => {
      getStyleSheet({ id: NEWS_DETAIL_LEGACY, href: '/css/legacyNewsArticle.css' });
      getStyleSheet({ id: TINY_NEWS_ARTICLE, href: '/css/tinyNewsArticle.css' });
    });

    onUnmounted(() => {
      removeStyleSheet(NEWS_DETAIL_LEGACY);
      removeStyleSheet(TINY_NEWS_ARTICLE);
    });

    const { getArticleDetail, removeArticle } = useArticleDetail(article, reporter);

    return {
      article,
      reporter,
      articleHtml,
      removeArticle,
    };
  },
};
</script>
