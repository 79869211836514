<template>
  <div class="py-4 container-fluid">
    <div class="card basic-card card-body">
      <v-icon class="icon-wrqpper" color="#ffcc00">{{ mdiAlertOutline }}</v-icon>

      <div class="text-wrapper">
        <h1 class="text-wrapper__title">페이지를 찾을 수 없습니다.</h1>
        <div class="text-wrapper__desc">
          <v-btn class="p-2" variant="text" size="xl" to="/">홈으로 가기</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiAlertOutline } from '@mdi/js';

export default {
  setup() {
    return { mdiAlertOutline };
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 2rem;
  height: 80vh;
  background-image: url('@/assets/img/illustrations/404.svg');
}

.icon-wrqpper {
  margin-bottom: 1rem;
  font-size: 120px;
}

.text-wrapper {
  text-align: center;

  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
  }

  &__desc {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
