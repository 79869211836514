<template>
  <div class="editor-wrapper">
    <textarea ref="editor" :id="selector" />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { tinyEditorDefaultOption, EDITOR_IMG } from '@/constants/tinymce';
import { addToggleClassButton, imageUploadHandler } from '@/lib/tinymce';
import { uploadArticleImage } from '@/apis/article';

import tinymce from 'tinymce';
import 'tinymce/models/dom';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.css';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/link';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';

/**
 * tinymce Editor 를 사용한 뉴스 퍼블리싱 에디터
 */
export default {
  name: 'ArticlePublishingEditor',
  components: {},
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    selector: {
      type: String,
      required: true,
    },
    updateContent: {
      type: Function,
      default: () => {},
    },
    changeEditorState: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const editor = ref();

    const initEditor = () => {
      tinymce.init({
        selector: `#${props.selector}`,
        ...tinyEditorDefaultOption,
        images_upload_handler: imageUploadHandler(uploadArticleImage, 'IMAGE'),
        setup: (ed) => {
          ed.on('init', function () {
            props.changeEditorState();
          });

          ed.on('blur', function () {
            const content = tinymce.get(props.selector).getContent();
            props.updateContent(content);
          });

          /** 버튼 추가 */
          addToggleClassButton(ed, EDITOR_IMG.FLOAT_LEFT, [EDITOR_IMG.FLOAT_RIGHT, EDITOR_IMG.CENTER], 'floatLeft', {
            tooltip: '이미지를 왼쪽으로 정렬',
            text: '이미지 왼쪽 맞춤',
          });

          addToggleClassButton(ed, EDITOR_IMG.CENTER, [EDITOR_IMG.FLOAT_LEFT, EDITOR_IMG.FLOAT_RIGHT], 'imageCenter', {
            tooltip: '이미지를 중앙으로 정렬',
            text: '이미지 중앙 정렬',
          });

          addToggleClassButton(ed, EDITOR_IMG.FLOAT_RIGHT, [EDITOR_IMG.FLOAT_LEFT, EDITOR_IMG.CENTER], 'floatRight', {
            tooltip: '이미지를 오른쪽으로 정렬',
            text: '이미지 오른쪽 맞춤',
          });
        },
        ...props.option,
      });
    };

    onMounted(() => {
      tinymce.remove(`#${props.selector}`);
      initEditor();
    });

    return { editor };
  },
};
</script>

<style lang="scss">
body.tox-dialog__disable-scroll {
  overflow: unset;
}
</style>
