import axios from '@/lib/axiosController';

/**
 * 대회 선수번호(거울번호)관리 API
 */

/**
 * 종목 ID에 따른 거울 번호들 수정
 * @param {array} bulk
 * @returns
 */
export const bulkUpdatePlayMirror = (bulk) =>
  axios.put('/player-mirror/modify/bulk', {
    modifyRequests: bulk,
  });

/**
 * 종목 ID별 거울번호 조회
 * @param {string} contestItemId
 * @returns
 */
export const getPlayerMirrorsByContestItemId = async (contestItemId) => axios.get(`/player-mirror/${contestItemId}`);

export const changePlayerMirrorsSeqByContestItemId = async (contestItemId) => axios.put(`/player-mirror/seq/contestItem/${contestItemId}`);
