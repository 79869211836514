<template>
  <main-content-box>
    <template #main>
      <div class="d-flex justify-content-end">
        <div class="d-flex align-items-center">
          <div class="searchUserInputWrapper">
            <basic-input
              id="userName"
              name="userName"
              :text-value="searchUserName"
              placeholder="검색할 유저 이름을 입력하세요"
              @update:textValue="searchUserName = $event"
              hideDetails
            />
          </div>
          <v-btn class="mr-4" color="success" @click="fetchData" :disabled="isDataLoading">조회하기</v-btn>
        </div>
      </div>
      <grid-view
        ref="grid"
        :columns="columns"
        :data="data"
        :column-options="columnOptions"
        :row-headers="rowHeaders"
        :updateRows="updateUsersHandler"
        :deleteRows="deleteUsersHandler"
        :pageOptions="PAGINATION_OPTION"
        :totalElements="totalElements"
        :page="currentPage"
        :validationFailHandler="validationFailHandler"
        deleteBtnText="사용자 비활성화"
        pagination
        deletable
      >
        <template #addBtn>
          <v-btn @click="openCommunityDialog($refs.grid.getCheckedRows())" class="mr-2" color="warning" v-bind="props"> 커뮤니티권한 변경 </v-btn>
          <v-dialog v-model="communityDialog">
            <v-card class="dialog">
              <v-card-title>
                <span class="text-h5">지회지부 게시판 커뮤니티 권한 변경</span>
              </v-card-title>
              <v-card-text>
                <div class="d-flex">
                  <branch-select-group
                    :branchOfficeId="communitySelectedBranchId"
                    :regionName="communitySelectedRegionName"
                    :branchName="communitySelectedBranchName"
                    @update:regionName="(value) => updateRegionName(value)"
                    @update:branchOfficeId="(value) => onUpdateCommunityBranch(value)"
                  />
                  <div class="d-flex align-items-center ml-10">
                    <v-btn class="d-flex justify-center" color="success" @click="addCommunityAuthority"> 추가 </v-btn>
                  </div>
                </div>
              </v-card-text>
              <v-table>
                <thead>
                  <tr>
                    <th class="text-center">지회/지부명</th>
                    <th class="text-center">조회 권한</th>
                    <th class="text-center">쓰기 권한</th>
                    <th class="text-center">고정글 권한</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in selectedUserCommunityAuthorities" :key="item.branchOfficeId">
                    <td>
                      {{ item.branchOfficeName }}
                    </td>
                    <td>
                      <v-checkbox class="d-flex justify-center" v-model="item.read" label=""></v-checkbox>
                    </td>
                    <td class="text-center">
                      <v-checkbox class="d-flex justify-center" v-model="item.write" label=""></v-checkbox>
                    </td>
                    <td class="text-center">
                      <v-checkbox class="d-flex justify-center" v-model="item.writeFixedPost" label=""></v-checkbox>
                    </td>
                    <td class="text-center" @click="removeCommunityAuthority(item)">
                      <i class="fas fa-minus" style="color: orangered"></i>
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="communityDialog = false"> 닫기 </v-btn>
                <v-progress-circular v-if="isUpdateLoading" indeterminate color="primary"></v-progress-circular>
                <v-btn v-else color="blue-darken-1" text @click="changeBranchOfficeAuthority"> 저장 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn class="mr-2" value="approve" color="info" @click="approveHandler($refs.grid.getCheckedRows())"> 승인하기 </v-btn>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ props }">
              <v-btn class="mr-4" color="primary" v-bind="props"> 지회/지부 변경 </v-btn>
            </template>
            <v-card class="dialog">
              <v-card-title>
                <span class="text-h5">지회 지부 선택</span>
              </v-card-title>
              <v-card-text>
                <branch-select-group
                  :branchOfficeId="selectedBranchId"
                  :regionName="selectedRegionName"
                  :branchName="selectedBranchName"
                  @update:regionName="selectedRegionName = $event"
                  @update:branchOfficeId="(value) => onUpdateBranch(value)"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog"> 닫기 </v-btn>
                <v-progress-circular v-if="isUpdateLoading" indeterminate color="primary"></v-progress-circular>
                <v-btn v-else color="blue-darken-1" text @click="updateBranchHandler($refs.grid.getCheckedRows())"> 변경 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </grid-view>
    </template>
  </main-content-box>
</template>

<script>
import { ref, onMounted } from 'vue';

import {
  getUserSearch,
  deleteUsers,
  updateUsers,
  modifyBranchUsers,
  approveUsers,
  getBranchOfficeCommunity,
  updateBranchOfficeCommunity,
} from '@/apis/userManagement';

import GridView from '@/views/components/common/GridView';
import { BRANCH_OFFICE_COMMUNITY_AUTHORITY_HEADER, USER_AUTHORITY, USER_MANAGEMENT_HEADER } from '@/constants/userManagement';
import MainContentBox from '@/components/MainContentBox.vue';
import BasicInput from '@/components/BasicInput.vue';
import BranchSelectGroup from '@/views/components/contest/BranchSelectGroup.vue';

import { successToast, warningToast } from '@/utils/toastUtil';
import { useBranchStore } from '@/store/branch';

const PAGINATION_OPTION = {
  useClient: true,
  perPage: 20,
};

/**
 * 일정관리 페이지
 */
export default {
  name: 'UserManagement',
  components: { GridView, MainContentBox, BasicInput, BranchSelectGroup },
  setup() {
    const branchStore = useBranchStore();
    const currentPage = ref(0);
    const totalElements = ref(0);
    const branchInfos = ref([]);
    const isBranchInfoLoading = ref(true);
    const isUpdateLoading = ref(false);

    const isDataLoading = ref(false);
    const searchUserName = ref('');

    const dialog = ref(false);

    const selectedBranchId = ref();
    const selectedRegionName = ref('');
    const selectedBranchName = ref('');
    const communityDialog = ref(false);

    const communitySelectedBranchName = ref('');
    const communitySelectedRegionName = ref('');
    const communitySelectedBranchId = ref(null);
    const selectedUserCommunityAuthorities = ref([]);
    const selectedUserId = ref(null);

    const columns = USER_MANAGEMENT_HEADER;
    const columnsUserMenuAuthority = BRANCH_OFFICE_COMMUNITY_AUTHORITY_HEADER;
    const columnOptions = {
      resizable: true,
    };
    const data = ref([]);
    const rowHeaders = ['rowNum', 'checkbox'];

    const fetchData = async () => {
      isDataLoading.value = true;
      const body = {};

      if (searchUserName.value !== '') {
        body.userName = searchUserName.value;
      }

      const query = Object.entries(body)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      const ret = await getUserSearch(query);

      data.value = ret.map((v) => ({
        ...v,
        userAuthorityGroupTypeText: USER_AUTHORITY[v.userAuthorityGroupType],
        userStatusText: v.userAuthorityGroupType === 'NON_AUTHORIZED_MEMBER' ? '대기중' : '승인됨',
        displayStatusText: v.displayStatus === 'SHOW' ? '활성화' : '비활성화',
      }));

      totalElements.value = ret.length;

      isDataLoading.value = false;
      successToast(`유저 검색에 성공했습니다.`);
    };

    const updateUsersHandler = async (dataArr) => {
      if (dataArr.length > 0) {
        const parsedData = dataArr.map((v) => ({
          id: v.id,
          name: v.name,
          phone: v.phone,
          address: v.address,
          addressDetail: v.addressDetail,
          zip: v.zip,
          userAuthorityGroupType: USER_AUTHORITY[v.userAuthorityGroupTypeText],
        }));

        await updateUsers(parsedData);
        await fetchData();
        return true;
      }

      return false;
    };

    const validationFailHandler = () => {
      warningToast('핸드폰 번호는 15글자 안으로 입력할 수 있습니다.');
    };

    const fetchBranchInfos = async () => {
      await branchStore.fetchBranchInfos();
      branchInfos.value = branchStore.branchInfos;
    };

    const initData = async () => {
      await fetchBranchInfos();
      await fetchData();
    };

    const closeDialog = () => {
      isUpdateLoading.value = false;
      dialog.value = false;
    };

    const updateBranchHandler = async (rows) => {
      isUpdateLoading.value = true;
      try {
        const body = rows.map((row) => ({ id: row.id, branchOfficeId: selectedBranchId.value }));
        await modifyBranchUsers(body);
        await fetchData();
      } catch (e) {
        closeDialog();
      }

      closeDialog();
      return true;
    };

    const approveHandler = async (rows) => {
      await approveUsers(rows.map((row) => row.id));
      await fetchData();
    };

    onMounted(() => {
      initData();
    });

    const deleteUsersHandler = async (ids) => {
      const alreadyDeleteUsersNames = ids
        .filter((id) => data.value.some((v) => id === v.id && v.displayStatus === 'DELETE'))
        .map((id) => {
          const user = data.value.find((d) => d.id === id);

          return user.name;
        });

      if (alreadyDeleteUsersNames.length > 0) {
        warningToast(`이미 삭제하신 사용자가 포함되어있습니다.\n이름: ${alreadyDeleteUsersNames.join(', ')}`);
        return;
      }

      await deleteUsers(ids.map((v) => Number(v)));
      await fetchData();
      successToast('삭제에 성공했습니다.');
    };

    const onUpdateBranch = (branchOfficeId) => {
      selectedBranchId.value = branchOfficeId;
      selectedBranchName.value = branchStore.branchInfoArray.find((v) => v.id === branchOfficeId)?.branchName;
    };

    const addCommunityAuthority = () => {
      if (!communitySelectedBranchId.value) {
        warningToast('지회/지부를 선택해주세요.');
        return;
      }
      const isExistedBranchOffice = selectedUserCommunityAuthorities.value.filter((v) => {
        return v.branchOfficeId === communitySelectedBranchId.value;
      });
      if (isExistedBranchOffice.length) {
        warningToast('이미 존재하는 지회/지부입니다.');
        return;
      }
      selectedUserCommunityAuthorities.value.push({
        branchOfficeId: communitySelectedBranchId.value,
        branchOfficeName: communitySelectedBranchName.value,
        read: false,
        write: false,
        writeFixedPost: false,
      });
    };

    const removeCommunityAuthority = (item) => {
      const index = selectedUserCommunityAuthorities.value.indexOf(item);
      selectedUserCommunityAuthorities.value.splice(index, 1);
    };

    const onUpdateCommunityBranch = async (branchOfficeId) => {
      communitySelectedBranchId.value = branchOfficeId;
      communitySelectedBranchName.value = branchStore.branchInfoArray.find((v) => v.id === branchOfficeId)?.branchName;
    };

    const openCommunityDialog = async (rows) => {
      communitySelectedBranchId.value = null;
      communitySelectedBranchName.value = '';
      communitySelectedRegionName.value = '';
      if (rows?.length === 0) {
        warningToast('커뮤니티 권한을 변경할 사용자를 선택해주세요.');
      } else if (rows?.length > 1) {
        warningToast('커뮤니티 권한은 동시에 1명만 변경 가능합니다.');
      } else {
        selectedUserId.value = rows[0].id;
        communityDialog.value = true;
      }

      const result = await getBranchOfficeCommunity(selectedUserId.value);
      selectedUserCommunityAuthorities.value = result;
    };

    const changeBranchOfficeAuthority = async () => {
      const result = updateBranchOfficeCommunity({
        userId: selectedUserId.value,
        branchOfficeBoardAuthorityViewList: selectedUserCommunityAuthorities.value,
      });
      if (result) {
        successToast('권한 부여가 완료되었습니다.');
        communityDialog.value = false;
      }
    };

    const updateRegionName = (regionName) => {
      communitySelectedRegionName.value = regionName;
    };

    return {
      PAGINATION_OPTION,
      isBranchInfoLoading,
      isDataLoading,
      searchUserName,
      totalElements,
      currentPage,
      fetchData,
      updateUsersHandler,
      deleteUsersHandler,
      validationFailHandler,
      columns,
      data,
      columnOptions,
      rowHeaders,
      dialog,
      closeDialog,
      onUpdateBranch,
      addCommunityAuthority,
      removeCommunityAuthority,
      updateBranchHandler,
      selectedRegionName,
      selectedBranchId,
      selectedBranchName,
      isUpdateLoading,
      approveHandler,
      communityDialog,
      openCommunityDialog,
      columnsUserMenuAuthority,
      communitySelectedRegionName,
      communitySelectedBranchName,
      communitySelectedBranchId,
      onUpdateCommunityBranch,
      changeBranchOfficeAuthority,
      updateRegionName,
      selectedUserCommunityAuthorities,
      selectedUserId,
    };
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  padding: 1rem 0.5rem;
  width: 40rem;
}

.searchUserInputWrapper {
  margin-right: 1rem;
  width: 20rem;
}

div.v-input__control {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
</style>
