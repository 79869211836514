export const TABLE_CHECKBOX = 'table-checkbox';
export const ACTIONS_SHOW_ARTICLE = 'actions-show-article';
export const ACTIONS_DELETE_ARTICLE = 'actions-delete-article';

export const OPTIONS = [
  {
    label: '제목',
    value: 'title',
  },
  {
    label: '기자명',
    value: 'reporterName',
  },
];

export const HEAD_CELLS = [
  { id: 'title', label: '제목' },
  { id: 'newTitle', label: '분류' },
  { id: 'reporterName', label: '기자명' },
  { id: 'createdDate', label: '작성일' },
  { id: 'modifiedDate', label: '최근 수정일' },
  { id: 'displayStatus', label: '상태' },
  { id: 'important', label: '중요기사 여부' },
];

export const NEWS_TYPE = {
  NEWS: '뉴스',
  BEAUTY: '뷰티',
  COLUMN: '컬럼&문화',
};

export const NEWS_IMG_BASE_URL_PROD = 'https://www.ko-ba.org/';
export const NEWS_IMG_BASE_URL_DEV = 'http://www.ko-ba.org/';
