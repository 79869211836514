<template>
  <div class="mt-2" style="background-color: lightgrey; width: 100%; height: 1px"></div>
</template>

<script>
export default {
  name: 'GreyLine',
};
</script>

<style scoped></style>
