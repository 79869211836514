<template>
  <thead class="thead-light">
    <tr>
      <th v-if="showCheckbox">선택</th>
      <th v-for="{ id, label } of headCells" :key="id">{{ label }}</th>
      <th v-if="showActions">-</th>
    </tr>
  </thead>
</template>

<script>
export default {
  // [{id: String, label: String}]
  name: 'BoardTableHeader',
  props: {
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    headCells: {
      type: Array,
      default: () => [],
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
