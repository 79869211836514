<template>
  <main-content-box>
    <template #main>
      <div class="mirror__header">
        <contest-select-group :defaultSelectedInfo="contestItemSelectedId" :selectHandler="itemSelectHandler" :mode="EVENT"></contest-select-group>
      </div>
      <div>
        <grid-view
          ref="gridRef"
          :columns="SCORE_MANAGEMENT_HEADER"
          :data="data"
          :header="HEADER_OPTION"
          :columnOptions="COLUMN_OPTIONS"
          :updateRows="updateRows"
          :validationFailHandler="validationFailHandler"
          :onMountGrid="onMountGrid"
        >
          <template #description> 점수는 숫자만 입력 가능합니다. 소수점은 입력하실 수 없습니다. </template>
          >
          <template #addBtn>
            <excel-export-btn
              :selected-event-id="contestItemSelectedId[EVENT]"
              :no-margin="true"
              file-name="점수 관리"
              :grid-ref="gridRef"
              :disabled="data.length === 0"
            />
          </template>
        </grid-view>
      </div>
    </template>
  </main-content-box>
</template>

<script>
import { ref } from 'vue';
import MainContentBox from '@/components/MainContentBox.vue';

import { getContestScoreByContestItemId, bulkUpdateContestScores } from '@/apis/contestScore';

import GridView from '@/views/components/common/GridView.vue';
import ContestSelectGroup from '@/views/components/contest/ContestSelectGroup.vue';

import { SCORE_MANAGEMENT_HEADER, SCORE_JUDGE_LIST, JUDGE_ID_PREFIX, CONTEST, CLASSIFICATION, SECTOR, EVENT } from '@/constants/contest/gridHeader';
import { successToast, warningToast } from '@/utils/toastUtil';
import ExcelExportBtn from '@/components/ExcelExportBtn';

const HEADER_OPTION = {
  height: 120,
  complexColumns: [
    {
      header: '점수',
      name: 'mergeColumn',
      childNames: ['takeOffPoint', 'mergeColumn2'],
    },
    {
      header: '심사위원 번호',
      name: 'mergeColumn2',
      childNames: [...SCORE_JUDGE_LIST.map((v) => v.name)],
    },
  ],
};

const COLUMN_OPTIONS = {
  frozenCount: 2,
  frozenBorderWidth: 2,
  resizable: true,
};

const makeScoreCellData = (mirrorSequence, contestScoreItems) => {
  return contestScoreItems.reduce((obj, prev) => [...obj, { cellId: prev.id, judgeId: prev.judgeId, mirrorSequence }], []);
};

const makeScoreJudgeData = (contestScoreItems) =>
  contestScoreItems.reduce((obj, prev) => {
    obj[`${JUDGE_ID_PREFIX}${prev.judgeId}`] = prev.score;

    return obj;
  }, {});

export default {
  name: 'ScoreManagement',
  components: { MainContentBox, ContestSelectGroup, GridView, ExcelExportBtn },
  setup() {
    const data = ref([]);
    const contestItemIdRef = ref(-1);

    const contestItemSelectedId = ref({
      [CONTEST]: '',
      [CLASSIFICATION]: '',
      [SECTOR]: '',
      [EVENT]: '',
    });

    const gridRef = ref({});

    const itemSelectHandler = async (contestItemId, type) => {
      if (type === EVENT) {
        getScore(contestItemId);
        contestItemIdRef.value = contestItemId;
      } else {
        data.value = [];
      }
    };

    const getScore = async (contestItemId, notification = true) => {
      const scores = await getContestScoreByContestItemId(contestItemId);

      if (notification) {
        successToast('데이터 조회가 완료되었습니다.');
      }

      data.value = scores.map((v) => ({
        id: v.id,
        takeOffPoint: v.takeOffPoint,
        mirrorSequence: v.mirrorSequence,
        playerName: v.playerName, // 2차원 배열,
        contestIds: makeScoreCellData(v.mirrorSequence, v.contestScoreItems),
        ...makeScoreJudgeData(v.contestScoreItems),
      }));
    };

    const validationFailHandler = (errorColumns) => {
      const errorText = errorColumns
        .map((row) =>
          row.columns
            .map((col) => {
              const isMinusCell = col === 2;

              return isMinusCell ? '• 감점' : `• ${row.rowKey + 1}행 심사위원${col - 2}번`;
            })
            .join('\n'),
        )
        .join('\n');
      warningToast(`점수는 숫자만 입력할 수 있습니다. (소수점 불가)\n${errorText}`, 5000);
    };

    const updateRows = async (modifiedRows) => {
      const parsed = modifiedRows.map((row) => {
        const modifiedScoreInfo = [];
        const prevRow = data.value.find((v) => v.id === row.id);
        const contestIds = row.contestIds;

        Object.keys(row).forEach((column) => {
          const splitColumn = column.split(JUDGE_ID_PREFIX);
          const { cellId } = contestIds.find((item) => +item.mirrorSequence === +row.mirrorSequence && +item.judgeId === +splitColumn[1]) || {};

          if (cellId && prevRow[column] != row[column]) {
            modifiedScoreInfo.push({
              id: cellId,
              score: parseInt(row[column]),
            });
          }
        });

        return {
          id: row.id,
          takeOffPoint: parseInt(row.takeOffPoint),
          contestScoreItemModifyRequests: modifiedScoreInfo,
        };
      });

      await bulkUpdateContestScores(parsed);
      getScore(contestItemIdRef.value, false);
    };

    const onMountGrid = (grid) => {
      grid.sort('mirrorSequence', true);
    };

    return {
      SCORE_MANAGEMENT_HEADER,
      gridRef,
      EVENT,
      data,
      contestItemSelectedId,
      updateRows,
      itemSelectHandler,
      validationFailHandler,
      onMountGrid,
      HEADER_OPTION,
      COLUMN_OPTIONS,
    };
  },
};
</script>

<style lang="scss" scoped>
.mirror {
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
  }

  &__header {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
