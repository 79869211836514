<template>
  <basic-card :title="title">
    <div style="display: flex; justify-content: center; align-items: center">
      <div style="width: 400px" class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </basic-card>
</template>

<script>
import { onMounted, watch } from 'vue';
import Chart from 'chart.js/auto';

import BasicCard from '@/components/Card/BasicCard.vue';

export default {
  name: 'DoughnutChart',
  props: {
    id: {
      type: String,
      default: 'doughnut-chart',
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '300px',
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        required: true,
        label: String,
        data: Array,
      },
    },
  },
  components: {
    BasicCard,
  },
  setup(props) {
    const createChart = () => {
      const ctx = document.getElementById(props.id).getContext('2d');

      const chartStatus = Chart.getChart(props.id);
      if (chartStatus !== undefined) {
        chartStatus.destroy();
      }

      if (!props?.chart?.datasets) {
        return;
      }

      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: props.chart.labels,
          datasets: [
            {
              label: 'asdf',
              weight: 5,
              borderWidth: 0,
              borderRadius: 0,
              backgroundColor: ['#194569', '#5F84A2', '#91AEC4', '#B7D0E1', '#CADEED', '#DBECF4'],
              data: props.chart.datasets.data,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          ...Chart.defaults.doughnut,
        },
      });
    };

    watch(
      () => ({ ...props.chart }),
      (curr, prev) => {
        console.log(curr, prev);
        createChart();
      },
    );

    onMounted(() => {
      createChart();
    });
  },
};
</script>
