<template>
  <main-content-box>
    <template #main>
      <contest-form
        :defaultPlayerInfo="playerInfo"
        :defaultRecommenderInfo="recommenderInfo"
        :submitHandler="onClickRegist"
        :isLoading="isLoading"
        buttonText="등록하기"
      ></contest-form>
    </template>
  </main-content-box>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { uploadPlayer } from '@/apis/contestant';

import MainContentBox from '@/components/MainContentBox.vue';
import ContestForm from '@/views/components/contest/ContestForm.vue';

import { ASSOCIATION_TYPE, CONTEST_REGISTER_TYPE_PASCAL, PLAYER_INFO_TITLE } from '@/constants/contest';
import { validateFormData, convertDataForApi } from '@/utils/contest';
import { useContestStore } from '@/store/contest';

const contestRegistGroup = Object.values(CONTEST_REGISTER_TYPE_PASCAL).map((value) => ({
  id: value,
  label: PLAYER_INFO_TITLE[value],
}));

/**
 * 종목 관리 페이지
 */
export default {
  name: 'ContestantRegist',
  components: {
    MainContentBox,
    ContestForm,
  },
  setup() {
    const router = useRouter();
    const contestStore = useContestStore();

    const playerInfo = ref({
      associationName: '',
      branchOfficeId: 0,
      branchName: '',
      name: '',
      birthday: '',
      phone: '',
      playerFiles: [],
      attachmentList: [],
      businessName: '',
      address: '',
      addressDetail: '',
      zip: '',
      contestId: 1,
      contestItemAndPlayerSequences: {
        createMirrorMappings: [],
        modifyMirrorMappings: [],
        deleteMirrorMappings: [],
      },
      note: '',
      contestRegisterType: contestRegistGroup[0].id,
      associationType: ASSOCIATION_TYPE[contestRegistGroup[0].id],
      regionName: '',
    });

    const recommenderInfo = ref({
      associationName: '',
      branchOfficeId: '',
      name: '',
      phone: '',
      associationType: ASSOCIATION_TYPE[contestRegistGroup[0].id],
      regionName: '',
    });

    const initData = async () => {
      await contestStore.setDefaultContestInfos();
    };

    onMounted(() => {
      initData();
    });

    const onClickRegist = async () => {
      try {
        const validInfo = validateFormData(playerInfo.value, recommenderInfo.value, playerInfo.value.contestRegisterType);

        if (!validInfo.isValid) {
          return validInfo;
        }

        const { convertedPlayerInfo, convertedRecommender } = convertDataForApi(playerInfo.value, recommenderInfo.value, false);

        const form = { ...convertedPlayerInfo, recommender: convertedRecommender };
        const formData = new FormData();

        formData.append('request', new Blob([JSON.stringify(form)], { type: 'application/json' }));
        form.attachmentList.forEach((item) => {
          formData.append('attachmentList', item);
        });

        await uploadPlayer(formData);
        router.push('/contest/list-contestant');

        return { isValid: true };
      } catch (e) {
        console.error(e);
      }
    };

    return {
      playerInfo,
      recommenderInfo,
      onClickRegist,
    };
  },
};
</script>

<style lang="scss" scoped>
.contestRegist {
  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.bank__description {
  color: #0070bc;
  margin-top: 8px;
}

.regist__btn {
  color: white;
  background-color: #0070bc;
}
</style>
