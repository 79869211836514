<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar btn-background="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/lock-cover.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card">
            <div class="card-body text-center">
              <div class="info mb-4 mt-n6">
                <img
                  class="avatar avatar-xxl shadow-lg border border-white"
                  alt="Image placeholder"
                  src="../../../assets/img/team-2.jpg"
                />
              </div>
              <h4 class="mb-0 font-weight-bolder">Mike Priesler</h4>
              <p class="mb-4">Enter password to unlock your account.</p>
              <form role="form">
                <argon-input
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="password"
                />
                <div class="text-center">
                  <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    size="lg"
                    class="mt-3 mb-0"
                    >Unlock</argon-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import Navbar from '@/examples/PageLayout/Navbar.vue';
import AppFooter from '@/examples/PageLayout/Footer.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import { mapMutations } from 'vuex';

const body = document.getElementsByTagName('body')[0];
export default {
  name: 'LockCover',
  components: {
    Navbar,
    AppFooter,
    ArgonInput,
    ArgonButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove('bg-gray-100');
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add('bg-gray-100');
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout']),
  },
};
</script>
