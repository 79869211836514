<template>
  <div class="dropdown">
    <v-select
      :model-value="selectedItem ? selectedItem : '선택해주세요'"
      :label="label"
      :items="list"
      :item-title="titleKey"
      :item-value="valueKey"
      :menu-props="{ class: 'dropdown__menu' }"
      :disabled="disabled"
      @update:modelValue="onChangeData"
      variant="outlined"
      no-data-text="데이터가 비어있습니다."
      hide-details
    ></v-select>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    selectedItem: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    titleKey: {
      type: String,
      default: 'title',
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    onChangeSelectedItem: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selector = ref(null);

    const onChangeData = (value) => {
      emit('update:selectedItem', value);
      props.onChangeSelectedItem(value, props.type, props.target);
    };

    return {
      selector,
      onChangeData,
    };
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  &:deep(.choices__item) {
    margin-right: 1rem;
  }

  &:deep(.choices) {
    margin-bottom: 0;
  }

  &__menu > .v-select__content {
    height: 10rem !important;
  }
}
</style>
