import axios from '@/lib/axiosController';

/**
 * 대회 점수관리 API
 */

/**
 * 종목 ID에 따른 점수들 수정
 * @param {array} bulk
 * @returns
 */
export const bulkUpdateContestScores = (bulk) =>
  axios.put('/contest-score/modify/bulk', {
    modifyRequests: bulk,
  });

/**
 * 종목 ID별 점수 조회
 * @param {string} contestItemId
 * @returns
 */
export const getContestScoreByContestItemId = (contestItemId) => axios.get(`/contest-score/${contestItemId}`);
