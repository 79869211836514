/** 에디터 이미지 업로드 핸들러 */
/**
 *
 * @param {function} request
 * @param {string} name
 * @returns {function(*, *): Promise<unknown>}
 *
 * @description
 * - request: image upload api
 * - name: form-data name
 */
const imageUploadHandler = (request, name) => async (blobInfo, progress) => {
  progress(100);
  const blobFile = await blobInfo.blob();

  const formData = new FormData();
  formData.append(name, blobFile);
  const res = (await request(formData)) || {};

  return new Promise((resolve, reject) => {
    if (res.url) {
      resolve(res.url);
    } else {
      reject('이미지 업로드에 실패했습니다.');
    }
  });
};

export default imageUploadHandler;
