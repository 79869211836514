import axios from '@/lib/axiosController';

const END_POINT = '/push-manage';

/**
 * 회원 단건 푸시 발송
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendPushToUser = (userId) => axios.post(`${END_POINT}/${userId}`);

/**
 * 회원 다중 푸시 발송
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendPushToUsers = ({ isSendAll, branchOfficeIds, title, body }) =>
  axios.post(`${END_POINT}/send-batch`, {
    isSendAll,
    branchOfficeIds: isSendAll ? [] : branchOfficeIds,
    title,
    body,
  });

/**
 * 푸시 대상 지회/지부 조회
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTargetBranchOffices = () => axios.get(`${END_POINT}`);

/**
 * 푸시 발송 대상 수 조회
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTargetSummary = ({ isSendAll, branchOfficeIds }) =>
  axios.post(`${END_POINT}/target-summary`, {
    isSendAll,
    branchOfficeIds: isSendAll ? [] : branchOfficeIds,
  });

/**
 * 푸시 히스토리 조회
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPushHistory = () => axios.get(`${END_POINT}/history`);
