<template>
  <card-frame>
    <card-header :title="title"></card-header>
    <card-body><slot /></card-body>
    <card-footer :desc="desc"></card-footer>
  </card-frame>
</template>

<script>
import CardFrame from '@/components/Card/CardFrame.vue';
import CardHeader from '@/components/Card/CardHeader.vue';
import CardBody from '@/components/Card/CardBody.vue';
import CardFooter from '@/components/Card/CardFooter.vue';

export default {
  components: {
    CardHeader,
    CardBody,
    CardFooter,
    CardFrame,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
